@import '../../styles/common.scss';

.LoginContainer_BigContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;

  @include media('<=phone') {
    flex-direction: column;
  }
}

.VideoContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  @include media('<=phone') {
    width: 100%;
  }
}

.LoginContainer {
  display: flex;
  justify-content: center;

  @include media('<=phone') {
    width: 100%;
  }

  .LoginForm {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-self: auto;
    max-width: 290px;
    @include media('<=phone') {
      max-width: 100%;
    }

    .LoginForm_AcblLine1,
    .LoginForm_AcblLine2 {
      font-size: 15px;
    }

    .LoginForm_AcblLine2 {
      margin-top: 5px;
    }

    .Form_Container {
      display: grid;
      gap: 24px;
    }

    .Button {
      font-weight: 700;
    }

    .Field {
      font-size: larger;
    }
  }
}

.LoginForm_Or {
  text-align: center;
  font-weight: bold;
  border-left: 1px #821b25;
}

.LoginForm_LeftHeader {
  font-weight: bolder;
  color: #821b25 !important;
}

.LoginForm_LoginResult {
  color: $errored;
  margin-bottom: 1.5em;
}

.LoginForm_ButtonContainer {
  display: flex;
  flex-direction: row;
}

.LoginForm_FieldContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.LoginForm_ButtonContainer {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.LoginForm_ForgotPasswordButton {
  @include media('<=phone') {
    width: 14em !important;
  }
}

.LoginFormColumnOneHeader {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
}

.LoginForm_ButtonContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  size: small;
}

.LoginForm_RegisterButton {
  font-weight: bolder !important;
  background-color: #821b25 !important;
  color: #ffffff !important;
  text-align: center !important;
  padding: 8px 30px !important;
}

.LoginForm_Video {
  width: 100%;
  aspect-ratio: 456/228;
  max-height: 200px;
}

.LoginForm_DoubleGrin {
  width: 70px;
  height: 70px;
  margin-left: 12px;
}

.LoginHeader {
  text-align: center;
}
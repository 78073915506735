@import "../../../styles/ignite/common.scss";

.member-details-content {
  width: 100%;

  .top-content {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0;
    border: 1px solid transparent;

    &>* {
      padding-right: 15px;
      padding-left: 15px;
    }

    &.is-editing {
      .preferred-method {
        pointer-events: none;

        .contact-info__label,
        .contact-info__main {
          color: #898989;
        }

        .button {
          color: #898989 !important;

          &.active {
            color: #fff !important;
            background: #C4C4C4 !important;
          }
        }
      }
    }

    .description {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 15px;
      border-bottom: 1px solid $softBorder;

      &__end {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }

      @include media("<=sm") {
        padding-right: 5px;
        padding-left: 5px;
        align-items: center;

        .button-wrapper {
          margin-top: 0;
        }
      }
    }

    @include media("<=sm") {
      padding-bottom: 0;
    }
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    line-height: 150%;
    text-align: center;
    margin-top: 26px;


    @include media("<=phone") {
      font-size: 24px;
    }
  }

  .separator {
    width: 30%;
    opacity: .5;
    margin: 20px auto 30px;
  }

  .intro-text {
    font-size: 24px;
    text-align: center;
    margin-bottom: 38px;
    line-height: 28.8px;

    @include media("<=phone") {
      font-size: 20px;
    }
  }

  .contact-info {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: 15px;

    .contact-info-start {
      display: flex;
      flex-grow: 1;

      @include media("<=sm") {
        padding: 0 0 15px;
        display: flex;

        .button {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }

    &__main {
      flex-grow: 1;
      font-size: 18px;
      font-weight: 700px;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @include media("<=sm") {
        flex-direction: column;
        align-items: end;
      }
    }

    &.preferred-method {
      border-bottom: 1px solid $softBorder;

      .contact-info__main {
        flex-direction: column;
        align-items: start;
      }

      .contact-info__label {
        align-items: start;

        @include media("<=sm") {
          width: 250px;
        }
      }

      .button-wrapper {
        .button {
          background: #EBEBEB;
          font-size: 18px;
          font-weight: 700;
          color: #555;
          width: 113px;
          height: 40px;
          margin-right: 2px;
          margin-left: 0;

          &.active {
            background: #0280CD;
            color: #fff;
          }

          @include media("<=sm") {
            width: 106px;
            font-size: 16px;
          }
        }

        @include media("<=sm") {
          display: flex;
        }
      }

      .help-text {
        font-size: 16px;
        font-weight: 600;
        margin-top: 20px;
        max-width: 380px;
        white-space: normal;

        @include media("<=sm") {
          font-size: 15px;
        }
      }

      @include media("<=sm") {
        flex-direction: column;
      }
    }

    .button {
      background: #0280CD;
      color: #fff;
      font-weight: 800;
      width: 130px;
      height: 40px;
      margin-left: 10px;

      &.disabled {
        background: #EBEBEB;
      }
    }

    &__text {
      padding: 4.5px 0 5.5px;
    }

    &__help-text {
      font-size: 18px;
      font-weight: normal;
      margin-left: 23%;
      margin-right: 65px;
      color: #555555;
    }

    &__input {
      font-size: 18px !important;
      font-weight: 700 !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.disabled {
        color: rgba(0, 0, 0, .38);
      }

      @include media("<=md") {
        text-align: right;
      }
    }

    &__base {
      padding: 0 5px;

      &.focused {
        border: 1px solid $primaryAccentDark;

        .contact-info__input {
          text-align: left;
        }
      }

      &::before {
        display: none;
      }
    }

    &__label {
      font-size: 18px;
      text-transform: uppercase;
      width: 30%;
      display: flex;
      align-items: center;
      font-weight: 600;

      @include media("<=sm") {
        width: 130px
      }
    }

    &.role-block {
      @include media("<=sm") {
        padding-top: 0;

        .contact-info__label {
          width: 80px;
        }

        .role-panel {
          flex-grow: 1;
        }
      }
    }

    &.is-editing {
      border: 2px solid #0177C0;
      border-radius: 4px;
    }
  }

  .club-button {
    border: 1px solid $primaryAccentDark;
    color: $primaryAccentDark;
    background: $primaryDark;
    color: #fff;
    font-weight: 800;
    align-self: flex-end;
    margin-top: 24px;
    min-height: 40px;
    min-width: 135px;
    padding-right: 0;

    &__icon {
      padding-left: 20px;
    }

    &:hover {
      background: $primaryDark;
    }
  }

  .disabled-button {
    color: #fff;
    background: $softBorder;
  }

  .edit-panel {
    flex-basis: 50%;

    @include media("<=sm") {
      display: flex;
      justify-content: flex-start;
    }

    .edit-button {
      padding: 8px;
    }
  }

  .role-panel {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    min-height: 70px;
    padding-left: 35px;
    flex-direction: column;

    .role-description {
      font-size: 16px;
      font-weight: 700;
      align-self: flex-start;
      text-transform: uppercase;
    }

    .slider {
      max-width: 188px;
    }

    .rail {
      height: 13px;
      background: #C4C4C4;
      border-radius: 12px;
    }

    .label+span {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 700;
    }

    .label.active+span {
      color: #0177C0;
    }

    .thumb {
      width: 21px;
      height: 21px;
      background: #0177C0;
    }

    @include media("<=sm") {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-left: none;
      padding-left: 0;
      margin-top: 15px;

      .slider {
        margin-left: 40px;
      }
    }
  }

  .button-wrapper {
    display: flex;
    text-align: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;

    .edit-button {
      border: 1px solid $primaryAccentDark;
      color: $primaryAccentDark;
      background: $primaryDark;
      color: #fff;
      font-weight: bold;
      align-self: flex-end;
      border-radius: 5px;
      height: 40px;
      width: 40px;
      margin-left: 10px;

      &:hover {
        background: $primaryDark;
      }

      &.is-editing {
        background: rgb(235, 86, 88);
      }
    }
  }

  .bottom-content {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $softBorder;

    .info-item {
      width: 33%;
      padding: 15px 20px;
      box-sizing: border-box;

      &:not(:last-child) {
        border-right: 1px solid $softBorder;
      }

      .text {
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        color: #555;
      }

      .date {
        font-size: 15px;
        font-weight: 600;
      }
    }

    @include media("<=sm") {
      flex-direction: column;
      padding: 0;
      border: none;

      .info-item {
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        border-right: none !important;
        border-top: 1px solid $softBorder;
      }
    }
  }

  @include media("<=sm") {
    padding: 0;
  }
}
@import "../../styles/common.scss";

.CCEventViewFullMobile {
  .CCEventViewFullMobile_Presents {
    font-style: italic;
    width: 95%;
    margin-bottom: .8em;
  }

  .CCEventViewFullMobile_EventName {
    font-size: $mediumHeadlineTextSize;
    font-weight: $semiBold;
    margin-bottom: .1em;
    max-width: 90%;
  }

  .CCEventViewFullMobile_EventDescription {
    margin-top: .4em;
    margin-bottom: .4em;
    max-width: 90%;
    white-space: pre-wrap;
  }

  .CCEventViewFullMobile_Extra {
    font-weight: $semiBold;
    width: 95%;
  }


  .CCEventViewFullDesktop_SelfGuided {
    margin-top: 1em;
  }
}

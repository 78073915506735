@import "../../pages/virtualClubs/VirtualClubsCommon.scss";
$e3blue: #0177C0;

.VirtualClub_events_container_button_list.flex-columns {
    gap: 24px;

    .eventInstance {
        flex: calc(33% - 24px);
        max-width: calc(33% - 24px);

        @media (max-width: $tabletWidth) {

            flex: calc(50% - 24px);
            max-width: calc(50% - 24px);
        }

        @media (max-width: $mobileWidth) {

            flex: 100%;
            max-width: 100%;
        }

        &>div {
            gap: 18px;
        }

        .col-right {
            flex: 0 0 100px;
            text-align: right;
        }

        .col-left {
            flex: 1;
            max-width: calc(100% - 100px);
        }

        .title {
            font-size: 16px;
            cursor: pointer;
        }

        .time {
            font-size: 14px;
        }

        .attending {
            color: $e3blue;
            font-size: 14px;
            font-weight: 800;
        }
    }

    // used for event sign up / cancel
    .VirtualClub_MUIButton.medium {

        font-size: 14px;
        font-weight: 800;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: #fff;

        margin: 0 0 0 8px;
        padding: 6px 13px;
        background-color: $e3blue;
        border-color: $e3blue;

        &:hover {
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.29);
        }
    }

    .VirtualClub_MUIButton_outlined.medium {
        padding: 6px 13px;
        margin: 0 0 0 8px;
        white-space: nowrap;
        border: 1px solid #0177C0;
        border-radius: 4px;

        font-size: 14px;
        font-weight: 800;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: #0177C0;
    }

}
@import "../../styles/common.scss";

#CCClubInvitation_Banner {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  @include media("<=phone") {
    flex-direction: column;
  }
}

#CCClubInvitation_Image_Wrapper {
  width: 305px;
  height: 305px;
  margin-right: 30px;
  @include media("<=phone") {
    margin: auto;
  }
}

#CCClubInvitation_Title{
  display: inline;
}

#text {
  flex: 1;
}

#CCClubInvitation_SubSubtext {
  display: flex;
  font-weight: bold;
}

#CCClubInvitation_ErrorMessage {
  color: $errored;
  text-align: center;
}

#CCClubInvitation_Response_Wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

@import "../../styles/common.scss";


.EmailVerificationNag {
  border-radius: 1em;
  border: 1px solid #CF504F;
  flex-direction: column;
  position: relative;

  @include media("<=phone") {
    margin-top: 120px;
  }
}

.EmailVerificationNag_Content {
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;

  @include media("<=phone") {
    flex-direction: column;
    padding: 24px;
  }
}

.CrossBoldIcon {
  position: absolute;
  right: 24px;
  top: 16px;
  cursor: pointer;

  @include media("<=phone") {
    right: 16px;
    top: 10px;
  }
}

.EmailVerificationNag_MemberJustRegistered {
  padding: 24px 24px 0;

  > h2 {
    text-align: center;
    margin: 0;
  }

  > p {
    margin: 1em 0 0;
    font-size: 18px;
  }

  @include media(">phone") {
    padding: 32px 32px 0;
  }
}

.EmailVerificationNag_Message_Title {
  display: flex;

  > p {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 0 8px;
  }
}

.EmailVerificationNag_Message_Body {
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0 0 0;

  > span {
    font-weight: 700;
  }
}

.EmailVerificationNag_Resend {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: $bold;
  padding: 12px 24px;
  color: #0177C0;
  white-space: nowrap;
  border: 1px solid #0177C0;
  border-radius: 4px;
  line-height: 1;
  margin: 0 0 0 40px;
  font-size: 18px;
  font-weight: 800;

  @include media("<=phone") {
    margin: 20px 0 0 0;
  }
}

.EmailVerificationNag_Resent {
  display: flex;
  font-weight: $bold;
  text-align: center;
  width: 180px;
  margin-left: 40px;

  @include media("<=phone") {
    margin: 26px 0 0 0;
    padding: 0 20px;
    width: auto;
    flex-direction: column;
  }
}

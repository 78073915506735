@import "../../styles/common.scss";

.EventView {
  margin-top: .8em;
}

.Loading {
  padding-top: 10em;
  padding-bottom: 10em;
}

.EventLandingPage {
  margin-top: 28px;
  @include media("<=phone") {
    padding-top: 17px;
  }
}

.EventLandingPage_Back {
  color: #0177C0;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  padding: 4px 10px;
}

.EventLandingPage_Back_Icon {
  margin-right: 8px;
}

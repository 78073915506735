@import "../../styles/common.scss";

.PaidIndicator
{
  display: flex;
  align-items: flex-start;
}

.PaidIndicator_Money {
  font-weight: $bold;
  font-size: $largeTextSize;
  line-height: $largeTextSize;
  color: $paidIndicatorForeground;
  margin-right: .2em;
}
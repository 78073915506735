@import "../../../styles/ignite/common.scss";

.leader-onboarding-welcome-page {
    .inner-part {
        max-width: 720px;
        margin: 0 auto;
        min-height: 15em;
        padding: 20px;
    }

    @include media("<=phone") {
        margin: 105px auto 20px;
    }

    .title {
        font-size: 36px;
        text-align: center;
        font-weight: 800;
        margin-bottom: 35px;

        @include media("<=phone") {
            font-size: 24px;
        }
    }

    .main-content {
        display: flex;

        &__text-wrapper {
            margin-left: 30px;
            max-width: 300px;

            @include media("<=phone") {
                text-align: center;
                margin: 0 auto;
            }

            .subtitle {
                font-size: 24px;
                font-weight: 600;
            }

            .description {
                font-size: 16px;
                font-weight: 600;
                margin: 20px 0 20px;
            }

            .help-text {
                font-size: 24px;
                font-weight: 800;
                margin-bottom: 10px;
                margin-top: 30px;

                @include media("<=phone") {
                    margin-top: 50px;
                }
            }

            .button {
                font-weight: 800;
                margin-bottom: 15px;
                width: 180px;
                background-color: $primaryDark;

                .icon {
                    width: 15px;
                }
            }

            .already-member {
                font-size: 18px;
                font-weight: 600;

                &__link {
                    text-decoration: none;
                    color: #000;
                    font-weight: 800;
                    text-decoration: underline;
                }
            }
        }

        &__image-wrapper {
            .image {
                max-width: 195px;
                max-height: 195px;
            }

            @include media("<=phone") {
                display: flex;
                justify-content: center;
            }
        }

        @include media("<=phone") {
            flex-direction: column;
        }
    }

    .footer {
        margin-top: 100px;
        background: #F7F7F7;

        .title {
            font-size: 32px;
            font-weight: 800;

            @include media("<=phone") {
                font-size: 24px;
            }
        }

        .links-list {
            padding: 0 30px;
            display: flex;
            justify-content: space-between;

            .link-item {
                color: #000;
                max-width: 130px;
                text-align: center;
                text-decoration: underline;
                white-space: pre-line;

                @include media("<=phone") {
                    padding: 0 4px;
                }
            }

            @include media("<=phone") {
                padding: 0;
            }
        }

        @include media("<=phone") {
            margin-top: 60px;
            margin-bottom: 30px;
        }
    }
}

@import "../../styles/common.scss";

.HaveQuestionsWrapper {
  display: flex;
  justify-content: center;

  .HaveQuestions {
    $altMobileWidth: 600px;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    width: 463px;
    display: flex;

    .HaveQuestions_TextContent {
      background: linear-gradient(99.84deg, #CFE9FF 28.76%, #FFFFFF 87.46%);
      width: 333px;
      padding: 12px 20px;
      box-sizing: border-box;
      border-radius: 16px 0 0 16px;
    }

    .HaveQuestions_HaveQuestions {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }

    .HaveQuestions_AskSpecialist {
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }

    .HaveQuestions_ContactNumber {
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }

    .HaveQuestions_ImageWrapperBackground {
      width: 130px;
      border-radius: 0 16px 16px 0;
      position: relative;
      background: rgb(255, 255, 255);
    }

    .HaveQuestions_ImageWrapper {
      width: 100%;
      height: 100%;
      background: radial-gradient(circle at left, rgba(255, 255, 255, 0.25) 0%, rgba(1, 119, 192, 1) 100%);
      border-radius: 0 16px 16px 0;
    }

    .HaveQuestions_Image {
      position: absolute;
      width: 108%;
      height: 124%;
      bottom: 0;
      left: -35%;
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: contain;
    }

    @mixin MobileSizing {
      max-width: 350px;
      flex-direction: column;
      height: auto;
      min-height: 129px;
      .HaveQuestions_TextContent {
        width: 100%;
        min-height: 105px;
        height: auto;
        border-radius: 16px 16px 0 0;
        background: linear-gradient(165.15deg, #CFE9FF 10.48%, #E9F5FF 88.02%);
      }
      .HaveQuestions_HaveQuestions {
        font-size: 18px;
      }
      .HaveQuestions_AskSpecialist {
        font-size: 16px;
      }
      .HaveQuestions_ContactNumber {
        font-size: 16px;
      }
      .HaveQuestions_ImageWrapperBackground {
        width: 100%;
        height: 24px;
        border-radius: 0 0 16px 16px;
      }
      .HaveQuestions_ImageWrapper {
        border-radius: 0 0 16px 16px;
        // calc(100% - 69px) = 40px from right positioning + half of image width (58px)
        background: radial-gradient(circle at calc(100% - 69px), rgba(255, 255, 255, 0.25) 0%, rgba(47, 143, 203, 1) 20%, rgba(1, 119, 192, 1) 100%);
      }
      .HaveQuestions_Image {
        left: initial;
        right: 40px;
        width: 58px;
        height: 63px;
        background-position: bottom;
      }
    }

    &.AlwaysMobile {
      @include MobileSizing;
    }

    &.AltMobileSizing {
      @media (max-width: $altMobileWidth) {
        @include MobileSizing;
      }
    }

    &:not(.AltMobileSizing) {
      @include media("<=phone") {
        @include MobileSizing;
      }
    }
  }
}


@import "../../styles/common.scss";

.CCModal
{
  align-items: center;
  justify-content: center;
  display:flex;
  outline: 0;
}
.CCModal:focus
{
  outline: 0;
}

.CCModal_Content
{
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  background-color: $background;
  z-index: 110;
  padding: 3em;
}

.CCModal_Content_Inner
{
  width: 100%;
}






.ClubLeaderFaqPage {

  .ClubLeaderFaqPage_PageHeader {
  }

  .ClubLeaderFaqPage_Section {
    margin: 1em auto 3em;
  }

  .ClubLeaderFaqPage_SectionHeader {
  }

  .ClubLeaderFaqPage_ItemList {
  }


}

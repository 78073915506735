@import "../../styles/common.scss";

.SelfGuidedEventTextShort {
  display: flex;
  align-content: center;

  svg {
    fill: white;
    margin-right: .5em;
    width: 30px;
    height: 30px;
  }

  div {
    font-size: $largeTextSize;
    font-weight: $bold;
    display: flex;
    align-self: center;
  }
}

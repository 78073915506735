@import "../../styles/common.scss";

.FieldWithBottomText_Wrapper
{
  display:flex;
  flex-direction: column;
}

.FieldWithBottomText_Instructions
{
  display:flex;
  font-size:$formInstructionsTextSize ! important;
}


.AcblLaunchSweepstakes {
  .RulesHeader {
    text-align: center;
    font-weight: 800;
    margin-top: 20px;
  }

  .RulesListTitle {
    font-weight: 800;
  }

  ol.RulesList > li::marker {
    font-weight: 800;
  }

  .RuleTitle {
    font-weight: 800;
  }

  ol.RulesList_Sublist {
    list-style: upper-alpha;
  }

  ol.RulesList_Sublist_Sublist {
    list-style: lower-roman;
  }

  a {
    color: #0891e9;
    text-decoration: none;
  }
}

@import "../../styles/common.scss";

#CCClubLeaderLanding_Banner {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  @include media("<=phone") {
    flex-direction: column;
  }
}

#CCClubLeaderLanding_Image_Wrapper {
  width: 305px;
  height: 305px;
  margin-right: 30px;
  @include media("<=phone") {
    margin: auto;
  }
}

#CCClubLeaderLanding_Title{
  display: inline;
}

#text {
  flex: 1;
}

#CCClubLeaderLanding_SubSubtext {
  display: flex;
  font-weight: bold;
}

#CCClubLeaderLanding_FAQ_Wrapper {
  display: flex;
  justify-content: center;
}

#CCClubLeaderLanding_FAQ {
  width: 70%;
  @include media("<=phone") {
    width: 100%;
  }
}

#CCClubLeaderLanding_SignInText {
  display: inline;
}

#CCClubLeaderLanding_SigninLogin {
  display: flex;
  flex-direction: row;
  @include media("<=phone") {
    flex-direction: column;
  }
}

#CCClubLeaderLanding_Learn_More_Header {
  text-align: center;
}

#CCClubLeaderLanding_SignInWrapper {
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @include media("<=phone") {
    justify-content: center;
  }
}

#CCClubLeaderLanding_SignInWrapper .CCButton {
  display: flex;
}


#CCClubLeaderLanding_RegisterWrapper {
  display: flex;
  flex-direction: column;
}

#CCClubLeaderLanding_RegisterWrapper .CCButton {
  display: flex;
}

#CCClubLeaderLanding_Signup_Wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.CCClubLeaderLanding_Link {
  text-decoration: none;
}
@import "../../../styles/ignite/common.scss";

.tracking-activity-modal-wrapper {
    height: 100vh;
    max-width: 570px;
    position: relative;
    margin: 0 auto;
    outline: none;

    .close-button {
        position: absolute;
        right: -60px;
        top: -60px;
        color: #fff;

        .icon {
            font-size: 60px;
        }
    }

    @include media("<=sm") {
        .close-button {
            right: -3px;
            top: -35px;

            .icon {
                font-size: 40px;
            }
        }
    }
}

.tracking-activity-modal {
    max-width: 570px;
    background: #fff;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    max-height: 85%;
    overflow-y: auto;

    .badge {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 150px 35px 80px;
        white-space: pre-line;

        .title {
            font-size: 34px;
            font-weight: 800;
            text-align: center;
        }

        .subtitle {
            font-size: 32px;
            font-weight: 800;
            text-align: center;
        }

        .icon {
            height: 260px;
            width: 260px;
            margin: 50px auto 55px;
        }

        .button {
            margin: 95px auto 0;
            background: $primaryDark;
            color: #fff;
            width: 100%;

            &__label {
                font-size: 32px;
                font-weight: 800;
            }
        }

        @include media("<=sm") {
            padding: 60px 30px 40px;

            .title {
                font-size: 26px;
            }

            .subtitle {
                font-size: 24px;
            }

            .icon {
                width: 140px;
                height: 140px;
                margin: 40px auto 45px;
            }

            .button {
                margin-top: 70px;

                &__label {
                    font-size: 26px;
                }
            }
        }
    }

    @include media("<=sm") {
        max-width: 330px;

        .top-content {
            &__text {
                font-size: 26px;
                padding: 20px 20px;
            }

            &__amount {
                font-size: 26px;
                min-width: 100px;
            }
        }

        .middle-content {
            padding: 15px 20px;

            .completed {
                font-size: 24px;
            }
        }

        .bottom-content {
            max-height: 400px;

            .tracking-activity-block {
                padding: 10px 20px;

                .date {
                    font-size: 24px;
                    width: 150px;
                }

                .amount-input {
                    font-size: 26px;
                }
            }
        }
    }
}

.top-content {
    font-weight: 800;
    display: flex;
    border-bottom: 1px solid $softBorder;

    &__text {
        font-size: 32px;
        padding: 35px 30px;
        flex-grow: 1;
        text-transform: uppercase;
    }

    &__amount {
        font-size: 36px;
        background: $primaryDark;
        min-width: 144px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
}

.middle-content {
    padding: 30px;

    .enter-activity {
        font-weight: 600;
        font-size: 24px;
    }

    .completed {
        font-size: 36px;
        font-weight: 800;
        color: $primaryDark;
        text-align: center;
        font-style: italic;
    }
}

.bottom-content {
    border-top: 1px solid $softBorder;

    .tracking-activity-block {
        display: flex;
        padding: 15px 10px 15px 30px;
        align-items: center;

        .date {
            font-size: 32px;
            font-weight: 800;
            flex-grow: 1;
        }

        .amount-input {
            background: #ebebeb;
            border-radius: 4px;
            height: 60px;
            width: 70px;
            padding: 0;
            text-align: center;
            font-size: 30px;
            font-weight: 800;
            box-sizing: border-box;
            border: none;

            &.activity-present {
                background: #009F2D;
                color: #fff;
            }

            &.is-not-editing {
                background: #009F2D !important;
                color: #fff !important;
                border: none !important;
            }
        }

        .status {
            width: 90px;
            display: flex;
            justify-content: center;
            align-items: center;

            .button {
                width: 70px;
                height: 40px;
                background: #fff;

                &__label {
                    font-size: 15px;
                    font-weight: 800;
                    text-transform: none;
                    color: $primaryDark;
                }
            }

            .check-icon {
                font-size: 40px;
            }
        }

        &.read-only {
            pointer-events: none;

            .date {
                color: #999;
            }
        }

        &.active {
            background: $primaryDark;
            color: #fff;

            .amount-input {
                border: 5px solid #000;
                background: #fff;
                color: #000;

                &.activity-absent {
                    border-color: #FD4B51;
                    color: #FD4B51;
                }
            }
        }

    }
}

.tracking-activity-editor {
    max-width: 70px;
    max-height: 60px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}
@import "../../../styles/ignite/common.scss";

.change-role-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .change-role-modal-wrapper {
        max-width: 500px;
        min-height: 450px;
        background: #fff;
        padding: 30px 50px;
        box-sizing: border-box;

        .content {
            border: 1px solid $softBorder;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                color: #EB5658;
                font-size: 32px;
                font-weight: 800;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 20px;
            }

            .description {
                font-size: 24px;
                font-weight: 600;
                text-align: center;
            }

            .button {
                width: 265px;
                height: 40px;
                font-size: 18px;
                font-weight: 800;

                &.confirm {
                    background: #0280CD;

                    text-transform: uppercase;
                    color: #fff;
                    margin: 20px 0;
                }

                &.cancel {
                    border: 1px solid #0280CD;
                    color: #0280CD;
                }

                .icon {
                    margin-left: 15px;
                }
            }
        }
    }
}

@import "../../../styles/common.scss";

$primarytDark: #0177C0;
$success2: #10BC41;

@include media("<=phone") {
  padding: 1px;
}

.form-wrapper {
    padding: 40px 72px 40px 72px; 
    border: 1px solid #C4C4C4; 
    border-radius: 4;

    @include media("<=phone") {
      padding: 24px;
    }
}

.registration-form__field-wrapper {
  margin-bottom: 17px;

  @include media(">phone") {
    grid-template-columns: 2fr;
  }
}
.required {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 40px;
}

.registration-form__help-text {
  font-size: $formEntryTextSize;
  display: block;
  font-weight: 600;
  padding-right: 1.25em;
}

.registration-form__field_bold {
  font-weight: 600;
}

.registration-form__button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 28px;

  @include media("<=phone") {
    align-items: stretch;
    margin: 0;
  }
}

.registration-form__button {
  margin-bottom: 15px;
  max-height: 40px;
  padding: 9px 20px;
  background-color: $primarytDark;

  &-icon {
    margin-left: 10px;
  }

  @include media("<=phone") {
    display: flex;
    justify-content: center;
    margin-right: 1.8em;
  }
}

.registration-form__terms-of-use-link {
  text-decoration: underline;
  color: #0280CD;
  font-weight: bold;
}

.placeholder {
  display: block;

  @include media("<=phone") {
    display: none;
  }
}

.registration-form {
  border: 1px solid $softBorder;
  padding: 40px 50px;
  border-radius: .3em;

  .required {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  @include media("<=phone") {
    border: none;
    padding: 0;
  }

  &__field-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-bottom: 15px;

    &.full-width {
      grid-template-columns: 1fr;
    }

    @include media("<=phone") {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }

  &__password-help-text {
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
  }

  &__field {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content min-content;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.registration-form__terms-of-use-wrapper {
  display: flex;
  width: 100%;

  label {
    padding: 9px;
    font-size: 16px;
  }

  @include media("<=phone") {
    width: 90%;
    text-align: center;
    margin-bottom: .5em;
  }
}

.MuiFormLabel-root {
  display: flex;
}

.ignite-page-content {
  max-width: 730px;
  margin: 0 auto 50px;
}

.chip-list {
  font-weight: bold;
  margin: .5em;
}

.label-root {
  font-weight: 800 !important;
  color: rgba(0, 0, 0, .6) !important;
  padding-right: 5px;
  background: #fff;
}

.label-error {
  color: red !important;
  font-weight: 600 !important;
}

.label-focused {
  color: $primarytDark !important;
}

.registration-form__checkbox-title {
  color: #000;
  margin-right: 5px;
}

.registration-form__checkbox {
  padding: 0 !important;
}

.registration-form__label {
  display: flex;
  flex-wrap: wrap;
}

.registration-form__password-help-text {
  padding-right: 1.8em;
}

.registration-form__field_error {
  font-weight: 700 !important;
}

@import "../../styles/common.scss";
@import "../../styles/colors.scss";


$pageWidth: $desktopPageWidth * 1px;
$pageContentWidth: $desktopPageContentWidth * 1px;

/*
$topAccentHeight: (6/$baseFontSize) * 1em;
$topCalloutHeight: (30/$baseFontSize) * 1em;
$navHeight: (95/$baseFontSize) * 1em;
$needHelpRight: (100/$baseFontSize) * 1em;
$needHelpRight_Mobile: (25/$baseFontSize) * 1em;
$pageMarginHorizontal_Mobile: (20/$baseFontSize) * 1em;
$pageMarginHorizontal_Mobile: (20/$baseFontSize) * 1em;
$pageWidth: ($desktopPageWidth/$baseFontSize) * 1em;
$pageContentWidth: (1024/$baseFontSize) * 1em;
$pageContentWidth_Mobile: 100%;
$logoAndCopyrightWidth: (260/$baseFontSize) * 1em;
$logoWidth: (254/$baseFontSize) * 1em;
$logoWidth_Mobile: (115/$baseFontSize) * 1em;
$headerHeight: (95/$baseFontSize) * 1em;
$headerBorderSize: (1/$baseFontSize) * 1em;
$headerHeight_Mobile: (50/$baseFontSize) * 1em;
$headerContentWidth: $pageWidth;
$headerContentMarginTop: (30/$baseFontSize) * 1em;
$headerContentMobileMarginLeft: $pageMarginHorizontal_Mobile;
$headerContentMobileMarginRight: $pageMarginHorizontal_Mobile;
$headerContentMobileMarginTop: (15/$baseFontSize) * 1em;
$hamburgerWidth: 1.25em;
$hamburgerHeight: 0.8em;
$hamburgerMarginRight: 0.3em;
$hamburgerBorderSize: 0.2em;
$hamburgerBorderColor: $foreground;
$hamburgerBeforeTop: 0.3em;
$hamburgerBeforeLeft: 0;
$hamburgerBeforeWidth: 100%;
$separatorBorderLeftSize: (1/$baseFontSize) * 1em;
$separatorMarginLeft: (25/$baseFontSize) * 1em;
$footerBorderTopSize: (1/$baseFontSize) * 1em;
$footerHeight: (156/$baseFontSize) * 1em;
$footerContentMarginTop: (40/$baseFontSize) * 1em;
$footerContentWidth: $pageWidth;
$footerContentMobileMarginTop: (40/$baseFontSize) * 1em;
$footerContentMobileWidth: $pageWidth;
$footerContentMobileLogoPaddingLeft: (10/$baseFontSize) * 1em;
$staticLinksWidth: (200/$baseFontSize) * 1em;
$staticLinksMarginLeft: (110/$baseFontSize) * 1em;
$staticLinksFontWeight: $bold;
$staticLinksPaddingLeft_Mobile: (10/$baseFontSize) * 1em;
$staticLinksPaddingTop_Mobile: (25/$baseFontSize) * 1em;
$staticLinksWidth_Mobile: 100%;
$staticLinksBackgroundColor_Mobile: rgba(240,245,248,1);
$staticLinksHeight_Mobile: (115/$baseFontSize) * 1em;
$contactUsWidth : (200/$baseFontSize) * 1em;
$contactUsMarginLeft : (80/$baseFontSize) * 1em;
$contactUsPaddingLeft_Mobile : (10/$baseFontSize) * 1em;
$contactUsPaddingTop_Mobile : (25/$baseFontSize) * 1em;
$contactUsWidth_Mobile : 100%;
$contactUsMarginBottom_Mobile: (20/$baseFontSize) * 1em;
*/

.Layout {
  width: 100%;
  overflow: hidden;
}

.Page {
  width: $desktopPageWidth * 1px;
  margin-left: auto;
  margin-right: auto;
  @include media("<=phone") {
    width: auto;
    margin-left: $pageMarginHorizontal_Mobile * 1px;
    margin-right: $pageMarginHorizontal_Mobile * 1px;
  }
}

.Page_Dim {
  opacity: .2;
}

.PageContent {
  width: $desktopPageContentWidth * 1px;
  margin-left: auto;
  margin-right: auto;
  @include media("<=phone") {
    width: 100%;
    position: relative;
    margin-top: $topCalloutHeight + $topAccentHeight + $headerHeight_Mobile;
  }
  &.NoHeader {
    margin-top: 0;
  }
}

.Layout_EmailVerification {
  width: $desktopPageContentWidth * 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  @include media("<=phone") {
    width: 100%;
  }
}

.TopAccent {
  background-color: $secondaryAccent;
  height: $topAccentHeight;
  @include media("<=phone") {
    position:fixed;
    width: 100%;
    top: 0;
    z-index: 500;
  }
}

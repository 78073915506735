@import "../../styles/common.scss";

.ListPaidIndicator
{
  display: flex;
}

.ListPaidIndicator
{
  .PaidEvent {
    display: flex;
    font-weight: bold;
    white-space: nowrap;
  }

  .Details {
    margin-left: 1em;
    display: flex;
    font-size: $smallerTextSize;
    font-style: italic;
  }
}


@import '../../styles/common.scss';


.ActivityTypePicker_ToggleSelectAllTerms-container {
    // internal workings of enclosed checkbox has 9px padding, remove that from the top
    margin-top: -9px;
    // and add 7 to give 9+7= 16px of bottom margin
    margin-bottom: 7px;
}

.ActivityTypePicker_Container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 12px;

}

@media (max-width: $mobileMaxWidth) {
    .ActivityTypePicker_Container {
        gap: 16px 8px;
    }
}

.ActivityTypeToggleChip {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;
    border-radius: 80px;
    padding: 0 10px;
    border: 1px solid $clubDirectory_mdGrey;
    background: #ffffff;

    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    transition: .1s all;

    cursor: pointer;

    &.selected {
        background: $clubDirectory_dkBlue;
        color: white;
    }
}
@import "../../../styles/ignite/common.scss";

.profile-page {
    margin-top: 20px;

    .content {
        display: flex;
        gap: 15px;

        &-main {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex-grow: 1;
        }

        .profile-panel {
            border: 1px solid $softBorder;
            border-radius: 4px;
            padding: 40px;
            position: relative;

            .top-content {
                border: none;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;

                .title {
                    margin: 0;
                    padding: 0;
                    color: #0177C0;
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            &.personal-info {
                .bottom-content {
                    display: flex;
                    flex-direction: column;
                    border: none;
                    flex-wrap: wrap;

                    .profile-group {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        column-gap: 30px;
                        row-gap: 30px;

                        @include media("<=sm") {
                            grid-template-columns: 1fr;
                            gap: 0;

                            .language {
                                grid-row-start: auto;
                            }
                        }
                    }

                    .help-text {
                        font-size: 14px;
                        font-weight: 600;
                        color: #555;
                        margin-top: 17px;
                    }
                }

                &:not(.editing) {
                    padding: 30px;

                    @include media("<=sm") {
                        .bottom-content {
                            overflow: hidden;
                            text-overflow: ellipsis;

                            .reset-password {
                                flex-direction: column !important;
                            }

                            .profile-item {
                                flex-direction: row;
                                max-width: 100%;

                                &__label {
                                    width: 145px;
                                }

                                &__input {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }

            &.contact-methods {
                .bottom-content {
                    border: none;
                    display: flex;

                    @include media("<=sm") {
                        flex-direction: column;

                        .profile-group {
                            flex-direction: column;

                            .profile-item {
                                flex-direction: column !important;
                            }
                        }
                    }
                }

                .profile-group {
                    display: flex;
                    column-gap: 60px;
                }
            }

            &.notifications {
                .bottom-content {
                    border: none;

                    .event-item {
                        gap: 20px;

                        .base {
                            color: #0067A6;
                        }

                        .track {
                            background: #80BFE6;
                        }
                    }
                }

                @include media("<=sm") {
                    padding: 20px;
                }
            }

            .subtitle {
                margin-bottom: 12px;
                font-size: 16px;
                font-weight: 700;
            }

            .input-dob {
                height: 40px;
            }

            &.editing {
                border: 2px solid $primaryAccent;

                .bottom-content {
                    gap: 30px;

                    .profile-group {
                        gap: 20px;

                        @include media("<=md") {
                            gap: 30px;
                        }
                    }
                }

                &.contact-methods {
                    .bottom-content {
                        display: flex;
                        flex-direction: column !important;

                        .profile-group {
                            gap: 30px;
                        }

                        .preferred-method {
                            .profile-item {
                                flex-direction: row;
                                gap: 15px;
                                max-width: 100%;
                            }
                        }
                    }

                    .phone {
                        grid-area: phone;
                    }

                    .email {
                        grid-area: mail;
                    }

                    .preferred-method {
                        .left-content {
                            width: 260px;
                        }

                        .right-content {
                            max-width: 275px;
                        }
                    }
                }

                &.personal-info {
                    .profile-group {
                        grid-template-columns: 1fr 1fr;

                        @include media("<=sm") {
                            grid-template-columns: 1fr;
                        }
                    }
                }

                .profile-item {
                    flex-grow: 1;

                    &__input {
                        box-sizing: border-box;
                        height: 40px;

                        &.dob {
                            padding: 10.5px 14px;
                        }
                    }

                    .select {
                        pointer-events: initial;

                        &-base {
                            padding: 8px 12px;
                        }
                    }

                    .preferred-contact-method-select {
                        pointer-events: initial;
                        width: 100%;

                        .preferred-contact-method-select-base {
                            padding: 8px 12px;
                        }
                    }

                    &.language {
                        grid-row-start: 5;

                        .select {
                            width: 100%;
                        }
                    }

                    &.full-width {
                        max-width: 500px;

                        .profile-item__main {
                            width: 260px;
                        }

                        .select {
                            width: 100%;
                        }
                    }

                    .label-error.dob {
                        margin-left: 0;
                    }
                }

                .profile-group.contact-methods {
                    flex-direction: column;
                }

                .phone-number-base {
                    width: 100%;
                }

                .select {
                    .base-input {
                        height: 40px;
                    }
                    .auto-complete {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }

                &.health-plan {
                    .bottom-content {
                        border: none;

                        .profile-group {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            gap: 10px;
                        }

                        .profile-item {
                            max-width: 270px;
                            width: 47%;

                            &.confirmation-code-switcher {
                                width: 48%;
                                max-width: 700px;
                            }

                            @include media("<=md") {
                                max-width: 240px;
                            }
                        }
                    }

                    @include media("<=sm") {
                        padding: 20px;

                        .profile-group {
                            gap: 5px !important;

                            .profile-item {
                                width: 100%;
                            }
                        }

                        .profile-item {
                            width: 100%;

                            &.member-id {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }

            .profile-item {
                &.eligibility {
                    .icon {
                        height: 20px;
                        width: 20px;

                        &.success {
                            color: #10BC41;
                        }

                        &.pending {
                            color: #898989;
                        }

                        &.error {
                            color: #D4383A;
                        }
                    }
                }
            }

            &:not(.editing) {
                .profile-item__input {
                    padding: 0;
                    cursor: default;
                }

                .date-of-birth {
                    .icon {
                        display: none;
                    }
                }

                .wrapper {
                    border: none;
                }

                .select {
                    .icon {
                        display: none;
                    }
                }

                .preferred-method {
                    .left-content {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                    }
                }

                &.health-plan {
                    .top-content {
                        margin-bottom: 0;
                    }

                    .bottom-content {
                        border: none;

                        .profile-item__label {
                            text-wrap: nowrap;
                        }

                        .additional-payer-id,
                        .confirmation-code-switcher {
                            display: none;
                        }
                    }

                    .profile-group {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;

                        &.with-code {
                            display: block;
                        }

                        .profile-item {
                            &.confirmation-code {
                                grid-row-start: 3;
                            }
                        }

                        @include media("<=sm") {
                            grid-template-columns: 1fr;
                        }
                    }

                    @include media("<=sm") {
                        padding: 15px;

                        .top-content {
                            align-items: center;

                            .title {
                                font-size: 20px;
                            }

                            margin-bottom: 30px;
                        }
                    }
                }
            }

            .title {
                font-size: 32px;
                font-weight: bold;
                line-height: 150%;
                text-align: center;
                margin-top: 26px;

                @include media("<=phone") {
                    font-size: 24px;
                }
            }

            .separator {
                opacity: .5;
                margin: 20px auto 30px;
            }

            .intro-text {
                font-size: 24px;
                text-align: center;
                margin-bottom: 38px;
                line-height: 28.8px;

                @include media("<=phone") {
                    font-size: 20px;
                }
            }

            .profile-item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 10px;
                max-width: 260px;
                gap: 6px;
                position: relative;

                .field-indicator-wrapper {
                    position: absolute;
                    right: -26px;
                }

                .eligibility-status-icon {
                    cursor: pointer;
                }

                &.reset-password {
                    margin-top: 30px;

                    .profile-item__label {
                        margin-bottom: 7px;
                    }

                    .reset-button {
                        &.disabled-button {
                            color: #fff !important;
                            background: $softBorder;
                            border: 1px solid $softBorder;
                        }
                    }
                }

                &:last-child {
                    .profile-item__label {
                        align-self: start;
                    }
                }

                &__main {
                    flex-grow: 1;
                    font-size: 16px;
                    font-weight: 400;
                    display: flex;

                    .date-of-birth-picker {
                        .label-error {
                            margin-left: 6px;
                        }

                        &.read-only {
                            pointer-events: none;
                        }
                    }

                    &.preferred-method {
                        .contacts-button-wrapper {
                            .button {
                                font-size: 18px;
                                font-weight: 700;
                                color: #555;
                                width: 113px;
                                height: 40px;
                                margin-right: 2px;

                                &.active {
                                    background: #0280CD;
                                    color: #fff;
                                }

                                @include media("<=sm") {
                                    width: 72px;
                                    font-size: 16px;
                                }
                            }

                            @include media("<=sm") {
                                margin-left: 5px;
                                display: flex;
                            }
                        }

                        .help-text {
                            font-size: 16px;
                            font-weight: 600;
                            margin-top: 20px;
                            max-width: 480px;

                            @include media("<=sm") {
                                font-size: 15px;
                            }
                        }
                    }

                    @include media("<=phone") {
                        font-size: 16px;
                    }
                }

                &__text {
                    padding: 4.5px 0 5.5px;
                }

                &__help-text {
                    font-size: 16px;
                    font-weight: 700;
                    margin-left: 20px;
                    max-width: 320px;

                    @include media("<=phone") {
                        display: none;
                    }
                }

                &__input {
                    text-overflow: ellipsis;
                    height: 20px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #555;

                    &.read-only {
                        pointer-events: none;
                        touch-action: none;
                    }

                    &.inactive {
                        color: #898989;
                    }

                    @include media("<=phone") {
                        font-size: 16px !important;
                    }
                }

                &__base {
                    position: relative;

                    &.error {
                        border: 1px solid #d00;
                    }

                    &::before {
                        display: none;
                    }

                    &.read-only {
                        pointer-events: none;
                        touch-action: none;
                    }
                }

                &__label {
                    color: #000;
                    font-size: 16px;
                    font-weight: 700;
                    display: flex;
                    align-items: center;

                    @include media("<=sm") {
                        font-size: 16px;
                    }
                }

                &__checkbox {
                    margin-right: 13px;
                    padding-top: 0;

                    @include media("<=phone") {
                        margin-right: 0;
                        padding-bottom: 0;
                    }
                }

                .switcher {
                    font-size: 16px;

                    &.positive {
                        .switcher__positive-text {
                            color: $primaryAccent;
                        }
                    }

                    &.negative {
                        .switcher__negative-text {
                            color: $primaryAccent;
                        }
                    }
                }

                &.date-of-birth {
                    min-height: 35px;
                }

                @include media("<=phone") {
                    margin-bottom: 10px;
                }
            }

            .reset-button {
                text-transform: none;
                font-weight: 800;
                border-color: $primaryDark;
                color: $primaryDark;
                font-size: 16px;
            }

            .disabled-button {
                color: $primaryDark !important;
            }

            .checkbox-column {
                display: flex;
                justify-content: flex-end;
                margin: 15px 15px 0;

                &__text {
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 18px;
                }
            }

            .button-wrapper {
                display: flex;
                text-align: center;
                justify-content: flex-end;
                font-weight: bold;
                align-items: flex-end;
                gap: 24px;
                margin-bottom: 25px;

                .button {
                    font-size: 16px;
                    padding: 7px 22px;
                    font-weight: 800;
                    cursor: pointer;
                    width: 110px;
                    height: 40px;

                    &.update {
                        background: $primaryDark;
                        color: #fff;

                        &.disabled {
                            background: $softBorder;
                            pointer-events: none;
                        }
                    }

                    &.cancel {
                        color: rgba(0, 0, 0, 0.26);
                        border: 1px solid rgba(0, 0, 0, 0.26);
                    }
                }

                .edit-button {
                    color: $primaryDark;
                    font-size: 16px;
                    font-weight: 600;
                    cursor: pointer;

                    .icon-wrapper {
                        color: $primaryDark;
                        font-weight: bold;
                        align-self: flex-end;
                        border-radius: 5px;
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;

                        .icon {
                            fill: $primaryDark;
                        }
                    }
                }

                @include media("<=phone") {
                    top: 5px;
                    right: 1px;
                }
            }

            .provider-wrapper {
                margin-top: 30px;
                border-top: 1px solid $softBorder;
                padding-top: 30px;

                .profile-item {
                    margin-bottom: 30px;
                    text-transform: uppercase;
                }
            }

            &.contact-methods {
                .phone {
                    max-width: 205px;
                }

                @include media("<=phone") {
                    .phone {
                        max-width: 260px;
                    }
                }
            }
        }

        .select {
            pointer-events: none;
        }

        @include media("<=md") {
            flex-direction: column;
        }
    }

    .preferred-contact-method-select {
        pointer-events: none;

        .preferred-contact-method-select-base {
            padding: 0;
        }
    }

    .profile-tabs {
        margin: 10px;
        min-width: 160px;

        .profile-tabs-indicator {
            display: none;
        }

        .profile-tab {
            color: rgba(0, 0, 0, 0.60);
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 900;
            border-bottom: 2px solid transparent;

            &.selected {
                color: #0177C0;
                border-right: 2px solid #0177C0;
            }

            .profile-tab-content {
                justify-content: start;
                align-items: start;
            }

            @include media("<=phone") {
                font-size: 18px;

                &.selected {
                    border-right: none;
                    border-bottom: 2px solid #0177C0;
                }
            }
        }
    }

    .notifications {
        padding: 30px !important;

        .event-item {
            display: flex;
            align-items: center;
            gap: 30px;
            margin-bottom: 20px;

            .label {
                font-size: 16px;
                font-weight: 600;
            }
        }

        .switcher {
            font-size: 16px;
            font-weight: 700;
            color: #898989;
            display: flex;
            align-items: center;

            &.positive {
                .switcher__positive-text {
                    color: $primaryAccent;
                }
            }

            &.negative {
                .switcher__negative-text {
                    color: $primaryAccent;
                }
            }
        }
    }

    @include media("<=md") {
        .profile-tabs {
            margin: 0;
            /* Hide scrollbar */
            .container {
                overflow: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }

            .container::-webkit-scrollbar {
                display: none;
            }

            .profile-tab {
                padding: 6px 5px;
            }

            .profile-tab-content {
                font-size: 14px;
            }
        }

        .profile-panel .profile-item {
            max-width: 240px;
        }
    }

    @include media("<=sm") {
        .top-content {

            .button-wrapper {
                margin-bottom: 0 !important;
            }
        }
    }
}

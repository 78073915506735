@import "../../styles/common.scss";

.NewMemberRegistrationWizard {
  @include media(">phone") {
    width: 100%;
    margin: 0 auto;
  }

  p.WizardStepHeading {
    text-align: center;
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 25px;
  }
}

.NewMemberRegistrationWizard_Wrapper {
  > h2,
  > .NewMemberPassionsStep_SubHeader,
  > .CustomEnrollmentContactDetailsStep_SubHeader {
    text-align: center;
  }
  > h2 {
    padding: 20px 0 0 0;
  }
  &.NewMemberAccountStep {
    .MuiGrid-item {
      padding: 0;
    }
    .field-indicator-wrapper {
      height: 52px;
      padding-left: 5px;
    }
  }
}

.SelectPassions_Passions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  @include media("<=phone") {
    margin-top: 40px;
    grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  }

  .SelectPassions_Passion {
    cursor: pointer;
    width: 125px;
    height: 125px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-size: contain;
    @include media("<=phone") {
      background-image: none !important;
      width: 165px;
      min-height: 34px;
      height: auto;
      border-radius: 8px;
    }

    .SelectPassions_Passion_Top {
      width: 100%;
      height: 81px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @include media("<=phone") {
        display: none;
      }

      .Checkmark {
        width: 46px;
        mix-blend-mode: normal;
        opacity: 0.6;
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35));
      }

      .SelectPassions_Passion_Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.15);
      }
    }

    .SelectPassions_Passion_Bottom {
      .SelectPassions_Passion_PassionName {
        max-width: 100%;
        padding: 0 5px;
        height: 44px;
        background: #0177C0;
        border-radius: 0 0 16px 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media("<=phone") {
          border-radius: 8px;
          background: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0.26);
          color: #0177C0;
          width: 100%;
          height: 100%;
          padding: 6px 8px;
          box-sizing: border-box;
        }
      }
    }

    &.selected {
      .SelectPassions_Passion_Top {
        .SelectPassions_Passion_Overlay {
          background: #0177C0;
          opacity: 0.8;
          border-radius: 16px 16px 0 0;
        }

        .Checkmark {
          opacity: 1;
          filter: none;
          z-index: 1;
        }
      }

      .SelectPassions_Passion_Bottom {
        .SelectPassions_Passion_PassionName {
          @include media("<=phone") {
            background: #0177C0;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}



.NewMemberPassionsStep_NextContainer {
  display: flex;
  flex-direction: row-reverse;
  button {
    color: #0091e9;
    font-size: 16px;
  }
}

.NewMemberPassionsStep_SkipLinkContainer {
  display: flex;
  flex-direction: row-reverse;
  padding: 20px 0;
  .NewMemberPassionsStep_SkipLink {
    display: flex;
    vertical-align: middle;
    color: #0091e9;
    cursor: pointer;
  }
}

.NewMemberRegistrationWizard_Wrapper_InnerContainer {
  position: relative;
  border: 1px solid darkgrey;
  padding: 50px 50px 100px 50px;
  margin: 30px 0 40px 0;
  min-height: 300px;
  .NewMemberRegistrationWizard_PersonalInformationInnerHeader,
  .NewMemberRegistrationWizard_AccountInnerHeader,
  .NewMemberActivityTypesStep_SubHeader,
  .NewMemberReportActivityStep_SubHeader {
    font-weight: bold;
  }
  .NewMemberActivityTypesStep_SubHeader {
    margin-bottom: 20px;
  }
  .NewMemberRegistrationWizard_FieldsContainer {
    position: relative;
  }
  .field-indicator-wrapper {
    padding-left: 0;
    margin-top: 6px;
  }
  .PersonalInformation_MemberIDNote {
    font-size: 12px;
    vertical-align: text-top;
  }
  .NewMemberPersonalInformationStep_CardWrapper {
    position: absolute;
    right: 0;
    top: 0;
    .card-img {
      width: 286px;
      height: 176px;
    }
    @include media("<=959px") {
      position: relative;
    }
  }
  .NewMemberRegistrationWizard_AccountNote {
    font-size: 12px;
  }
  .NewMemberPersonalInformationStep_HealthPlanNameField_Label {
    width: calc(100% - 18px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .NewMemberReportActivityStep_Details {
    font-size: 14px;
    margin: 20px 0;
  }
  .NewMemberReportActivityStep_DetailsLegal {
    font-size: 14px;
    font-style: italic;
  }
  .NewMemberReportActivityStep_InfoText {
    font-size: 14px;
    font-weight: bold;
  }
  .NewMemberReportActivityStep_Confirmation {
    font-size: 14px;
  }
  .NewMemberReportActivityStep_MonthInputElementContainer {
    margin: 15px 0;
    height: 62px;
  }
  .NewMemberReportActivityStep_MonthInputContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    @include media("<=820px") {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
    @include media("<=600px") {
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }
  .NewMemberReportActivityStep_MonthInput {
    display: flex;
    flex-direction: row;
    width: 225px;
    justify-content: space-around;
    @include media("<=600px") {
      width: 100%;
    }
    .NewMemberReportActivityStep_MonthInputLabel {
      line-height: 40px;
      font-weight: bold;
    }
    .MemberRegistrationWizard_Input {
      width: 115px;
    }
  }
  .MonthInputLabel_Errored {
    color: red;
    font-size: 12px;
    margin: 0 10px;
    max-width: 225px;
    @include media("<=600px") {
      margin: auto;
    }
  }
  .NewMemberRegistrationWizard_Wrapper_TermsAndAgreements label {
    font-weight: bold;
    line-height: 42px;
    vertical-align: middle;
  }
  .NewMemberRegistrationWizard_Wrapper_EnrollmentMessage {
    font-size: 12px;
    margin-top: 10px;
    font-style: italic;
  }
  .NewMemberRegistrationWizard_ExternalLink_Icon {
    vertical-align: text-bottom;
    font-size: 1.8em;
  }
  .MuiFormHelperText-root.Mui-error {
    font-weight: bold;
    margin-left: 2px;
  }
  .NewMemberRegistrationWizard_Wrapper_AlreadyLoggedIn {
    .NewMemberRegistrationWizard_Wrapper_AlreadyLoggedInText {
      margin-right: 8px;
    }
  }
}

.NewMemberRegistrationWizard.CustomEnrollmentFlow {
  h2 {
    text-align: left;
  }
  .NewMemberRegistrationWizard_Wrapper_InnerContainer {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    border: none;
    margin-top: 10px;
  }
  .NewMemberReportActivityStep_DetailsLegal {
    font-style: normal;
  }
  .NewMemberReportActivityStep_MonthInputContainer {
    border: 1px solid darkgrey;
    padding: 20px 40px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    @include media("<=820px") {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
    @include media("<=600px") {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }
  .NewMemberReportActivityStep_MonthInputLabel {
    font-weight: normal;
  }
  .MonthInputLabel_Errored {
    margin: 0 10px;
    @include media("<=600px") {
      margin: auto;
    }
  }
  .NewMemberReportActivityStep_Confirmation {
    margin: 10px 0;
  }
  .NewMemberReportActivityStep_NextContainer {
    left: 0;
    right: 0;
  }
}

.NewMemberAccountStep_NextContainer,
.NewMemberPersonalInformationStep_NextContainer,
.NewMemberActivityTypeStep_NextContainer,
.NewMemberReportActivityStep_NextContainer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 50px;
  right: 50px;
  bottom: 30px;
  flex-direction: row-reverse;
  button {
    color: #0091e9;
    font-size: 16px;
    padding-left: 1.2em;
    padding-right: 1.2em;
    &:disabled {
      color: darkgrey;
      background-color: white;
      border-color: darkgrey;
    }
  }
}
.NewMemberRegistrationWizard_SubmitButtonLoading {
  width: auto;
  margin-left: 0;
  margin-right: 0;
  .MuiCircularProgress-root {
    width: 42px !important;
    height: 42px !important;
  }
}

.CustomEnrollmentContactDetailsStep {
  .CustomEnrollmentContactDetailsStep_HearAboutUsSelect {
    .PrivateNotchedOutline-legendLabelled-3 {
      visibility: visible;
      font-size: 0.75rem;
    }
  }
}
.CustomEnrollmentPersonalInformationStep_ExternalActivityProfileUrlNotes {
  font-size: 12px;
  margin-top: -15px;
}
.CustomEnrollmentHealthCareCoverageStep_PhoneNote {
  font-size: 12px;
}
.CustomEnrollmentPersonalInformationStep_MemberIdInfoContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}
.CustomEnrollmentPersonalInformationStep_ExternalActivityProfileUrlNotes_InfoButton {
  cursor: pointer;
}
.CustomEnrollmentHealthCareCoverageStep {
  h2 {
    max-width: 400px;
    text-align: center;
    margin: auto;
  }
  .MuiFormControl-root {
    margin: 10px 0;
    height: 62px;
  }
  .field-indicator-wrapper {
    padding-left: 0;
    margin-top: 18px;
  }
}

.InfoModalWrapper {
  height: 100vh;
  max-width: 570px;
  position: relative;
  margin: 0 auto;
  outline: none;
}
.InfoModal {
  max-width: 570px;
  background: #fff;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  padding: 30px;
  max-height: 85%;
  overflow-y: auto;
}
.InfoModal_InnerContainer {
  padding: 30px;
  border: 1px solid darkgrey;
}
.InfoModal_Title {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid darkgrey;
}
.InfoModal_ButtonContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.InfoModal_Button {
  color: #0280CD;
}
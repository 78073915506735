@import "../../styles/common.scss";

.RsvpAttendanceListSelfGuided {
  position: relative;

  .RsvpAttendanceListSelfGuided_Instructions {
    margin-bottom: 1.5em;
  }

  .RsvpAttendanceListSelfGuided_LoadingMessage {
    color: $errored;
    font-size: $mediumHeadlineTextSize;
  }

  .RsvpAttendanceListSelfGuided_Title {
    font-size: $mediumHeadlineTextSize;
  }

  .RsvpAttendanceListSelfGuided_AttendanceUpdate {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    align-items: center;
    @include media("<=phone") {
      align-items: flex-start;
      flex-direction: column;
    }
    margin-top: 1.5em;
  }

  .RsvpAttendanceListSelfGuided_AttendanceUpdateBottom {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
    align-items: center;
    @include media("<=phone") {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .RsvpAttendanceListSelfGuided_AttendanceUpdateTitle {
    display: flex;
    align-items: center;
    font-size: $mediumHeadlineTextSize;
    margin-right: 1em;
  }

  .RsvpAttendanceListSelfGuided_AttendanceUpdateButton {
    display: flex;
  }

  .RsvpAttendanceListSelfGuided_SavingLoading {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    margin-left: -50px;
    margin-top: 10em;
  }

  .RsvpAttendanceListSelfGuided_SavingSuccess {
    display: flex;
    margin-left: 1em;
    max-width: 20em;
    @include media("<=phone") {
      margin-top: 1em;
      margin-left: 0;
    }
  }

  .RsvpAttendanceListSelfGuided_PastUsersTitle {
    margin-top: 2em;
    font-size: $mediumHeadlineTextSize;
  }

  .RsvpAttendanceListSelfGuided_PastUsersInstructions {
    margin-bottom: 1em;
  }

  .RsvpAttendanceRow {
  }

  .RsvpAttendanceRow_Switch {
    width: 15%;
  }

  .RsvpAttendanceRow_Name {
    font-size: $largeTextSize;
    width: 30%;
    @include media("<=phone") {
      font-weight: $bold;
    }
  }

  .RsvpAttendanceRow_Contact {
    width: 35%;
    display: flex;
    flex-direction: column;
  }

  .RsvpAttendanceRow_Contact a {
    color: $foreground;
    text-decoration: none;
    cursor: pointer;
  }

  .RsvpAttendanceRow_AttendedOrNot {
    text-align: right;
    width: 20%;
    font-size: $largeTextSize;
    @include media("<=phone") {
      text-align: left;
    }
  }
}


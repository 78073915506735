@import "../../styles/common.scss";

.EditEventPage_FlexRow
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.EditEventPage_RadioFlexRow
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.EditEventPage_RadioNoWrapFlexRow
{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.EditEventPage_FlexColumn
{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.EditEventPage_FlexData
{
  display: flex;
  width: 40%;
}

form hr {
  color: $softBorder;
  background-color: $softBorder;
}

.EditEventPage_FormControl {
  display: flex ! important;
  flex-direction: row ! important;
  flex-wrap: wrap ! important;
  margin-top: .1em;
  margin-bottom: .1em;
}

.EditEventPage_Label {
  display: flex;
  min-width: 10em;
  max-width: 12em;
  margin-right: .5em;
}

.EditEventPage_Field {
  display: flex;
  flex-direction: column;
  width: 20em;
  margin-right: 1em;
}

.EditEventPage_FormhelperText {
  display: flex;
  width: 19em;
  @include media("<=phone") {
    margin-top: .5em;
    margin-bottom: .5em;
  }
}

.EditEventPage_FileInputField_TopText {
}

.EditEventPage_FileInputField_Error {
  color: red;
  font-size: $formInstructionsTextSize;
  margin-bottom: .5em;
}

.EditEventPage_FileInputField_InputElement {
  margin-top: .5em;
  margin-bottom: .5em;
}

.EditEventPage_DateAndTime, .EditEventPage_EventLeader
{
  width: 30em;
}

// Prevent the "Invalid Date Format" error text from pushing the date input
// field upwards when the error text appears.
.EditEventPage_DateField, .EditEventPage_TimeField {
  align-self: flex-start;
}

.EditEventPage_VirtualEventDetails
{
  .EditEventPage_FormControl:not(:first-child) {
    margin-top: 1em;
  }
}

.EditEventPage_Address
{
  .EditEventPage_FlexData {
    margin-top: .2em;
  }
  .EditEventPage_FlexData:not(.EditEventPostalCode)
  {
    width: auto;
  }

  .EditEventPage_FlexData:not(:last-of-type)
  {
    margin-bottom: 1em;
  }

  .EditEventPage_LocationName
  {
    margin-top: 1em;
  }

  .EditEventPostalCode
  {
    margin-left: 1em;
    flex-grow: 1;
  }
}

.EditEventPage_EventLeaderName
{
  margin-top: 1em;
}

.EditEventPage_EventLeaderFirstName
{
  margin-right: 1em;
}

.EditEventPage_EventLeaderEmail, .EditEventPage_EventLeaderPhone
{
  margin-bottom: 1em;
  width: 75%;
}

.EditEventPage_DateLabel
{
  display: flex;
  margin-right: 1em;
  align-items: center;
}

.EditEventPage_DateField
{
  display: flex;
  align-items: center;
  margin-right: 2em;
  width: 12em;
  @include media("<=phone") {
    width: 15em;
  }
}

.EditEventPage_DateField svg,
.EditEventPage_TimeField svg
{
  font-size: $largeHeadlineTextSize;
}

.EditEventPage_TimeLabel
{
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.EditEventPage_TimeField
{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 15em;
  @include media("<=phone") {
    margin-top: .5em;
    width: 15em;
  }
}

.EditEventPage_TimeField_Hours {
  display: flex;
  margin-right: .25em;
  width: 5em;
}

.EditEventPage_TimeField_Minutes {
  display: flex;
  margin-left: .25em;
  margin-right: .25em;
  width: 5em;
}

.EditEventPage_TimeField_AmPm {
  display: flex;
  margin-left: .25em;
  width: 5em;
}

.EditEventPage_TimeZoneLabel
{
  margin-right: 1em;
  display: flex;
  align-items: center;
}

.EditEventPage_TimeZoneField
{
  display: flex;
}

.EditEventPage_RightMarginLabel
{
  margin-right: 1em;
  display: flex;
  align-items: center;
}

.EditEventPage_LeftMarginLabel
{
  margin-left: 1em;
  display: flex;
  align-items: center;
}

.EditEventPage_WeekDay {
  margin-right: 10px !important;
}

.EditEventPage_ButtonContainerFormControl
{
  width: 100%;
}

.EditEventPage_ButtonContainer {
  margin-top: 2em;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items:center;
}

.EditEventPage_ButtonContainerOLD
{
  justify-content: flex-end;
  width: 100%;
  @include media("<=phone") {
    justify-content: center;
  }
}

.EditEventPage_Button
{
  display: flex;
  margin-left: .5em;
}

.EditEventPage_SavingMessage
{
  color: $errored;
  @include media("<=phone") {
    display: block;
    width: 100%;
    text-align:center;
  }
}

.EditEventPage_Loading
{
  margin-top:5em;
  margin-bottom:5em;
}

.EditEventPage_LoadingMessage
{
  color: $errored;
}

.EditEventPage_Cancelled
{
  color: $errored;
  font-size:$mediumHeadlineTextSize;
  text-align:center;
  margin: 2em;
  font-weight: bold;
}

.EditEventPage_SaveIndicator {
  margin-top: .5em;
  display: flex;
  align-items: center;
  justify-content: center;
}


@import "../../../styles/ignite/common.scss";

.payer-qualification {
    .title-wrapper {
        font-size: 32px;
        font-weight: 800;
        display: flex;
        width: 100%;
        margin: 48px auto 74px;
        max-width: 680px;

        @include media("<=phone") {
            font-size: 24px;
            max-width: 500px;
            flex-direction: column;
            margin-top: 0;
            text-align: center;
            align-items: center;
        }

        .title-wrapper__image_wrapper {
            padding-right: 55px;

            @include media("<=phone") {
                margin-right: 0;
                margin-top: 0;
                max-width: 120px;
                min-height: 100px;
                margin-bottom: 20px;
            }
        }
    }
}

.button-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    margin: 0 auto 28px;

    .select-payer {
        margin-bottom: 22px;
        font-weight: bold;
        background: $primaryDark;
        color: $background;
        font-size: 18px;

        &:hover {
            background: $primaryAccent;
        }

        &__icon {
            margin-left: 7px;
        }
    }
}

.skip-for-now {
    color: $primaryDark;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
}

.bottom-text {
    font-size: 18px;
    line-height: 25.2px;
    max-width: 487px;
    margin: 0 auto 40px;
    text-align: center;
}

@import "../../../styles/common.scss";

.PayerMemberRegistrationWizard {
  .PageContent {
    width: 100%;
  }
  @include media(">phone") {
    margin: 0 auto;

    > h1 {
      text-align: center;
    }
  }

  .RegistrationWizardStep {
    margin-bottom: 15px;
  }
}

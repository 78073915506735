@import "../../styles/common.scss";

.PayerDetailsForm_Wrapper {
  padding: 15px;
  min-height: 25em;
  @include media("<=phone") {
    padding: 1px;
  }

  .PayerDetailsForm_FieldIndicator.validated {
    color: $primaryAccent;
  }

  .PayerDetailsForm_FieldIndicator {
    color: #bababa;
  }

  .PayerDetailsForm_FieldIndicator.error {
    color: red;
  }

  .PayerDetailsForm_FormError {
    color: red;
  }

  .MemberSelectPayer {
    margin-bottom: 15px;
  }

  .PayerDetailsForm_FieldWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-bottom: 15px;
    @include media("<=phone") {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }

  .PayerDetailsForm_Field {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content min-content;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .PayerDetailsForm_ConfirmationCodeFieldWrapper {
    @include media(">phone") {
      grid-template-columns: 2fr;
    }
  }

  .PayerDetailsForm_ConfirmationCodeField {
    @include media(">phone") {
      grid-template-columns: 2fr min-content;
    }
  }

  .PayerDetailsForm_ElementHelpText {
    font-size: $formEntryTextSize;
    display: block;
  }

  .PayerDetailsForm_FieldIndicatorWrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .PayerDetailsForm_Input, .PayerDetailsForm_InputHelpWrapper {
    flex: 1;
  }

  .PayerDetailsForm_Bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .PayerDetailsForm_SubmitButton {
    margin-bottom: 15px;
  }

  .PayerDetailsForm_FormHelpText {
    font-weight: bold;
    @include media("<=phone") {
      text-align: center;
    }
  }

  .PayerDetailsForm_FormHelpText_ContactPhoneNumber {
    margin-left: .3em;
    white-space: nowrap;
  }

  .PayerDetailsForm_TermsOfUseLink {
    text-decoration: none;
    color: $primaryAccent;
  }

  .placeholder {
    display: block;
    @include media("<=phone") {
      display: none;
    }
  }

  .PayerDetailsFormField_TermsOfUseField {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    font-size: 18px;
    margin-bottom: 15px;
    @include media("<=phone") {
      text-align: center;
    }
  }

  .PayerDetailsFormField_TermsOfUseField {
    margin-bottom: 0;
  }

  .PayerDetailsFormField_TermsOfUseWrapper {
    display: block;
    text-align: left;
    width: 90%;
    label {
      padding: 9px;
      font-size: 16px;
    }
    @include media("<=phone") {
      text-align: center;
      margin-bottom: .5em;
    }
  }

  .MemberSelectPayer .MemberSelectPayer_InsuranceIdField,
  .MemberSelectPayer_HealthPlanNameField {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

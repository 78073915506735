@import "../../../../styles/common.scss";

$primarytDark: #0177C0;
$success2: #10BC41;

.edit-member-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  overflow: auto;

  .edit-member-modal-wrapper {
    width: 785px;
    min-height: 415px;
    background: #fff;
    padding: 50px 50px 30px;
    box-sizing: border-box;
    position: relative;
    margin: 0 10px;
    max-height: 100%;
    overflow: auto;

    .close-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    .title {
      color: #0091E9;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .subtitle {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }

  @include media("<=phone") {
    padding: 1px;
  }

  .edit-form__field-wrapper {
    margin-bottom: 17px;

    @include media(">phone") {
      grid-template-columns: 2fr;
    }
  }

  .edit-form__field_bold {
    font-weight: 600;
  }

  .edit-form__button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 28px;

    @include media("<=md") {
      margin-top: 50px;
    }

    @include media("<=phone") {
      align-items: stretch;
    }
  }

  .edit-form__button {
    margin-bottom: 15px;
    max-height: 40px;
    font-size: 16px;
    font-weight: 800;
    padding: 9px 20px;
    background-color: $primarytDark;
    color: #fff !important;
    cursor: pointer;

    &:hover {
      background: #0177C0 !important;
    }

    &.disabled {
      background: $softBorder;
    }

    @include media("<=phone") {
      display: flex;
      justify-content: center;
      margin-right: 1.8em;
    }
  }

  .edit-form {
    border-radius: .3em;

    @include media("<=phone") {
      border: none;
      padding: 0;
    }

    &__field-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-bottom: 15px;

      &.dense-row {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;

        @include media("<=sm") {
          grid-template-columns: 1fr;
          grid-gap: 10px;
        }
      }

      &.full-width {
        grid-template-columns: 1fr;
      }

      @include media("<=phone") {
        grid-template-columns: 1fr;
        grid-gap: 10px;
      }
    }

    &__field {
      display: grid;
      grid-template-columns: 1fr min-content;
      grid-template-rows: min-content min-content;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .label-root {
      font-weight: 600 !important;
      color: rgba(0, 0, 0, .6) !important;
      padding-right: 5px;
      background: #fff;
    }

    .label-error {
      color: red !important;
      font-weight: 600 !important;
    }

    .label-focused {
      color: $primarytDark !important;
    }

    .edit-form__label {
      display: flex;
      flex-wrap: wrap;
    }

    .edit-form__field_error {
      font-weight: 700 !important;
    }
  }

  .email,
  .dob {
    pointer-events: none;

    .edit-form__field_bold,
    .label-root {
      color: #888 !important;
    }
  }

  .date-of-birth-input {
    color: #888;
  }
}

@import "../../../styles/common.scss";

$primaryDark: #0177C0;

.payer-selection {
    max-width: 850px;
    margin: 0 auto;
    min-height: 15em;

    &__title {
        font-size: 32px;
        font-weight: 800;
        text-align: center;
        max-width: 630px;
        margin: 40px auto 20px auto;
        padding: 0 10px;

        @include media("<=phone") {
            font-size: 24px;
        }
    }

    &__subtitle {
        font-size: 20px;
        text-align: center;
        max-width: 440px;
        margin: 5px auto;
        padding: 0 10px;
    }

    &__click-here {
        font-size: 20px;
        text-decoration: underline;
        font-weight: bold;
        color: $primaryDark;
    }

    &__separator {
        opacity: 0.3;
        margin: 0;
    }

    &-list {
        border: 1px solid $softBorder;
        border-radius: 8px;
        padding: 15px;
        margin: 10px 0 100px;
        max-height: 600px;
        overflow-y: auto;

        @include media("<=phone") {
            border: none;
            border-top: 1px solid $softBorder;
            border-radius: 0;
            margin-top: 20px;
        }
    }

    &-item {
        display: flex;
        align-items: center;
        padding: 10px 0;

        .main-info {
            padding-left: 30px;
            padding-top: 5px;

            &__name {
                font-size: 24px;
                font-weight: 800;
            }

            &__description {
                font-size: 16px;
                padding-right: 10px;

                @include media("<=phone") {
                    padding: 0;
                }
            }

            @include media("<=phone") {
                padding: 10px;
            }
        }

        .logo-wrapper {
            padding-right: 15px;
            border-right: 1px solid $softBorder;
            min-width: 155px;
            max-width: 155px;
            min-height: 45px;

            .logo {
                max-width: 100%;
            }

            @include media("<=phone") {
                border: none;
                padding: 0;
            }
        }

        .button {
            background: $primaryDark;
            color: $background;
            font-size: 18px;
            font-weight: bold;
            padding: 4px 13px;
            height: 40px;
            width: 140px;

            &__icon {
                margin-left: 10px;
                width: 20px;
                height: 18px;
            }

            &:hover {
                background: $primaryAccent;
            }

        }

        @include media("<=phone") {
            flex-direction: column;
            text-align: center;
        }
    }

    @include media("<=phone") {
        margin: 120px auto 60px;
    }

}

@import "../../../styles/common.scss";
@import "../NewMemberRegistrationWizard.scss";

.NewMemberReportActivityStepV2_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 24px;

  > h2 {
    text-align: center;
  }
}

.NewMemberReportActivityStepV2_Wrapper_InnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 1px solid #C4C4C4;

  > h4 {
    text-align: justify;
  }
  >* {
    width: 85%;
  }
  > .ButtonsContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    button {
      color: #0091e9;
      font-size: 16px;
      padding-left: 1.2em;
      padding-right: 1.2em;
      &:disabled {
        color: darkgrey;
        background-color: white;
        border-color: darkgrey;
      }
    }
  }
}

.NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  @include media("<=phone") {
    flex-direction: column;
    align-items: center;
  }

  > .NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Input
  {
    display: flex;
    flex-grow: 1;
    width: 30px;
    flex-direction: column;
    align-items: start;
    gap: 4px;

    @include media("<=phone") {
      width: 100%;
    }

    > .NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Input_Error {
      flex-grow: 1;
      color: red;
      font-size: 12px;
      text-align: justify;
      @include media("<=600px") {
        margin: auto;
      }
    }
  }
  > .NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Help
  {
    display: flex;
    width: 30px;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-top: 42px;

    @include media("<=phone") {
      width: 100%;
      margin-top: 0;
    }
    > p {
      text-align: justify;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
    }
  }
}

.NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Input_Headers {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  > p {
    flex-grow: 1;
    color: #0280CD;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
}

.NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Input_Values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  > .MonthInputElementContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    > .MonthInput {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      > .MonthInputLabel {
        flex-grow: 3;
        display: inline-flex;
        align-items: center;
        height: 100%;
        min-width: 85px;
        max-width: 24%;
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
        text-align: left;
      }
    }
  }
}



.SubmitButtonLoading {
  width: auto;
  margin-left: 0;
  margin-right: 0;
  .MuiCircularProgress-root {
    width: 42px !important;
    height: 42px !important;
  }
}

$cardHeight: 428px;

$e3blue: #0177C0;

.Card_Container {
    height: $cardHeight;
    width: 100%;
    margin: 0 auto;
}

.ClubCard {
    width: 94%;
    height: 99%;
    margin: 0 auto;
    border-radius: 8px;

    .ClubCard_ActionArea {
        height: 100%;

        &.MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight {
            opacity: 0;
        }
    }

    .ClubCard_Img {
        height: 45%;
    }

    .no-img {
        display: block;
        width: 100%;
        background-color: rgba(25, 118, 210, 0.08);
    }

    .ClubCard_CardContent {
        // height = remainder of card. 55% (100-45% image height) - (16px of MUI padding) * 2
        height: calc(55% - 32px);
        display: flex;
        flex-direction: column;
    }

    .ClubCard_Category {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        text-transform: uppercase;
        color: $e3blue;
    }

    .ClubCard_Title {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.01em;

    }

    .ClubCard_Description {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.01em;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;

        margin-bottom: auto;
    }

    .ClubCard_CTA {
        // align to bottom
        margin-top: auto;
        align-self: flex-end;

        font-size: 13px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: $e3blue;
        border-color: rgba(25, 118, 210, 0.5);

        &:hover {
            background: rgba(25, 118, 210, 0.05);

        }
    }

}
@import "../../../styles/ignite/common.scss";

.error-page {
    display: flex;
    align-items: center;
    flex-direction: column;

    .icon {
        width: 160px;
        height: 160px;
        color: #ED5459;
        margin: 40px auto 30px;
    }

    .title {
        font-size: 24px;
        font-weight: 800;
        text-transform: capitalize;
    }

    .description {
        font-size: 20px;
        font-weight: 600;
        margin-top: 30px;

        @include media("<=md") {
            text-align: center;
        }
    }

    .button {
        background: #0288d1;
        height: 40px;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 800;
        margin-top: 30px;
        padding: 0 20px;

        &:hover {
            background: #19e;
        }
    }

    @include media("<=md") {
        padding-top: 90px;
        padding-bottom: 30px;
    }
}

@import "../../../styles/common.scss";

.AcblRegistrationWizard {
  @include media(">phone") {
    width: 75%;
    margin: 0 auto;

    > h1 {
      text-align: center;
    }
  }

  padding-bottom: 50px;

  .AcblRegistrationWizard_Wrapper {
    margin-bottom: 15px;
    &.MemberAccountStep {
      .MuiFormHelperText-root {
        font-size: 14px;
      }
    }
  }
  .MemberSelectPayerPage_HealthPlanNameField,
  .MemberSelectPayerPage_InsuranceIdField {
    margin-top: 1em;
  }
  &.AcblRegistrationWizard__EmailSent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    margin: 2em auto 1em;
  }
  .AcblRegistrationWizard__EmailSent__Title {
    text-align: center;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 12px;
    text-shadow: 0 0.25px, 0.25px 0, 0.25px 0.25px;
  }
  .AcblRegistrationWizard__EmailSent__Text {
    text-align: center;
    font-size: 16px;
    font-weight: 800;
  }
  .WizardProgress {
    margin-top: 10px;
  }
  .MuiFormHelperText-contained {
    font-size: 15px;
  }
  .MemberRegistrationWizard_OptInSweepstakes {
    margin-bottom: 10px;
    a {
      color: #0891e9;
      text-decoration: none;
    }
  }
}

$cardHeight: 540px;

$e3blue: #0177C0;

.Card_Container {
    height: $cardHeight;
    width: 100% ;
    margin: 0 auto;
}

.RelatedClubCard {
    width: 100%;
    height: 99%;
    display: inline-block;

    @media (min-width: 855px) {
        .Card_Container {
            flex: calc(50% - 32px) 1;
            max-width: calc(50% - 32px);
        }
    }

    .Card_Container {
        height: $cardHeight;
        width: auto;
        margin: 0 0 24px 0;
    }

    .ClubCard_ActionArea {
        height: 100%;

        &.MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight {
            opacity: 0;
        }
    }

    .ClubCard_Img {
        height: 230px;
        margin-bottom: 16px;
    }

    .no-img {
        display: block;
        width: 100%;
        background-color: rgba(25, 118, 210, 0.08);
    }

    .ClubCard_Gallery_Container.equal-columns {
        gap: 18px;

    }

    .ClubCard_Gallery_Image {
        height: 100px;
        border-radius: 8px;
        background: $e3blue cover center center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ClubCard_CardContent {
        // height = remainder of card. 55% (100-45% image height) - (16px of MUI padding) * 2
        height: calc(55% - 32px);
        display: flex;
        flex-direction: column;
    }

    h3 {
        margin: 0 0 16px 0;
        color: #000;
    }

    .RelatedClubCard_Description,
    .RelatedClubCard_Instructor {
        color: #000;
        font-size: 16px;
        line-height: 150%;
    }
    .RelatedClubCard_Description {
        margin-top: 16px;
    }
    .RelatedClubCard_Instructor {
        margin-top: 0;
    }

    .CCButton_ChildContainer {
        text-transform: uppercase;
    }

    .ClubCard_Category {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        text-transform: uppercase;
        color: $e3blue;
    }

    .ClubCard_Title {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.01em;

    }

    .ClubCard_Description {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.01em;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;

        margin-bottom: auto;
    }

    .ClubCard_CTA {
        margin-top: auto;
        align-self: flex-end;

        font-size: 13px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: $e3blue;
        border-color: rgba(25, 118, 210, 0.5);

        &:hover {
            background: rgba(25, 118, 210, 0.05);

        }
    }

}
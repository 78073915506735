@import "../../styles/common.scss";

.FullSizeThumbnail {
  overflow: hidden;
  background-color: $callout;
  justify-content: center;
  display: flex;

  img {
    display: flex;
    object-fit: cover;
  }
}
@import "../../styles/common.scss";

.RegistrationModal_Wrapper {
  .RegistrationModal_MemberMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
  }

  .RegistrationModal_LoginLink {
    color: $primaryAccent;
    text-decoration: none;
    font-size: 16px;
    display: block;
    font-weight: $bold;
  }

  .RegistrationForm_TermsOfUseLink {
    white-space: nowrap;
  }
  .RegistrationModal_SigninText {
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .RegistrationModal_SelfGuidedEventText {
    margin-bottom: 0.8em;
    margin-top: 0.5em;
    font-weight: normal;
    font-size: $textSize;
    width: 75%;
    @include media("<=phone") {
      width: 90%;
    }
  }
  .RegistrationModal_Close {
    display: flex;
    justify-content: flex-end;
  }
  .RegistrationModal_CloseIcon {
    color: #bababa;
    cursor: pointer;
  }
  .RegistrationModal_Bolded {
    font-weight: $bold;
  }
  .RegistrationModal_FormErrors {
    padding: 10px;
    margin-bottom: 15px;
    min-height: 1.5em;
    text-align: center;
  }
  .RegistrationModal_LeaderRegisteredText {
    padding-top: 15px;
  }
  .RegistrationModal_LeaderRegisterSignin {
    padding-left: 20px;
    padding-right: 20px;
    @include media("<=phone") {
      margin-bottom: 1.5em;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .RegistrationModal_SelfGuidedWaiver_Wrapper {
    padding-left: 2em;
    padding-right: 2em;
    height: 56em;
    overflow-y: scroll;
    padding-bottom: 1em;
    @include media("<=phone") {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

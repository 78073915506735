@import '../../styles/common.scss';
@import '../../styles/colors.scss';

.NewLayout {
  overflow: visible;

  .Footer {
    margin-top: 0;
  }

  .TopAccent,
  .TopCallout,
  .Header {
    position: relative;
    top: 0;
  }

  .TopCallout {
    overflow: hidden;
  }

  &-Navigation {
    z-index: 99;
    position: sticky;
    top: 0;
    background: white;

    @media (max-width: 1024px) {
      .Header {
        height: auto;
      }
    }

    .HeaderContentMobile {
      margin: 1rem 2rem;
    }
  }

  .TwoColumnPage {
    width: 100%;
  }

  .NewLayout_EmailVerification {
    margin-top: 0;
    margin-bottom: 32px;
    .EmailVerificationNag_Content > div {
      width: 100%;
      overflow-wrap: break-word;
    }
  }
}

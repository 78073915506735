@import "../../styles/common.scss";

.MyClubPage {
  margin-top: 1em;
  @include media("<=phone") {
    margin-top: 0em;
  }

  @include media("<=sm") {
    padding-top: .1em;
  }
}

.MyClubPage_Loading {
  margin-top: 5em;
  margin-bottom: 5em;
}

.MyClubPage_UpcomingEventsTopButtons
{
  align-content: flex-end;
  margin-top: .5em;
  margin-bottom: 5em;
  @include media("<=phone") {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin-top: .5em;
    margin-bottom: 0;
  }
}

.MyClubPage_UploadMultipleEvent {
  @include media("<=phone") {
    display: none;
  }
}

.MyClubPage_UpcomingEventsTopButtons .CCButton
{
  margin-left: .5em;
  float: right;
  @include media("<=phone") {
    float: inherit;
    margin-left: 0;
    margin-top: .25em;
    width: 100%;
  }
}

.MyClubPage_UpcomingEventsTitle, .MyClubPage_PastEventsTitle {
  font-size: $mediumHeadlineTextSize;
  margin-top: 1em;
  font-weight: $bold;
  @include media("<=phone") {
    padding: 0;
    margin-right:-25px;
    margin-left:-25px;
    width: 125%;
    padding-left: .25em;
    margin-top: 2em;
  }
}

.MyClubPage_UpcomingEventsSeparator, .MyClubPage_PastEventsSeparator, .MyClubPage_MyClubLeadersSeparator {
  @include media("<=phone") {
    padding: 0;
    margin-right:-25px;
    margin-left:-25px;
    width: 125% ! important;
  }
}

.MyClubPage_No_UpcomingEvents {
  margin-top: 1em;
  text-align: center;
}

.MyClubPage_No_UpcomingEventsMessage {
}

.MyClubPage_No_UpcomingEvents a {
  text-decoration: none;
  color: $foreground;
  font-weight: $bold;
  font-size: $smallHeadlineTextSize;
  margin-bottom: 1.5em;
}

.MyClubPage_No_PastEvents {
  text-align: center;
  margin-top: 1em;
  font-size: $smallHeadlineTextSize;
  margin-bottom: 1.25em;
}

.MyClubLeaders {
  margin-top: 1.5em;
}

.MyClubPage {
  .MyClubPage_Upcoming {

    @include media("<=phone") {
      margin-top: 1em;
    }

    .EventListNameItem {
      font-size: $largeTextSize;
      flex-basis: 35%;
      @include media(">phone") {
        padding-right: 1em;
      }
      @include media("<=phone") {
        width: 100%;
      }
    }
    .EventListDateTimeItem {
      flex-basis: 30%;
      padding-right: 1em;
      @include media("<=phone") {
        margin-top: .4em;
        margin-bottom: .4em;
        width: 100%;
      }
    }
    .EventList_RSVPs {
      flex-basis: 15%;
      @include media("<=phone") {
        margin-top: .4em;
        margin-bottom: .4em;
        width: 100%;
      }
    }
    .EventList_Buttons {
      flex-basis: 20%;
      justify-self: flex-end;
      @include media("<=phone") {
        margin-top: .4em;
        margin-bottom: .4em;
        width: 100%;
      }
    }
  }
}


.MyClubPage {
  .MyClubPage_Past {
    @include media("<=phone") {
      margin-top: 1em;
    }
    .EventListNameItem {
      font-size: $largeTextSize;
      width: 35%;
      padding-right: 1em;
      @include media("<=phone") {
        margin-top: .4em;
        margin-bottom: .4em;
        width: 100%;
      }
    }
    .EventListDateTimeItem {
      width: 35%;
      @include media("<=phone") {
        margin-top: .4em;
        margin-bottom: .4em;
        width: 100%;
      }
    }
    .EventList_Buttons {
      width: 30%;
      .CCButton {
        @include media(">phone") {
          width: 100%;
        }
      }
      @include media("<=phone") {
        margin-top: .4em;
        margin-bottom: .4em;
        width: 100%;
      }
    }
  }
}

.MyClubLeaders_Leaders {
  .FullName {
    font-size: $largeTextSize;
  }
  .ContactInfo {
  }
  .Controls {
  }
}


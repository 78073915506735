@import "../../styles/common.scss";

.JoinClubModal_Term {
  color: #0177C0;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.JoinClubModal_MemberMessage {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 19px;
  vertical-align: middle;
  flex-wrap: wrap;
  text-wrap: balance;
}

.JoinClubModal_MemberEmail {
  padding-right: 20px;
  font-weight: 700;
}

.JoinClubModal_MemberProfileLink {
  color: #0177C0 !important;
  font-weight: 600;
  font-size: 19px;
  text-transform: unset;
  
}

.JoinClubModal_Wrapper {
  .JoinClubModal_Close {
    display: flex;
    justify-content: flex-end;
  }
}

@import "../../../styles/common.scss";

.club-finder.ignite-page-content {
    max-width: 730px;
    margin: 0 auto 50px;

    @include media("<=phone") {
        margin-top: $headerHeight_Mobile + $topCalloutHeight + $topAccentHeight;
    }
     
    h1 {
        display: block;
        text-align: center;
        @include media("<=phone") {
            font-size: 24px;
            padding: 0;
            font-weight: 800;
        }
    }

    .bottom-content {
        padding: 0 18px 60px;
        border-right: 1px solid #d6d6d6;
        border-left: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
    }
}

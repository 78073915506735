@import "../../styles/common.scss";

.CCDetailsBox {
  border: 1px solid $secondaryAccent;
  width: 275px;
  height: 180px;
  position: relative;
}

.CCDetailsBox_Content {
  margin: 1em;
  margin-top: 1.6em;
}

.CCDetailsBox_Title {
  text-transform: uppercase;
  color: $secondaryAccent;
  font-size: $formEntryTextSize;
}

.CCDetailsBox_Main {
  margin-top: .2em;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 6em;
}

.VirtualEventDetailsBox .CCDetailsBox_Main {
  max-height: 6em;
}

.CCDetailsBox_Footer {
  position: absolute;
  bottom: 1em;
}
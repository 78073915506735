@import "../../../styles/common.scss";

.onboarding-page {
    max-width: 630px;
    margin: 0 auto;
    min-height: 15em;
    padding: 20px;

    @include media("<=phone") {
        margin: 105px auto 20px;
    }

    &__title {
        font-size: 32px;
        text-align: center;
        font-weight: 800;
        margin: 0 0 30px 0;

        @include media("<=phone") {
            font-size: 24px;
        }
    }
}

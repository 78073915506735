@import "../../styles/common.scss";

.CCSaveIndicator
{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CCSaveIndicator svg
{
  color: green;
  font-size: 2.5em;
}


.CCSaveIndicator_Success svg
{
  color: $success;
}

.CCSaveIndicator_Failure svg
{
  color: $errored;
}

.CCSaveIndicator_Message
{
  margin-left: .2em;
}
@import "../../styles/common.scss";

.EventListNameItem {
  flex-direction: column;
  @include media("<=phone") {
    font-weight: bold;
  }
}

.EventListNameItem_IsPaid {
  font-size: $formEntryTextSize;
  @include media("<=phone") {
    font-weight: normal;
  }
}

.EventListNameItem_IsSelfGuided {
  font-size: $formEntryTextSize;
  @include media("<=phone") {
    font-weight: normal;
  }
}

.EventListNameItem_ClubName {
  font-size: $textSize;
}

.EventListNameItem_IsCancelled {
  font-weight: $bold;
  color: $darkErrored;
  @include media("<=phone") {
    font-weight: normal;
  }
}


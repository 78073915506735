@import "../../styles/common.scss";

.MemberCreateAccountPage {
  @include media(">phone") {
    > h1 {
      text-align: center;
    }
  }

  .MemberCreateAccountPage_Error {
    color: red;
  }

  .MemberCreateAccountPage_PayerDetails {
    display: flex;
    flex-direction: row;
    @include media("<=phone") {
      flex-direction: column;
    }
  }

  .MemberCreateAccountPage_PayerDetails_Item {
    margin: 15px;
  }

  .RegistrationFormField_TermsOfUseWrapper {
    text-align: center;
  }

}

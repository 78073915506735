@import "../../../styles/ignite/common.scss";

.welcome-dashboard {
    position: relative;
    background: #0177C0;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    color: #fff;
    margin-right: 16px;

    &__title {
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 10px;
    }

    &__description {
        font-size: 20px;
        font-weight: 700;
    }

    &__icon {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    @include media("<=md") {
        margin-right: 0;
    }
}

@import "../../../styles/ignite/common.scss";

.leaderboard-general-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    text-transform: uppercase;
    border-right: 1px solid $softBorder;
    border-left: 1px solid $softBorder;
    border-bottom: 1px solid $softBorder;
    border-top: 1px solid $primaryDark;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .top-content {
        display: flex;
        text-align: center;
        justify-content: space-around;
        border: none;
        padding-bottom: 10px;

        .amount {
            font-size: 16px;
            font-weight: 800;
            min-height: 20px;
        }

        .description {
            font-size: 16px;
            font-weight: 600;
        }
    }

    .bottom-content {
        display: flex;
        text-align: center;
        justify-content: space-between;
        padding-top: 10px;

        .amount {
            font-size: 16px;
            font-weight: 800;
        }

        .description {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .symbol {
        color: #555;
        font-weight: bold;
    }
}

@import "~include-media/dist/include-media.scss";
@import "../../styles/common.scss";
@import "../../styles/colors.scss";


$copyrightFontSize: (11/$baseFontSize) * 1em; //(11/$baseFontSize) * 1em;
$copyrightMarginTop: 10px; //(10/$baseFontSize) * 1em;

.Copyright
{
  font-size: $copyrightFontSize;
  margin-top: $copyrightMarginTop;
}


@import "../../styles/common.scss";


.MyEvents {
  // The following rules (.SortButton, .SortIcon, .ResultsList_SortBy, and
  // .ResultsList_Divider) can be removed once the My Events API supports
  // sorting.
  .SortButton {
    pointer-events: none;
    touch-action: none;
  }
  .SortIcon {
    display: none;
  }
  .ResultsList_SortBy,
  .ResultsList_Divider {
    display: none;
  }

  .MyEvents_CurrentlyNoEvents {
    background: #F5F5F5;
    padding: 40px 128.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.No_Passions {
      padding: 49px 128.5px;
    }
    @include media("<=phone") {
      &, &.No_Passions {
        padding: 20px 40px;
      }
    }
  }
  .MyEvents_CurrentlyNoEvents_Text {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
  .MyEvents_CurrentlyNoEvents_Button {
    font-family: 'Open Sans', sans-serif;
    margin-top: 32px;
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #0177C0;

  }
  .MyEvents_CurrentlyNoEvents_Button_Icon {
    color: #FFFFFF;
    margin-right: -15px;
  }
  .MyEvents_CurrentlyNoEvents_TellUsYourPassions {
    margin-top: 32px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 32px 60px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .MyEvents_CurrentlyNoEvents_TellUsYourPassions_Text {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #0177C0;
    margin-bottom: 21px;
  }
  .MyEvents_CurrentlyNoEvents_TellUsYourPassions_Button {
    font-family: 'Open Sans', sans-serif;
    padding: 7px 22px;
    background: #0177C0;
    border-radius: 4px;
  }
  .MyEvents_CurrentlyNoEvents_TellUsYourPassions_Button_Icon {
    color: #FFFFFF;
    margin-right: -10px;
  }
  .MyEvents_CurrentlyNoEvents_TellUsYourPassions_Image {
    margin-top: 32px;
    width: calc(100% + 120px);
    // Aspect ratio of desktop image
    padding-bottom: 15.5%;
    margin-left: -60px;
    margin-right: -60px;
    margin-bottom: -32px;
    background-size: cover;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    @include media("<=phone") {
      // Aspect ratio of mobile image
      padding-bottom: 19.18918918918919%;
    }
  }
}

.MyEvents_PreviousAndNextButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .PreviousButton, .NextButton {
    margin: 1em;
  }
}

@import "src/styles/common";

.PayerMemberRegistrationWizard .MemberPersonalInformationStep {
    h2 {
        color: #0097ea;
    }

    .MemberPersonalInformationStep_StepSubcontent {
        @include media(">=phone") {
            text-align: justify;
        }
    }
}
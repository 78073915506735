@import "../../styles/common.scss";

.SelfGuidedEventText {
  display: flex;
  flex-direction: column;

  .SelfGuidedEventText_Body a {
    color: $primaryAccent;
    font-weight: bold;
  }

  .SelfGuidedEventText_Title {
    display: flex;
    flex-direction: row;
    margin-bottom: .5em;

    svg {
      display: flex;
      fill: $secondaryAccent;
      margin-right: .5em;
      width: 40px;
      height: 40px;
      @include media("<=phone") {
        width: 60px;
        height: 60px;
      }
    }

    .SelfGuidedEventText_TitleText {
      display: flex;
      font-weight: $bold;
      align-self: center;
    }
  }
}

// used for breakpoint calculations in scss
@import "~include-media/dist/include-media.scss";
@import "fonts.scss";
@import "colors.scss";



// can i override bulma?

@import url('https://fonts.googleapis.com/css?family=Young+Serif:400,700');
$family-sans-serif: "Young Serif", Verdana;




// the total page width
$desktopPageWidth: 1024; // px
$medium: 855px;
$small: 500px;


$breakpoints: (
  phone: $desktopPageWidth * 1px,
  md: $medium,
  sm: $small
);

$tabletWidth: 768px;
$mobileMaxWidth: 767px;

// the width of the content area
$desktopPageContentWidth: 850; // px
// the size of the "edges" on mobile
$pageMarginHorizontal_Mobile: 25;
$pageMarginHorizontal_Mobile: 25;

$baseFontSize: 16; // useful for px/em calculations, if you're doing those

$headerHeight: 95px;
$headerBorderSize: 1px;
$headerHeight_Mobile: 70px;
$headerContentWidth: $desktopPageContentWidth * 1px;
$headerContentMarginTop: 30px;
$headerContentMobileMarginLeft: $pageMarginHorizontal_Mobile * 1px;
$headerContentMobileMarginRight: $pageMarginHorizontal_Mobile * 1px;
$headerContentMobileMarginTop: 15px;
$logoWidth: 150px;
$logoWidth_Mobile: 120px;
$topAccentHeight: 6px;
$navHeight: 95px;
$topCalloutHeight: 30px;



html {
  margin: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  font-size: $largeHeadlineTextSize;
}


h2 {
  font-size: $mediumHeadlineTextSize;
}


.PageLoading {
  padding-top: 10em;
  padding-bottom: 10em;
}

.PageLoadingMessage {
  padding-top: 10em;
  padding-bottom: 10em;
}

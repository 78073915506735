@import "../../styles/common.scss";

.MyAccount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 175px;

  .MyAccount_Name {
    text-align: right;
  }

  .MyAccount svg {
    display: flex;
    color: $primaryAccent;
    font-size: 3em ! important;
  }

  .MyAccount_Links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 1em;
    margin-top: .2em;

    .Separator {
      height: 1.3em;
      margin-left: .5em;
      margin-right: .5em;
    }
  }

  .MyAccount_Logout, .MyAccount_Profile {
    display: flex;
    font-size: 14px;
    margin-top: .1em;
    cursor: pointer;
    color: $primaryAccent;
    font-weight: $bold;
    text-align: right;
  }

}


@import "../../styles/colors.scss";

table {
  width: 70%;
}

.IndexColumn {
  width: 25px;
}

.LabelColumn {
  width: 175px;
}

.SpacerCell {
  height: 5px;
}

.IndexColumnValid {
  color: #009933;
  font-weight: bold;
}

.IndexColumnInvalid {
  color: #990000;
  font-weight: bold;
}
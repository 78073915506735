@import "../../styles/common.scss";

.SelectPassions {
  .SelectPassions_WelcomeBack {
    font-size: $largeHeadlineTextSize;
    font-weight: $bold;
    margin-top: .5em;
    margin-bottom: .5em;
  }
  .SelectPassions_Panel {
    display: flex;
    justify-content: space-between;
    .SelectPassions_Panel_Text {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3A3A3A;
    }
    a {
      display: flex;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.014em;
      text-transform: uppercase;
      color: #0177C0;
      text-decoration: none;
    }
    @include media("<=phone") {
      flex-direction: column;
      justify-content: flex-start;
      .SelectPassions_Panel_Text {
        margin-bottom: 16px;
      }
    }
  }
  .SelectPassions_Passions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
    @include media("<=phone") {
      margin-top: 40px;
      grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
    }

    .SelectPassions_Passion {
      cursor: pointer;
      width: 125px;
      height: 125px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background-size: contain;
      @include media("<=phone") {
        background-image: none !important;
        width: 165px;
        min-height: 34px;
        height: auto;
        border-radius: 8px;
      }

      .SelectPassions_Passion_Top {
        width: 100%;
        height: 81px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @include media("<=phone") {
          display: none;
        }

        .Checkmark {
          width: 46px;
          mix-blend-mode: normal;
          opacity: 0.6;
          filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35));
        }

        .SelectPassions_Passion_Overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.15);
        }
      }

      .SelectPassions_Passion_Bottom {
        .SelectPassions_Passion_PassionName {
          width: 100%;
          height: 44px;
          background: #0177C0;
          border-radius: 0 0 16px 16px;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          @include media("<=phone") {
            border-radius: 8px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.26);
            color: #0177C0;
            width: 100%;
            height: 100%;
            padding: 6px 8px;
            box-sizing: border-box;
          }
        }
      }

      &.selected {
        .SelectPassions_Passion_Top {
          .SelectPassions_Passion_Overlay {
            background: #0177C0;
            opacity: 0.8;
            border-radius: 16px 16px 0 0;
          }

          .Checkmark {
            opacity: 1;
            filter: none;
            z-index: 1;
          }
        }

        .SelectPassions_Passion_Bottom {
          .SelectPassions_Passion_PassionName {
            @include media("<=phone") {
              background: #0177C0;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  .SelectPassions_CheckboxContainer {
    margin-bottom: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .SelectPassions_Submit {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 7px 42.5px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
  }
}

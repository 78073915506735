@import "../../styles/common.scss";

.MyClubLeaders {
  display: flex;
  flex-direction: column;
}

.MyClubLeaders div{
  display: flex;
}

.MyClubLeaders_TitleRow {
  flex-direction: row;
}

.MyClubLeaders_Title{
  font-size: $mediumHeadlineTextSize;
  font-weight: $bold;
}

.MyClubLeaders_AddButton{
  margin-left: auto;
  flex-basis: min-content;
  height: 0%;
  align-items: center;
  margin-top: 1em;
  @include media("<=phone") {
    justify-content: center;
    width: 100%;
  }
}

.MyClubLeaders_AddForm {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
}

.MyClubLeaders_Leaders {
  display: flex;
  flex-direction: column;
}

.MyClubLeaders_Invitations {
  display: flex;
  flex-direction: column;
}

.AddClubLeaderForm
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @include media("<=phone") {
    flex-direction: column;
  }
}

.AddClubLeaderForm_FormControl_Buttons {
  flex-direction: row ! important;
  @include media("<=phone") {
    margin-top: .5em !important;
    margin-bottom: .5em !important;
  }
}

.AddClubLeaderForm_Instructions
{
  margin-left: 1em;
  width: 35%;
  @include media("<=phone") {
    margin-left: 0em;
    width: 100%;
    margin-top: .5em;
    margin-bottom: .5em;
  }
}

.AddClubLeader_SubmitButton {
  display: flex;
  margin-left: 1em;
  height: auto;
  align-self: flex-start;
  justify-content: center;
}

.ClubLeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $darkCallout;
  padding-bottom: 1em;
  @include media("<=phone") {
    flex-direction: column;
    border: 1px solid $darkCallout;
    padding: 1em;
  }
}

.ClubLeader div {
  display: flex;
}

.ClubLeader .FullName {
  width: 33%;
  @include media("<=phone") {
    width: 100%;
    font-weight: $bold;
  }
}

.ClubLeader .ContactInfo {
  flex-direction: column;
  width: 33%;
  @include media("<=phone") {
    margin-top: .5em;
    width: 100%;
  }
}

.ClubLeader .ContactInfo a {
  cursor: pointer;
  text-decoration: none;
  color: $foreground;
}

.ClubLeader.NotRemovable .FullName {
  width: 50%;
  @include media("<=phone") {
    width: 100%;
  }
}

.ClubLeader.NotRemovable .ContactInfo {
  width: 50%;
  @include media("<=phone") {
    width: 100%;
  }
}

.ClubLeader .Controls {
  width: 33%;
  justify-content: flex-end;
  @include media("<=phone") {
    margin-top: 1em;
    width: 100%;
    justify-content: center;
  }
}

.DeputyInvitation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $darkCallout;
  padding-bottom: 1em;
  @include media("<=phone") {
    flex-direction: column;
    border: 1px solid $darkCallout;
    padding: 1em;
  }
}

.DeputyInvitation .EmailAddress {
  width: 33%;
  @include media("<=phone") {
    margin-top: .5em;
    width: 100%;
    font-weight: $bold;
  }
}

.DeputyInvitation .Message {
  font-style: italic;
  width: 33%;
  @include media("<=phone") {
    margin-top: .5em;
    width: 100%;
  }
}

.DeputyInvitation .Controls {
  width: 33%;
  justify-content: flex-end;
  @include media("<=phone") {
    margin-top: 1em;
    width: 100%;
    justify-content: center;
  }
}

@import "../../styles/common.scss";


.NotFoundPage {
  .Title {
    margin-top: 1em;
    font-weight: bold;
    font-size: $largeHeadlineTextSize;
  }
  .Message {
    margin-top: 2em;
    font-size: $largeTextSize;
    margin-bottom: 3em;
  }
  a {
    text-decoration: none;
    color: $primaryAccent;
    font-weight: $bold;
  }
}

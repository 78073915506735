@import "../../styles/common.scss";

.RsvpAttendanceList {
  position: relative;
}

.RsvpAttendanceList_Instructions {
  margin-bottom: 1.5em;
}

.RsvpAttendanceList_LoadingMessage {
  color: $errored;
  font-size: $mediumHeadlineTextSize;
}

.RsvpAttendanceList_Title {
  font-size: $mediumHeadlineTextSize;
}

.RsvpAttendanceList_AttendanceUpdate {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  align-items: center;
  @include media("<=phone") {
    align-items: flex-start;
    flex-direction: column;
  }
  margin-top: 1.5em;
}

.RsvpAttendanceList_AttendanceUpdateBottom{
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  align-items: center;
  @include media("<=phone") {
    align-items: flex-start;
    flex-direction: column;
  }
}

.RsvpAttendanceList_AttendanceUpdateTitle {
  display: flex;
  align-items: center;
  font-size: $mediumHeadlineTextSize;
  margin-right: 1em;
}

.RsvpAttendanceList_AttendanceUpdateButton {
  display: flex;
}

.RsvpAttendanceList_SavingLoading {
  position: absolute;
  height:100px;
  width:100px;
  left:50%;
  margin-left:-50px;
  margin-top: 10em;
}

.RsvpAttendanceList_SavingSuccess {
  display: flex;
  margin-left: 1em;
  max-width: 20em;
  @include media("<=phone") {
    margin-top: 1em;
    margin-left: 0;
  }
}

.RsvpAttendanceList_NowSaving {
  opacity: .1;
}

.RsvpAttendanceList_PastUsersTitle {
  margin-top: 2em;
  font-size: $mediumHeadlineTextSize;
}

.RsvpAttendanceList_PastUsersInstructions {
  margin-bottom: 1em;
}

.RsvpAttendanceRow {
}

.RsvpAttendanceRow_Switch {
  width: 15%;
}

.RsvpAttendanceRow_Name {
  font-size: $largeTextSize;
  width: 30%;
  @include media("<=phone") {
    font-weight: $bold;
  }
}

.RsvpAttendanceRow_Contact {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.RsvpAttendanceRow_Contact a {
  color: $foreground;
  text-decoration: none;
  cursor: pointer;
}

.RsvpAttendanceRow_AttendedOrNot {
  text-align: right;
  width: 20%;
  font-size: $largeTextSize;
  @include media("<=phone") {
    text-align: left;
  }
}

.ManualAttendance {
  display: flex;
  flex-direction: column;
}

.ManualAttendance_Instructions {
  display: flex;
  flex-direction: column;
}

.ManualAttendance_InstructionsTitle {
  margin-top: 1em;
  display: flex;
  font-weight: $bold;
  font-size: $mediumHeadlineTextSize;
}

.ManualAttendance_InstructionsText {
  flex-wrap: nowrap;
  display: flex;
  @include media("<=phone") {
    margin-top: .5em;
  }
}

.ManualAttendance_Form {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include media("<=phone") {
    flex-direction: column;
    margin-top: .5em;
    align-items: flex-start;
  }
}

.ManualAttendance_Form_AutocompleteFormControl {
  width: 25em;
  @include media("<=phone") {
    width: 100%;
  }
}

.ManualAttendance_Form_Autocomplete {
  display: flex;
  @include media("<=phone") {
    width: 100%;
  }
}

.ManualAttendance_Form_Button {
  margin-left: 1em;
  display: flex;
  @include media("<=phone") {
    margin-top: .5em;
    margin-left: 0;
  }
}

.ManualAttendance_Form_SavingSuccess {
  margin-left: .5em;
  @include media("<=phone") {
    margin-top: .5em;
    margin-left: 0;
  }
}

.ManualAttendance_ErrorMessage {
  height: auto;
  margin-top: 1em;
}

.react-multi-email {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  background: #fff;
  border: 1px solid #d6d6d6;
  color: #666;
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  font-size: 16px;
}

.react-multi-email>span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}

.react-multi-email.focused {
  border-color: #85b7d9;
  background: #fff;
}

.react-multi-email.empty>span[data-placeholder] {
  display: inline;
  color: #ccc;
}

.react-multi-email.focused>span[data-placeholder] {
  display: none;
}

.react-multi-email>input {
  flex: 1;
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 5px;
  background-color: #EFEFEF;
  background-image: none;
  padding: 4px 10px;
  color: #000;
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 4px;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}

.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}

.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}

.react-multi-email-item {
  min-height: 25px;
  background: #eee !important;

  div {
    min-height: 25px;
    display: flex;
    align-items: center;
  }
}

.react-multi-email-placeholder{
  color: #666;
}
@import "../../../styles/ignite/common.scss";

.subheader {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title {
        display: flex;
        align-items: center;

        @include media("<=sm") {
            flex-direction: column;
            align-items: start;
        }
    }

    .club-info {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: end;
        position: relative;
        margin-left: 10px;

        .left-content {
            text-align: right;
            margin-right: 10px;
        }

        .right-content {
            display: flex;
            align-items: center;

            .img {
                min-width: 60px;
            }
        }

        .icon {
            font-size: 40px;
            cursor: pointer;
        }

        .name {
            font-size: 18px;
            font-weight: 800;
        }

        .description-text {
            font-size: 16px;
            font-weight: 600;
        }

        .img {
            max-width: 60px;
            border-radius: 50%;
        }

        .dropdown {
            position: absolute;
            top: 75px;
            background: #fff;
            opacity: 0;
            transition: opacity .3s;
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
            font-size: 20px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            pointer-events: none;
            z-index: 1;
            min-width: 300px;
            max-height: 500px;
            overflow-y: auto;

            &.is-open {
                opacity: 1;
                cursor: pointer;
                pointer-events: all;
            }

            .club-item {
                line-height: 24px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                padding: 12px;
                &:hover, &.selected {
                    background-color: #0091E91A;
                }
                .club-item__name {
                    font-size: 14px;
                    margin-right: 10px;
                    flex-direction: row;

                }
                .club-item__role {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #0F8FAB;
                    &.Member {
                        color: #9747FF;
                    }
                }
            }
        }

        @include media("<=sm") {
            align-self: end;
            margin-left: 0;
            width: 100%;
            margin-bottom: 30px;

            &::after {
                content: '';
                position: absolute;
                display: block;
                height: 1px;
                background: $softBorder;
                width: 100vw;
                bottom: -20px;
                left: -10px;
            }
        }
    }

    @include media("<=md") {
        margin: 20px 0;
        font-size: 24px;
    }

    @include media("<=sm") {
        flex-direction: column-reverse;
        align-items: start;

        .label {
            margin-left: 0;
            margin-top: 10px;
        }
    }
}

@import "../../styles/common.scss";
.InstructorBioModal {
    .MuiDialogTitle-root {
        padding: 0;
    }

    .MuiDialogContent-root {
        padding: 0 32px 24px;
    }

    .InstructorBioModal_Content.equal-columns {
        padding: 24px 0 8px 0;

        &>div {
            width: calc(50% - 24px);
        }
    }

    .InstructorBioModal_Content.equal-columns>div:first-child {
        padding-left: 0;
        padding-right: 24px;
        border-right: 1px solid rgba(204, 204, 204, 1);
    }

    .InstructorBioModal_Content.equal-columns>div {
        padding-top: 24px;
        padding-left: 24px;
    }

    .relatedClubs {
        display: flex;
        flex-direction: row;
        gap: 32px;
    }
    @media (max-width: $medium) {

        .InstructorBioModal_Content.equal-columns {
            padding: 24px 0 8px 0;
            flex-direction: column;
            
            &>div {
                width: 100%;
                padding-left: 0;
                border: none;
            }
            &>div:first-child {
                padding-top: 0;
                padding-bottom: 24px;
                border-bottom: 1px solid rgba(204, 204, 204, 1);
            }
            
        }
        .relatedClubs {
            flex-direction: column;
            gap: 0;
        }
    
    }
    
    
}



.HorizontalMediaCard {
    display: flex;
    flex-direction: row;
    width: 100%;

}

.HorizontalMediaCard__info {
    margin-left: 24px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@import "../../styles/common.scss";

.BorderedRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $darkCallout;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: .2em;
  padding-right: .2em;
  @include media("<=phone") {
    flex-direction: column;
    border: 1px solid $darkCallout;
    padding: 1em;
    margin-bottom: 1em;
    margin-top: 0;
  }
}

.BorderedRow_TopBorder {
  border-top: 1px solid $darkCallout;
  padding-top: 1em;
}

.BorderedRow:first-child {
  @include media("<=phone") {
    margin-top: 1em;
  }
}

.BorderedRow .BorderedRowItem {
  display: flex;
  @include media("<=phone") {
    margin-top: .5em;
    width: 100%;
  }
}

.BorderedRow .BorderedRowLastItem {
  justify-content: flex-end;
  @include media("<=phone") {
    margin-top: 1em;
    width: 100%;
    justify-content: center;
  }
}

.BorderedRow_Link {
  cursor: pointer;
}

.BorderedRow_Link:hover {
  background-color: $primaryAccentLight;

}
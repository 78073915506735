/// Defines common colors.
///
///

// the main foreground color
$foreground: rgba(0, 0, 0, 1);
// the main background color
$background: rgba(255, 255, 255, 1); // The foreground color of most buttons, lightblue
$primaryAccent: rgba(0, 145, 233, 1);
// Elements (eg text) placed over $primaryAccent
$primaryAccentLight: rgba(0, 145, 233, .1);
$primaryAccentForeground: rgba(255, 255, 255, 1);
// dark version of primary accent, often used for rollovers
$primaryAccentDark: rgba(2, 128, 205, 1);
$primaryDark: #0177C0;
// The secondary accent color, dark red
$secondaryAccent: rgba(237, 84, 89, 1);
// Elements (eg text) placed over $secondaryAccent
$secondaryAccentForeground: rgba(255, 255, 255, 1);
$secondaryAccentDark: rgba(253, 75, 81, 1);
// used for text callouts, grey
$callout: rgba(235, 235, 235, 1);
// used for text callouts, darker grey
$darkCallout: rgba(137, 137, 137, 1);
$darkCalloutForeground: rgba(255, 255, 255, 1);
// disabled elements, darker grey
$disabled: rgba(185, 185, 185, 1);
$disabledForeground: rgba(200, 200, 200, 1);
// used for soft borders, grey
$softBorder: rgba(214, 214, 214, 1);
$calloutForeground: rgba(0, 0, 0, 1);
// errored text (eg, form validation) uses this color
$errored: rgba(255, 0, 0, 1);
$darkErrored: rgba(253, 75, 81, 1);
$darkErroredForeground: rgba(255, 255, 255, 1);
$success: rgba(0, 150, 0, 1);
$inactiveClub: rgba(255, 0, 0, 1);
$activeClub: rgba(0, 100, 0, 1);
$paidIndicatorForeground: rgba(253, 75, 81, 1);

@import "../../../styles/common.scss";

.OptumMemberRegistrationWizard {
  @include media(">phone") {
    width: 75%;
    margin: 0 auto;

    > h1 {
      text-align: center;
    }
  }

  .OptumMemberRegistrationWizard_Wrapper {
    margin-bottom: 15px;
  }
}

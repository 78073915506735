.MemberSelectPayer {
  .MemberSelectPayer_FormErrors {
    margin-top: 1em;
  }

  .MemberSelectPayer_FormError {
    color: red;
  }

  .MemberSelectPayer_Fields {
    display: flex;
    flex-direction: column;
  }

  .MemberSelectPayer_InsuranceIdField {
    margin-top: 1em;
  }
}
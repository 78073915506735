@import '../../styles/common.scss';

.AcblLandingPage {
  $semiTablet: 855px;
  $tablet: 650px;
  $phone: 500px;

  padding-bottom: 50px;

  .AcblLandingPage_Banner {
    width: 100vw;
    margin-left: calc(-1 * (100vw - 850px) / 2);
    @include media('<phone') {
      margin-left: -25px;
    }
  }
  .AcblLandingPage_Banner_Top1 {
    background: #215296;
    height: 25px;
    width: 100%;
  }
  .AcblLandingPage_Banner_Top2 {
    display: flex;
    align-items: center;
    background: #001c58;
    padding-left: calc((100vw - 850px) / 2);
    padding-top: 4px;
    padding-bottom: 4px;
    @include media('<phone') {
      padding-left: 25px;
    }
  }
  .AcblLandingPage_Banner_Top_Logo {
    width: 50px;
  }
  .AcblLandingPage_Banner_Bottom {
    background-size: cover;
    padding-left: calc((100vw - 850px) / 2);
    @include media('<phone') {
      padding-left: 25px;
    }
    @media (max-width: $phone) {
      display: flex;
      justify-content: center;
      background-image: url('../../assets/acbl-banner-mobile.jpg');
    }
  }
  .AcblLandingPage_Banner_Bottom_Body {
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: $phone) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .AcblLandingPage_Hook {
    font-size: 30px;
    font-weight: 900;
    text-shadow: 0 0.5px, 0.5px 0, 0.5px 0.5px;
    margin-bottom: 20px;
    max-width: 350px;
    @media (max-width: $phone) {
      text-align: center;
    }
  }
  .AcblLandingPage_Banner_Text {
    max-width: 600px;
    font-size: 18px;
    @media (max-width: $semiTablet) {
      max-width: 400px;
    }
    @media (max-width: $phone) {
      margin-bottom: 150px;
    }
  }
  .AcblLandingPage_Banner_Text_Line3 {
    margin-top: 5px;
    font-weight: 800;
    font-style: italic;
  }
  .AcblLandingPage_Banner_Bottom_Button {
    background: #1e2452;
    font-size: 14px;
    letter-spacing: 0.75px;
    padding: 12px 24px;
  }
  .AcblLandingPage_Banner_Bottom_Button_Icon {
    margin-left: 6px;
    width: 5px;
    @media (max-width: $tablet) {
      width: 7px;
    }
  }
  .AcblLandingPage_Body1 {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $phone) {
      flex-direction: column;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  .AcblLandingPage_Body1_Info {
    color: #070267;
    font-weight: 800;
    font-size: 20px;
    max-width: 300px;
    margin-right: 20px;
    @media (max-width: $semiTablet) {
      font-size: 24px;
      max-width: 450px;
    }
    @media (max-width: $phone) {
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .AcblLandingPage_Body1_Info_Text {
    margin-bottom: 5px;
  }
  .AcblLandingPage_Body1_Logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }
  .AcblLandingPage_Body1_Logo {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .AcblLandingPage_Body1_Logos_Icon {
    margin: 0 25px;
    width: 20px;
    @media (max-width: $tablet) {
      margin: 10px 0;
      width: 15px;
    }
  }
  .AcblLandingPage_Body1_GrouperLogo {
    width: 150px;
    height: 40px;
    @media (max-width: $tablet) {
      height: 30px;
    }
  }
  .AcblLandingPage_Body1_ACBLLogo {
    width: 122px;
    height: 60px;
    @media (max-width: $tablet) {
      height: 30px;
    }
  }
  .AcblLandingPage_BodyDivider {
    width: 100%;
    height: 2px;
    background: #dddddd;
    @media (max-width: $phone) {
      width: 100vw;
      margin-left: -25px;
    }
  }
  .AcblLandingPage_Body2 {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    @media (max-width: $semiTablet) {
      flex-direction: column;
    }
    @media (max-width: $phone) {
      margin-top: 30px;
      align-items: center;
    }
  }
  .AcblLandingPage_Body2_TitleAndLink {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
    @media (max-width: $semiTablet) {
      margin-bottom: 30px;
    }
    @media (max-width: $phone) {
      margin-bottom: 20px;
      .AcblLandingPage_Body2_Link {
        display: none;
      }
    }
  }
  .AcblLandingPage_Body2_Title {
    color: #1e2452;
    font-weight: 800;
    text-shadow: 0 0.25px, 0.25px 0, 0.25px 0.25px;
    font-size: 22px;
    width: 165px;
    @media (max-width: $tablet) {
      width: auto;
    }
    @media (max-width: $phone) {
      text-align: center;
      width: 165px;
    }
  }
  .AcblLandingPage_Body2_Link {
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: #4870a8;
    cursor: pointer;
    font-size: 14px;
    font-weight: 800;
    .AcblLandingPage_Body2_Link_Text {
      display: inline-block;
      width: 130px;
      @media (max-width: $phone) {
        text-align: center;
      }
    }
    &.AcblLandingPage_Body2_LinkBottom {
      display: none;
      @media (max-width: $phone) {
        display: block;
        margin-top: 20px;
      }
    }
  }
  .AcblLandingPage_Body2_Link_Icon {
    margin-left: 6px;
    width: 10px;
  }
  .AcblLandingPage_Body2_InfoSection {
    display: flex;
    justify-content: space-between;
    flex: 3;
    @media (max-width: $phone) {
      flex-direction: column-reverse;
      justify-content: initial;
      align-items: center;
    }
  }
  .AcblLandingPage_Body2_Info {
    width: 200px;
    margin-right: 20px;
    @media (max-width: $phone) {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:not(:last-child) {
        margin-top: 20px;
      }
    }
  }
  .AcblLandingPage_Body2_Info_Icon {
    width: 25px;
  }
  .AcblLandingPage_Body2_Info_Text {
    font-weight: 800;
    @media (max-width: $phone) {
      margin-top: 10px;
      text-align: center;
    }
  }
}

@import "../../styles/common.scss";

.RsvpList {
  position: relative;
}

.RsvpList_Title  {
  font-size: $mediumHeadlineTextSize;
  font-weight: bold;
}

.RsvpList_Instructions {
  margin-bottom: 1.5em;
}

.RsvpList_LoadingMessage {
  color: $errored;
  font-size: $mediumHeadlineTextSize;
}

.RsvpList_Title {
  font-size: $mediumHeadlineTextSize;
}

.RsvpList_AttendanceUpdate {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  margin-top: 1em;
  align-items: center;
  @include media("<=phone") {
    align-items: flex-start;
    flex-direction: column;
  }
}

.RsvpList_AttendanceUpdateBottom{
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  align-items: center;
  @include media("<=phone") {
    align-items: flex-start;
    flex-direction: column;
  }
}

.RsvpList_AttendanceUpdateTitle {
  align-items: center;
  font-size: $mediumHeadlineTextSize;
  margin-right: 1em;
  display: none;
}

.RsvpList_AttendanceUpdateButton {
  display: flex;
  margin-right: 1em;
}

.RsvpList_SavingLoading {
  position: absolute;
  height:100px;
  width:100px;
  left:50%;
  margin-left:-50px;
  margin-top: 10em;
}

.RsvpList_SavingSuccess {
  display: flex;
  margin-left: 1em;
  max-width: 20em;
  @include media("<=phone") {
    margin-top: 1em;
    margin-left: 0;
  }
}

.RsvpList_NowSaving {
  opacity: .1;
}

.RsvpList_PastUsersTitle {
  margin-top: 2em;
  font-size: $mediumHeadlineTextSize;
}

.RsvpList_PastUsersInstructions {
  margin-bottom: 1em;
}

.RsvpRow {
}

.RsvpRow_Switch {
  width: 15%;
}

.RsvpRow_Name {
  width: 35%;
  font-size: $largeTextSize;
  @include media("<=phone") {
    font-weight: $bold;
  }
}

.RsvpRow_Contact {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.RsvpRow_Contact a {
  width: 33%;
  color: $foreground;
  text-decoration: none;
  cursor: pointer;
}

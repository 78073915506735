@import "../../styles/common.scss";


.CCLocationView {

  .CCLocationView_Comma {
    display: none;
  }

  @include media("<=phone") {
    &.CCLocationView_InlineMobile {

      // All parts should be inline
      .CCLocationView_Part,
      // Commas preceded by parts should become visible
      .CCLocationView_Part+.CCLocationView_Comma {
        display: inline;
      }

      .CCLocationView_LocationName,
      .CCLocationView_LocationName+.CCLocationView_Comma {
        display: none;
      }
    }
  }
}
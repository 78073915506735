@import "../../styles/common.scss";

.OkModal {
  display: flex;
  flex-direction: column;
}

.OkModal_Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: $bold;
  font-size: $smallHeadlineTextSize;
}

.OkModal_Message {
  display: flex;
  justify-content: center;
  font-weight: $bold;
}

.OkModal_Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3em;
}

.OkModal_Buttons .CCButton {
}
@import "../../styles/common.scss";

.CCDetailsBoxContainer {
  display: flex;
  justify-content: space-between;
  @include media("<=phone") {
    flex-direction: column;
  }
}

.CCDetailsBoxContainer .CCDetailsBox {
  @include media("<=phone") {
    // todo: should probably not apply margin to last child?
    margin-bottom: 1em;
    width: 100%;
  }
}
@import "../../../styles/ignite/common.scss";

.dtc-club-finder {
    display: flex;
    justify-content: center;
    .finder-wrapper {
        display: flex;
        margin-top: 48px;
        justify-content: space-between;
        @include media("<=phone") {
            flex-direction: column;
            margin-top: 0;
        }

        .button{
            font-weight: 800;
            font-size: 16px;
            @include media("<=phone") {
                margin-top: 16px;
            }
        }

    }
}

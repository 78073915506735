@import "../../styles/common.scss";

.FeaturedEvents {
  display: flex;
  &.Full {
    .EventResult {
      max-width: 270px;
      flex: 1;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 22px;
      }
    }
    .SelfGuidedIndicator {
      display: inline;
      font-size: 10px;
      height: 1.5em;
      fill: $secondaryAccent;
      margin-right: .3em;
      font-weight: $bold;
    }
    .PassionImage {
      padding-bottom: 71.1%;
    }
    .EventResult_PassionName {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      margin-top: 12px;
    }
    .EventResult_EventName {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: #0177C0;
      margin-top: 4px;
    }
    .EventResult_EventTime {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #3E3E3E;
      margin-top: 4px;
    }
    @include media("<=phone") {
      flex-direction: column;
      align-items: center;
      .EventResult {
        flex: unset;
        max-width: 350px;
        width: 100%;
        margin-top: 31px;
      }
    }
  }
  &.Mini {
    width: 100%;
    background: rgba(25, 118, 210, 0.08);
    border-radius: 8px;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    cursor: pointer;
    .SelfGuidedIndicator {
      display: inline;
      font-size: 8px;
      height: 1.5em;
      fill: $secondaryAccent;
      margin-right: .3em;
      font-weight: $bold;
    }
    .FeaturedEvents_Title {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }
    .EventResult_EventName {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #0177C0;
      margin-top: 13px;
    }
    .EventResult_EventTime {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }

    @include media("<=phone") {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}

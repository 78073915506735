@import "../../../../styles/ignite/common.scss";

.dashboard-calendar {
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        text-transform: uppercase;

        padding: 10px 0;

        .text {
            display: flex;
            align-items: center;
            color: #000;
            font-size: 20px;
            font-weight: 800;

            .info-icon {
                color: #0280CD;
            }
        }

        @include media("<=md") {
            padding-left: 0;
        }
    }

    .toolbar {
        display: flex;
        align-items: center;
        padding: 16px 32px;
        border: 1px solid $softBorder;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;

        .arrow {
            font-size: 32px;
            cursor: pointer;
        }
    }

    .header {
        border: 1px solid $softBorder;
        padding: 0 32px 15px;

        .total-activity-title {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 700;
            background: #ECF2FF;
            padding: 8px 16px;
            margin: 16px 0;
            border-radius: 8px;

            .right-content {
                display: flex;
                align-items: center;

                .icon {
                    font-size: 16px;
                    margin-left: 5px;
                    color: #c00;
                }
            }
        }

        .activity-amount-panel {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .in-person,
            .virtual-activity {
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                border: 1px solid $softBorder;
                min-width: 245px;
                padding: 8px 0;

                &.full-width {
                    width: 100%;
                }

                &.disabled-view {

                    .value,
                    .value .icon {
                        color: #bbb;
                    }
                }

                .value {
                    display: flex;
                    align-items: center;

                    .icon {
                        color: #0177C0;
                        margin-left: 8px;
                    }
                }

                @include media("<=md") {
                    flex-grow: 1;
                }

                @include media("<=sm") {
                    min-width: 145px;
                }
            }
        }

        .button {
            height: 40px;
            border: 1px solid $softBorder;
            padding: 0;
            min-width: 40px;
            color: #fff;
            font-size: 16px;
            font-weight: 800;
            background: #0177C0;
            width: 100%;
            margin: 16px 0;

            &.disabled-view {
                background: $softBorder;
            }

            &.disabled {
                pointer-events: none;
                background: $softBorder;
            }

            .icon {
                font-size: 16px;
                margin-left: 6px;
            }
        }

        @include media("<=sm") {
            padding: 0 0 10px 0;

            .total-activity-title {
                padding: 8px 5px;
            }
        }
    }

    .calendar-content {
        border: 1px solid $softBorder;

        .fc-day {
            border: none;
            height: 62px;
            width: 42px;
            box-sizing: border-box;
        }

        .fc-event-main {
            position: relative;
            height: 42px;
        }

        .event {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 40px;
        }

        .fc-daygrid-event {
            border-radius: 100px;
            background: #ECF2FF;
            border: none;
        }

        .fc .fc-daygrid-body-unbalanced {
            .fc-daygrid-day-events {
                position: absolute;
                z-index: -1;
                top: 0;
                bottom: 0;
            }
        }

        .fc-daygrid-day-frame {
            height: 42px;
            width: 42px;
        }

        .fc-col-header {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 18px;
            text-transform: uppercase;
            color: #555;

            @include media(">sm") {
                width: 100% !important;
            }
        }

        .fc-daygrid-day-events {
            width: 42px;
            height: 42px;
        }

        .fc-daygrid-day-top {
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 700;
        }

        .fc-scrollgrid-sync-inner {
            width: 42px;
            height: 42px;
        }

        .fc .fc-daygrid-day.fc-day-today {
            background: none;
        }

        .fc .fc-daygrid-day-frame {
            min-height: auto;
            display: flex;
            justify-content: center;
            border: none;
        }

        .fc-daygrid.fc-dayGridMonth-view.fc-view {
            z-index: 0;
        }

        .fc-event-start {
            position: relative;

            &::before {
                content: '';
                display: block;
                height: 42px;
                position: absolute;
                width: 42px;
                top: 0;
                left: 0;
                background: #0280CD;
                border-radius: 100px;
            }
        }

        .fc-event-end {
            position: relative;

            &::after {
                content: '';
                display: block;
                height: 42px;
                position: absolute;
                width: 42px;
                top: 0;
                right: 0;
                background: #0280CD;
                border-radius: 100px;
            }
        }

        .fc-daygrid-dot-event {
            background: none;

            .fc-daygrid-event-dot {
                display: none;
            }

            &::after {
                display: none;
            }
        }

        .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end) {
            border-radius: 100px;
        }

        .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start) {
            border-radius: 100px;
        }

        .fc-daygrid-day-bottom {
            display: none;
        }

        .fc-highlight {
            background: none;
        }

        .cell-custom {
            cursor: pointer;

            &.is-white {
                color: #fff;
            }
        }

        .fc-scroller-liquid-absolute {
            overflow-y: hidden !important;
        }

        .fc-scrollgrid-section-header {
            td {
                border: none;
            }

            .fc-scroller-harness {
                height: 36px;
            }
        }

        .fc-scrollgrid {
            padding: 0 0 0 32px;
            border: none;
        }

        .fc-day-other {
            pointer-events: none;
        }

        .fc-day-future {
            pointer-events: none;
        }

        .help-text {
            padding: 16px 34px 16px;
            font-size: 17px;
            font-weight: 700;
        }

        .fc-direction-ltr .fc-daygrid-event.fc-event-end {
            margin-right: 0;
        }

        .fc-direction-ltr .fc-daygrid-event.fc-event-start {
            margin-left: 0;
        }

        .fc .fc-daygrid-day-events {
            margin-top: 0;
        }

        .fc-direction-ltr.fc-theme-standard {
            min-height: 400px;
        }

        .fc-daygrid-body-unbalanced {
            width: 100% !important;
        }

        .fc-scrollgrid-sync-table {
            width: 100% !important;
        }

        .fc-event-title {
            display: none;
        }

        @include media("<=sm") {
            .fc-day {
                height: 50px;
            }

            .help-text {
                padding: 16px 7px 16px;
            }

            .fc-direction-ltr.fc-theme-standard {
                min-height: 300px;
                height: 350px;
            }
        }

        @include media(">=md") {
            .fc .fc-scroller {
                overflow: hidden !important;
            }
        }
    }

    &.locked {
        .fc-event-start {
            &::before {
                background: #D4383A;
            }
        }

        .fc-event-end {
            &::after {
                background: #D4383A;
            }
        }

        .cell-custom {
            cursor: default;
        }

        .fc-daygrid-event {
            background: rgba(212, 56, 58, 0.10);
        }
    }

    .fc-daygrid-dot-event {
        padding: 0;
    }

    @include media("<=sm") {
        .calendar-content {
            .fc-scrollgrid {
                padding-left: 3px;
            }
        }
    }

    @media screen and (orientation:landscape) {
        .fc-direction-ltr.fc-theme-standard {
            max-height: 400px;
        }
    }
}

.choose-activity-type-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;

    .choose-activity-type-modal-wrapper {
        width: 560px;
        min-height: 185px;
        background: #fff;
        padding: 32px 32px 40px 32px;
        box-sizing: border-box;
        position: relative;
        max-width: 100%;

        .title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;
            text-align: center;
        }

        .button-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .button {
                height: 40px;
                font-size: 18px;
                font-weight: 800;
                background: #0280CD;
                color: #fff;
                margin: 20px 0;
                width: 228px;
            }
        }
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
}

.calendar-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .calendar-modal-wrapper {
        position: relative;
        background: #fff;
        padding: 15px 15px;
        box-sizing: border-box;
        width: 520px;
        max-width: 97%;
        max-height: 95%;
        overflow: auto;

        .header {

            .in-person,
            .virtual-activity {
                min-width: 195px;
            }

            @include media("<=sm") {

                .in-person,
                .virtual-activity {
                    min-width: 145px;
                }
            }
        }

        .title {
            font-size: 24px;
            font-weight: 600;
            color: #0177C0;
        }

        .content {
            display: flex;
            flex-direction: column;

            .name {
                width: 100%;
                font-size: 20px;
                font-weight: 600;
                text-transform: capitalize;
                max-width: 500px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .subtitle {
                font-size: 20px;
                font-weight: 800;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 20px;


            }

            .description {
                font-size: 24px;
                font-weight: 600;
                text-align: center;
            }

            .dashboard-calendar {
                .toolbar {
                    padding: 8px 32px;
                }
            }
        }

        .notification-error {
            background: #D4383A
        }
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
}

.calendar-tooltip {
    background: #fff !important;
    box-shadow: 0px 8px 16px -2px rgba(27, 33, 44, 0.12);
    border-radius: 8px !important;
    padding: 12px !important;

    .tooltip-item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #000;
        font-size: 12px;
        font-weight: 400;
        text-align: center;

        .icon {
            color: #0177C0;
            height: 23px;
            width: 23px;
        }
    }

    .tooltip-item:not(:last-child) {
        margin-bottom: 8px;
    }

    .arrow {
        color: #fff;
    }
}

@import "../../styles/common.scss";

.PasswordResetForm {
  flex-basis: auto;
}

.PasswordResetForm_Result
{
  color: $errored;
  margin-bottom: 1.5em;
}

.PasswordResetForm_ButtonContainer
{
  display:flex;
  flex-direction: row;
}

.PasswordResetForm_FieldContainer
{
  display:flex;
  flex-direction: row;
}

.PasswordResetForm_ButtonContainer
{
  margin-top:  1em;
}

.PasswordResetForm_Password
{
  display:flex;
  margin-right: 1em;
}

.PasswordResetForm_Email
{
  display:flex;
  margin-right: .5em;
}

.PasswordResetForm_LoginButton
{
  margin-right: .5em;
}


.PasswordResetPage_PasswordResetResult
{
  color: $errored;
  margin-bottom: 1.5em;
}

.PasswordResetForm_ButtonContainer
{
  display:flex;
  flex-direction: row;
}

.PasswordResetForm_FieldContainer
{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.PasswordResetForm_ButtonContainer
{
  margin-top:  1em;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 21.15em;
  justify-content: space-between;
  @include media("<=phone") {
    align-items: start;
    gap: 1em;
    flex-direction: column-reverse;
  }
}

.PasswordResetForm_Email
{
  display:flex;
  margin-right: .5em;
  width: 20em;
}

.PasswordResetForm_Email fieldset
{
  width: 20em;
  @include media("<=phone") {
    width: 15em ! important;
  }
}

.PasswordResetForm_CancelButton
{
  font-weight: bolder;
  font-size: 18px;
  @include media("<=phone") {
    width: 10em ! important;
  }
}

.PasswordResetForm_PasswordResetButton
{
  @include media("<=phone") {
    margin-top: .5em;
    width: 10em ! important;
  }
}
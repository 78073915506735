@import "../../../styles/ignite/common.scss";

.member-landing-content {
    max-width: 850px;
    margin: 0 auto;

    .section1 {
        &__title {
            font-weight: 700;
            font-size: 38px;
            margin-top: 30px;
            margin-bottom: 30px;

            @include media("<=md") {
                text-align: center;
            }

            @include media("<=sm") {
                font-size: 34px;
            }
        }

        &__subtitle {
            font-size: 24px;
            color: #0177C0;
            text-align: center;
        }

        &__img {
            max-width: 100%;
            border-radius: 8px;
            margin: 30px auto 40px;

            &.desktop {
                display: block;
            }

            &.mobile {
                display: none;
            }

            @include media("<=sm") {
                margin-bottom: 30px;

                &.desktop {
                    display: none;
                }

                &.mobile {
                    display: block;
                }
            }
        }

        .button-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 35px;
            margin-bottom: 30px;

            .sign-up-button {
                background: #0288D1;
                height: 40px;
                color: #fff;
                font-size: 16px;
                font-weight: 800;
                width: 150px;
                margin-right: 20px;
            }

            .tell-more-button {
                color: #0177C0;
                border: 1px solid #0177C0;
                width: 160px;
                font-size: 16px;
                font-weight: 800;
            }

            @include media("<=sm") {
                flex-direction: column;

                .sign-up-button {
                    width: 100%;
                    margin-bottom: 30px;
                }

                .tell-more-button {
                    width: 100%;
                }
            }
        }

        .login-block {
            display: flex;
            justify-content: center;

            .have-account {
                font-size: 14px;
                margin-right: 8px;
            }

            .login {
                font-size: 14px;
                font-weight: 700;
                color: #0091E9;
                text-decoration: none;
            }
        }

        .arrow-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            .icon {
                color: rgba(26, 98, 199, 0.1);
                width: 60px;
                height: 60px;
            }
        }


        @include media("<=md") {
            margin-top: 120px;
        }

        @include media("<=sm") {
            margin-top: 130px;

            .login-block {

                .have-account,
                .login {
                    font-size: 16px;
                }
            }
        }
    }

    .section2 {
        margin-top: 70px;

        &__title {
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 30px;
            color: #0177C0;

            @include media("<=sm") {
                text-align: center;
            }
        }

        .testimony-wrapper {
            display: flex;
            justify-content: space-between;

            .testimony-item {
                max-width: 270px;

                .img {
                    border-radius: 8px;
                    max-width: 100%;
                }

                .text {
                    margin-top: 30px;
                    font-size: 18px;
                    color: #2F2F2F;
                    text-align: center;
                }
            }
        }

        .testimony-carousel {
            .text {
                margin-top: 15px;
                font-size: 18px;
            }

            .control-dots {
                display: none;
            }
        }

        @include media("<=sm") {
            margin-top: 40px;
        }
    }

    .section3 {
        margin-top: 80px;

        &__title {
            font-weight: 700;
            font-size: 38px;
            margin-bottom: 30px;
            text-align: center;
        }

        .content {
            display: flex;
            align-items: start;

            &__subtitle {
                font-size: 24px;
                color: #0177C0;
                padding-right: 30px;
                margin-bottom: 40px;

                @include media("<=sm") {
                    padding-right: 0;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }

            &__description {
                padding-right: 20px;
                font-size: 16px;
                color: #2F2F2F;
            }

            &__text {
                @include media(">=md") {
                    width: 50%;
                }
            }

            &__img {
                width: 50%;
                border-radius: 8px;

                &.mobile {
                    display: none;
                }

                &.desktop {
                    display: block;
                }

                @include media("<=sm") {
                    &.mobile {
                        display: block;
                        width: 100%;
                        border-radius: 8px;
                    }

                    &.desktop {
                        display: none;
                    }
                }
            }

            @include media("<=sm") {
                flex-direction: column-reverse;
            }
        }

        @include media("<=sm") {
            margin-top: 30px;
        }
    }

    .section4 {
        margin-top: 80px;

        &__title {
            font-weight: 700;
            font-size: 38px;
            margin-bottom: 30px;
            text-align: center;
        }

        .steps-wrapper {
            display: flex;

            .step-item {
                display: flex;
                flex-direction: column;
                align-items: center;

                .step-label {
                    font-size: 48px;
                    font-weight: 700;
                    color: #0177C0;
                    text-align: center;
                }

                .step-title {
                    padding: 0 30px;
                    font-size: 22px;
                    font-weight: 600;
                    text-align: center;
                    margin: 25px auto;
                    min-height: 60px;
                    display: flex;
                    align-items: center;

                    @include media("<=md") {
                        margin-top: 5px;
                    }
                }

                .step-content {
                    font-size: 18px;
                    color: #2F2F2F;
                    text-align: left;
                    text-align: center;
                }
            }
        }

        @include media("<=sm") {
            margin-top: 30px;

            .steps-wrapper {
                flex-direction: column;

                .step-label {
                    margin-top: 15px;
                }

                .step-title {
                    margin: 0 auto !important;
                    padding: 0 !important;
                }
            }

            &__title {
                margin-bottom: 20px;
            }
        }
    }

    .section5 {
        margin-top: 50px;

        .button-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            .sign-up-button {
                background: #0091E9;
                height: 62px;
                width: 270px;
                color: #fff;
                font-size: 16px;
                font-weight: 800;
            }

            .faq {
                display: flex;
                margin: 30px auto 50px;
                font-size: 14px;
                font-weight: 700;
                color: #0091E9;
                text-decoration: none;
                align-items: center;

                .icon {
                    margin-left: 5px;
                }
            }
        }
    }

    @include media("<md") {
        padding: 0 20px;
    }

    @include media("<=sm") {
        padding: 0 20px;
    }
}

@import "../../styles/common.scss";

.LegacyVirtualClubsPage {

  .VirtualClubsPage_Header {
  }

  .VirtualClubsPage_Loading {
    margin: 2em auto;
  }

  .ErrorMessage {
    margin-top: 1.5em;
    color: $errored;
  }

  .Results {

    hr {
      margin: 1em 0;
    }

    .Result {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ClubName {
        color: $primaryAccent;
        font-size: $largeTextSize;
        font-weight: $semiBold;
        flex-basis: 25%;
      }

      .Passion {
        margin-left: 1em;
        flex-basis: 25%;
        @include media("<=phone") {
          margin-left: 0;
        }
      }

      .Location {
        margin-left: 1em;
        flex-basis: 25%;
        @include media("<=phone") {
          margin-left: 0;
        }
      }

      .Events {
        margin-left: 1em;
        flex-basis: 25%;
        text-align:right;
        @include media("<=phone") {
          margin-left: 0;
          text-align:inherit;
        }
      }

      .Events .CCButton {
        justify-self: flex-end;
      }
    }
  }

  .PreviousAndNextButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .PreviousButton, .NextButton {
      margin: 1em;
    }
  }
}

@import "./VirtualClubsCommon.scss";

.pt-48 {
    padding-top: 20%;
}

.EmailVerificationNag {
    margin-bottom: 32px;
}


.VirtualClubsCategoryPage {
    @media (max-width: $mobileWidth) {
        width: calc(100% + 48px);
        left: -24px;
    }

    .equal-columns {
        gap: 0;
    }

    .VirtualClubs_Term {

        height: 110px;

        img {
            max-width: 75px;
        }

        &.active {
            background: #ffffff;
            border: 2px solid $e3blue;
        }
    }


    .Card_Container {
        height: 430px;
        width: 100%;
        margin: 0 auto;
    }


}

.VirtualClubs_ClubList {
    height: 428px;
    padding: 22px 0;

    @media (max-width: $mobileWidth) {
        padding: 12px 0;
    }
}




/* carousel customizations */
.recCarousel,
.rec-item-wrapper,
.rec-slider-container {
    height: 100% !important;

}

.rec.rec-slider-container {
    margin: 0;
}

.rec.rec-arrow:disabled {
    visibility: hidden;
}

.rec.rec-arrow {
    height: 100%;
    width: 32px;
    border-radius: 0;
    background: url("../../assets/nextprev-button.png") no-repeat center center;
    background-size: 10px 16px;
    border: none;
    color: transparent;
    box-shadow: none;

    &:hover,
    &:hover:enabled,
    &:focus,
    &:focus:enabled {
        height: 100%;
        border-radius: 0;
        background: url("../../assets/nextprev-button.png") no-repeat center center;
        background-size: 10px 16px;
        border: none;
        box-shadow: none;
        color: transparent;
    }

    &.rec-arrow-left {
        transform: rotate(180deg);
    }
}
@import "../../styles/common.scss";

.EventFileList {
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }

  .EventFileList_Title {
    font-weight: bold;
    font-size: $largeTextSize;
    margin-bottom: .75em;
    align-content: center;
    flex-direction: row;

    svg {
      color: $secondaryAccent;
      height: 40px;
      width: 40px;
      margin-right: .2em;
    }

    .EventFileList_Title_Text {
      align-self: center;
    }
  }

  .EventFileList_Instructions {
    margin-bottom: 1em;
  }

  .EventFileList_Children {
    flex-direction: row;
    flex-wrap: wrap;
    @include media("<=phone") {
      flex-direction: column;
      align-content: center;
    }

    .EventFile {
      margin-right: 1.5em;
      padding: .1em;
    }

    .EventFile_Status_Failure {
      border: 4px solid $errored;
    }

    .EventFile_Status_Pending {
      border: 4px solid gold;
    }
  }

  .EventFile:first-child {
    margin-bottom: 1em;
  }
}

.EventFile {
  display: flex;

  &.EventFile_Broken {
    cursor: not-allowed;
  }

  &:not(.EventFile_Broken) {
    cursor: pointer;
  }

  flex-direction: column;

  @include media("<=phone") {
    justify-content: center;
  }

  div {
    display: flex;
  }

  .WordDocumentIcon {
    height: 140px;
    width: auto;
  }

  .PdfDocumentIcon {
    height: 140px;
    margin-left: 10px;
    width: auto;
  }

  .FullSizeThumbnail {
    height: 140px;
    width: 140px;
  }

  .BrokenImageIcon {
    height: 140px;
    width: 140px;
  }

  .EventFile_Title {
    font-weight: bold;
    font-size: $smallTextSize;
    margin-top: .75em;
    justify-content: center;
    text-align: center;
    width: 140px;
  }

  .EventFile_Children {
    justify-content: center;
  }
}

.EventFile_Modal {
  img {
    width: 100%;
  }
  .MuiDialog-paper {
    border-radius: 2em;
  }
  .EventFile_Modal_Close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
  }

  .EventFile_Modal_CloseIcon {
    color: #bababa;
    cursor: pointer;
  }
}

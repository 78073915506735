@import '../../styles/common.scss';

.ClubDirectory {
  display: flex;

  flex-direction: row;

  @media (max-width: 1024px) {
    position: relative;
  }

  &-Single {
    $margin: 8px;
    $color: #1345a9;
    $border-color: rgba(0, 0, 0, 0.1);

    a {
      color: $color;
      font-weight: bold;
    }

    margin-bottom: 4rem;

    &-CallUs {
      background-color: #eef5ffff;
      box-shadow: 0 0.3rem 0.75rem rgba(0, 0, 0, 0.1);
      padding: 2rem;
      border-radius: 0.5rem;
      border: solid 1px $border-color;

      h3,
      p {
        margin: 0;
      }

      h3 {
        margin-bottom: 1rem;
      }
    }

    &-Title,
    &-Surtitle,
    &-Subtitle {
      margin-bottom: $margin;
      margin-top: $margin;
    }

    &-Subtitle {
      padding: 8px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      svg {
        padding-right: 12px;
        color: $clubDirectory_dkBlue;
      }
      a {
        font-weight: normal;
      }
    }

    &-Surtitle {
      text-transform: uppercase;
    }

    &-Button.MuiButtonBase-root {
      background-color: $color;
      color: white;
      font-weight: 600;
      font-size: 1.1rem;
      text-transform: none;
      border-radius: 1.75rem;
      padding: 0.7rem 2rem;

      &:hover {
        background-color: $color;
      }
    }

    &-Title {
      color: $color;
    }

    &-CoverImage {
      aspect-ratio: 4/3;
      border-radius: 10px;
      background-size: cover;
      background-position: center;
    }

    &-BackToClubFinder {
      position: relative;
      left: -0.6rem;

      .MuiTypography-body2 {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }

    &-Spacer {
      margin-top: 2rem;
      margin-bottom: 2rem;
      border-width: 0;
      border-bottom-width: 1px;
      border-color: $border-color;
    }
  }
}

/*
LAYOUT - 
Desktop (>= 768px) -
1440 px is max width of actual content (filter column + results column
left filter column is 38% width, maxing out at 38% of 1440px, minimum 376px
right col is flexible - 62% width

don't worry about it yet -
at < 768px, break to a stacked filter on top of clubs layout with collapsible filter screen
*/

.ClubDirectory_FilterColumn_Container {
  display: flex;
  justify-content: right;
  background: $clubDirectory_ltBlue;
  padding: 32px;
  width: calc(((100vw - 1440px) / 2) + (0.38 * 1440px));
  min-width: calc(376px - (32px * 2));
  max-width: calc(620px - (32px * 2));
}

.ClubDirectory_FilterColumn {
  width: 100%;

}

.ClubDirectory_Section.ClubDirectory_FilterColumn_MobileHeader {
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
}

.ClubDirectory_Section {
  margin-bottom: 32px;
}

.ClubDirectory_Section--WithSeparator {
  padding-bottom: 40px;
  border-bottom: 1px solid $clubDirectory_ltGrey;
  flex-grow: 0;
}

.ClubDirectory_FormControl_Row1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  >* {
    display: flex;
  }
}

.ClubDirectory_Title {
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  color: $clubDirectory_darkText;
}

.ClubDirectory_SubTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  color: $clubDirectory_darkText;
}

.ClubDirectory_MobileToggle {
  display: none;
}

.ClubDirectory_Link {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: $clubDirectory_dkBlue;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
}

.ClubDirectoryVC_Link {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: $clubDirectory_dkBlue;
  text-decoration: underline;
  cursor: pointer;
}

.ClubDirectory_OnlyMobile {
  display: none;
}

.ClubDirectory_ClubList_Container {
  display: flex;
  flex: 1 1;
  justify-content: left;
  max-width: none;
  padding: 32px;
}

.ClubDirectory_ClubList {
  width: 100%;
  max-width: 1070px;

  // Pagination
  .MuiPaginationItem-textPrimary.Mui-selected,
  .MuiPaginationItem-textPrimary.Mui-selected:hover,
  .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
    background-color: $clubDirectory_dkBlue;
  }
}

.ClubDirectory_FilterDisplayMessage {
  margin-bottom: 18px;
}

.ClubDirectory_VirtualClubs {
  display : inline;
}

.ClubDirectory_DirectoryWorkInProgress {
  width: $desktopPageContentWidth * 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  @include media("<=phone") {
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .ClubDirectory_FilterColumn_Container {
    width: calc(38% - (32px * 2));
  }

  .ClubDirectory_ClubList_Container {
    width: calc(62% - (32px * 2));

  }
}

.ClubDirectory_Section--Bottom {
  display: none;
}

// up to tablet - "mobile" layout
@media (max-width: $mobileMaxWidth) {
  .ClubDirectory {
    flex-direction: column;
  }

  .ClubDirectory_FilterColumn_Container {
    padding: 16px;
    width: calc(100% - (16px * 2));
    max-width: none;
    justify-content: left;
    position: fixed;
  }

  .ClubDirectory_FilterColumn {
    width: 100%;
  }

  .ClubDirectory_FilterColumn_MobileHeader--Form {
    margin-bottom: 0;
  }

  .ClubDirectory_FilterColumn_MobileBody {
    display: none;
  }

  .ClubDirectory_OpenMobileMenu {

    .ClubDirectory_ClubList_Container,
    .Footer {
      display: none;
    }

    .ClubDirectory_FilterColumn_Container {
      // 100% smallest visible height - 109px (header static size) - 32px (container margin 16px * 2)
      height: calc(100svh - 109px - 32px);
      max-height: calc(100svh - 109px - 32px);
      position: static;
    }

    .ClubDirectory_ClubList_Container {
      overflow: hidden;
    }

    .ClubDirectory_FilterColumn_MobileHeader--Form {
      margin-bottom: 16px;
    }

    .ClubDirectory_FilterColumn_MobileBody {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
    }

    .ClubDirectory_Section--FillToBottom {
      flex-grow: 1;
      overflow-y: auto;
      margin-bottom: 0;
    }

    .ClubDirectory_Section--Bottom {
      position: relative;
      // deal with the margin of the container
      left: -16px;
      width: 100%;
      bottom: 0;

      background: white;
      height: calc(80px - 32px);

      display: flex;
      padding: 16px;

      flex-grow: 0;
    }

    .ClubDirectory_MobileFilter_SubmitButton {
      height: 48px;
      min-width: 180px;
      padding: 0 32px;
      flex-basis: 100%;

    }

    .ClubDirectory_MobileToggle {
      .ClubDirectory_MenuOpen {
        display: none;
      }

      .ClubDirectory_MobileClose {
        display: block;
      }
    }

  }

  .ClubDirectory_MobileToggle {
    display: flex;

    .ClubDirectory_MenuOpen {
      display: block;
    }

    .ClubDirectory_MobileClose {
      display: none;
    }
  }

  .ClubDirectory_OnlyMobile {
    display: flex;
  }

  .ClubDirectory_ClubList_Container {
    width: calc(100% - (32px * 2));
    // on mobile, find a club toggle is position: fixed, so push this down by its height
    margin-top: 44px;
  }
}
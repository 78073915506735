@import "../../../styles/ignite/common.scss";

.member-dashboard {
    .header {
        border-bottom: 1px solid $softBorder;

        .header-inner {
            width: 855px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            padding: 20px 0;

            .logo {
                width: 140px;
                height: 38px;
            }

            .navigation {
                min-height: 40px;
                flex-grow: 1;
                display: flex;
                justify-content: center;

                .nav-link {
                    position: relative;

                    text-decoration: none;
                    color: #222;
                    font-size: 16px;
                    text-transform: uppercase;
                    border: 2px solid transparent;
                    padding: 5px 10px;
                    margin-right: 10px;

                    &.active {
                        border-bottom: 3px solid #0177C0;
                        font-weight: 700;
                    }
                }

                .nav-link:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    right: -5px;
                    height: 30px;
                    top: 2px;
                    width: 1px;
                    background: #ccc;
                }
            }

            .profile {
                display: flex;
                flex-direction: column;
                text-align: right;

                .actions {
                    display: flex;
                    color: #0177C0;
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: capitalize;
                    cursor: pointer;

                    .action {
                        position: relative;
                        padding: 5px 0;

                        &:first-child {
                            margin-right: 10px;

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                height: 20px;
                                top: 6px;
                                right: -6px;
                                width: 1px;
                                background: #222;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media("<=md") {
        margin-top: 35px;

        .header {
            .header-inner {
                justify-content: space-between;
                width: auto;
                padding: 20px 15px;
                position: relative;

                .hamburger-icon {
                    font-size: 36px;
                }

                .navigation {
                    position: absolute;
                    top: 85px;
                    left: 0;
                    right: 0;
                    flex-direction: column;
                    z-index: 10;

                    .nav-link {
                        display: block;
                        background: #0091e9;
                        color: #fff;
                        margin-right: 0;
                        font-size: 24px;
                        font-weight: bold;
                        text-align: center;
                        padding-top: 1em;
                        padding-bottom: 1em;
                        border-bottom: 1px solid #fff;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .leaderboard {
        margin-top: 0;
    }

    .notification-error {
        background: #D4383A
    }
}

.dashboard-content {
    // this matches the tablet max width/medium breakpoint
    max-width: 855px;
    margin: 20px auto 40px;
    display: flex;
    flex-direction: column;

    .dashboard-tabs-indicator {
        background-color: #000;
        margin-bottom: 5px;
        height: 5px;
    }

    .dashboard-tab {
        color: #000;
        font-size: 16px;
        font-weight: 600;

        &.selected {
            color: #000;
            font-weight: 800;
        }
    }

    .info-icon {
        color: #0280CD;
        margin-left: 5px;
        cursor: pointer;
        width: 26px;
        height: 26px;
    }

    .active-months-section,
    .submitted-months-section {
        .section-header {
            padding: 0 25px 10px 20px;
            color: #01639F;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            display: flex;

            .activity {
                flex-basis: 42%;
            }

            .month {
                flex-basis: 35%;
            }

            .activity {
                display: flex;
                justify-content: space-between;
                flex-grow: 1;
            }

            @include media("<=md") {
                padding-right: 73px;

                .month {
                    flex-basis: 37%;
                }
            }

            @include media("<=sm") {
                .month {
                    flex-basis: 43%;
                }
            }
        }

        .accordion-summary {
            display: none;
        }

        .accordion {
            box-shadow: none;
            margin-top: 0;

            &::before {
                display: none;
            }

            .accordion-details {
                display: flex;
                flex-direction: column;
                padding-left: 0;

                @include media("<=md") {
                    padding-right: 0;
                }
            }
        }

        .month-item {
            padding: 20px;
            display: flex;
            border: 1px solid $softBorder;
            border-radius: 4px;
            justify-content: space-between;
            margin-bottom: 10px;
            position: relative;

            .text {
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                flex-basis: 37%;

                @include media("<=md") {
                    flex-basis: 22%;
                }

                @include media("<=sm") {
                    flex-basis: 47%;
                }
            }

            .lock-icon {
                margin-right: 15px;
            }

            .amount {
                font-size: 20px;
                font-weight: 800;
                flex-basis: 33%;
                text-align: center;
            }

            .icon-wrapper {
                position: absolute;
                flex-basis: 35px;
                display: flex;
                justify-content: end;
                right: 15px;
                top: 15px;
            }

            .check-icon {
                font-size: 33px;
                color: #0177C0;
            }

            .close-icon {
                font-size: 33px;
                color: #D4383A;
            }
        }

        .title-wrapper {
            display: flex;
            justify-content: space-between;

            .year-select {
                width: 105px;
                margin-right: 16px;

                .root {
                    color: #222;
                    font-size: 20px;
                    font-weight: 800;
                }

                @include media("<=md") {
                    margin-right: 0;
                }
            }
        }

    }

    .active-months-section {
        .section-header {
            padding-right: 0;

            .month,
            .activity {
                flex-basis: 29%;
                padding-right: 30px;
            }

            @include media("<=md") {
                justify-content: space-between;

                .month {
                    flex-basis: 29%;
                }

                .activity {
                    flex-grow: 0;
                    flex-basis: 45.5%;
                }
            }

            @include media("<=sm") {
                display: none;
            }
        }

        @include media("<=sm") {
            .activity {
                flex-basis: 50%;
                text-align: center;
                margin-left: 10px;
            }

            .bottom-content {
                justify-content: stretch;

                .button-wrapper {
                    width: 100%;

                    .button {
                        width: 100%;
                    }
                }
            }
        }
    }

    .submitted-months-section {
        @include media("<=sm") {
            .section-header {
                padding-right: 30px;
            }

            .month-item {
                .icon-wrapper {
                    display: none;
                }
            }
        }
    }

    .section-title {
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        .icon {
            font-size: 30px;
        }
    }

    .tracking-subtitle {
        display: flex;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 800;
        padding: 10px 0;

        @include media("<=md") {
            padding-left: 0;
        }
    }

    .top-content {
        border: none;
    }

    .bottom-content {
        display: flex;
        border: none;
    }

    .main-content {
        width: 590px;
        margin-right: 16px;

        .list-title {
            font-size: 32px;
            font-weight: 800;
            margin-top: 40px;
            margin-bottom: 5px;
        }

        @include media("<=md") {
            width: 100%;
            margin-right: 0;
        }
    }

    .stats-panel {
        border: 1px solid $softBorder;
        padding: 20px 30px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        margin-right: 16px;

        &__score,
        &__ranking {
            display: flex;
            flex-direction: column;
            align-items: center;

            .text {
                font-size: 16px;
                font-weight: 800;
                color: #222;
                text-transform: uppercase
            }

            .amount {
                font-size: 40px;
                font-weight: 800;
                color: #0177C0;
            }
        }

        @include media("<=md") {
            margin-right: 0;
        }
    }

    .leaderboard-money-info {
        .icon-wrap {
            display: none;
        }
    }

    @include media("<=md") {
        padding: 0 10px 50px;
        margin: 0 auto;
        flex-direction: column;

        .leaderboard {
            width: auto;
            margin-top: 10px;

            &__title {
                justify-content: center;
            }

            &-ranking {
                justify-content: space-around;
                padding-right: 15px;
                padding-left: 15px;
                gap: 22px;

                .amount {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    flex-basis: 49%;
                }

                .badges {
                    flex-grow: 1;
                    display: flex;
                    justify-content: center;
                    flex-basis: 49%;
                }
            }

            &-money-info {

                .club,
                .total {
                    flex-basis: 49%;
                }
            }
        }
    }

    .get-help-panel {
        padding: 20px;
        border: 1px solid $softBorder;
        border-radius: 4px;
        margin-top: 20px;

        .title {
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .label {
            margin-top: 15px;
            font-size: 16px;
            font-weight: 700;
        }

        .link {
            font-size: 16px;
            font-weight: 400;
            text-decoration: underline;
            color: #0177C0;
        }
    }
}

.dot {
    font-size: 40px;
    line-height: 21px;
    max-height: 24px;
    display: inline-block;
    padding: 0 5px;
}

.option-item {
    display: flex;
}

.info-panel-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -40px;
    margin-top: -55px;

    .info-panel {
        max-width: 250px;

        .main-content {

            @include media("<=sm") {
                flex-grow: 1;
            }
        }

        .leaderboard-my-activity {
            @include media("<=sm") {
                max-width: 121px;
                padding: 30px 11px;
                flex-direction: column;
                margin-left: 10px;
            }
        }

        @include media("<=sm") {
            display: flex;
            flex-grow: 1;
            max-width: 100%;
            flex-direction: row-reverse;
        }
    }

    @include media("<=sm") {
        justify-content: space-between;
        margin: 0 auto;
    }
}

.info-tracking-activity-modal-wrapper,
.confirm-lock-it-in-modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .info-tracking-activity-modal,
    .confirm-lock-it-in-modal {
        background: #fff;
        max-width: 460px;
        padding: 40px;
        border-radius: 4px;

        .content {
            padding: 20px 40px;
            border: 1px solid $softBorder;

            .title {
                color: #0280CD;
                font-weight: 800;
                font-size: 32px;
                text-align: center;
                margin-bottom: 25px;
            }

            .subtitle {
                text-transform: uppercase;
                font-weight: 800;
                margin-bottom: 10px;
                margin-top: 20px;
            }

            .button-wrapper {
                display: flex;
                justify-content: center;
                margin-top: 30px;

                .button {
                    color: #0280CD;
                    font-size: 18px;
                    font-weight: 800;
                    width: 280px;
                }
            }

            .list {
                margin-top: 0;
                padding-left: 30px;
                white-space: pre-line;

                li {
                    margin-bottom: 10px;
                }
            }

            @include media("<=sm") {
                padding: 20px 10px;
            }
        }

        @include media("<=sm") {
            max-width: 380px;
            padding: 20px;
        }
    }

    .info-tracking-activity-modal {
        overflow-y: auto;
        max-height: 100%;
    }

    .confirm-lock-it-in-modal {
        .content {
            padding: 30px 40px;

            .subtitle {
                font-weight: 600;
            }

            .button {
                color: #0280CD;
                font-size: 18px;
                font-weight: 800;
                width: 110px !important;

                &.lock {
                    background: #0177C0;
                    color: #fff !important;
                    font-size: 16px;
                    font-weight: 800;
                    width: 180px !important;
                    margin-left: 20px;

                    .icon {
                        margin-left: 10px;
                    }
                }
            }

            @include media("<=sm") {
                padding: 30px 10px;
            }
        }
    }
}

@import "../../styles/common.scss";

.PasswordResetConfirmPage
{
  margin-bottom: 1em;
}

.PasswordResetConfirmPage_FormContainer
{
  @include media(">phone") {
    max-width: 80%;
  }
}
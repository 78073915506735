@import "../../styles/common.scss";

@include media("<=phone") {
  .NavigationMobile {
    width: 100%;
    display: none;
    background-color: $primaryAccent;

    color: $primaryAccentForeground;
    position: absolute;
    top: 60px;
    z-index: 100;
    max-height: 100vh;
    overflow-y: auto;
  }

  .NavigationMobile_Show {
    display: flex;
  }

  .NavigationMobile_Container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}


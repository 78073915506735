.CustomEnrollmentFaq {
  .CustomEnrollmentFaq_BannerContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  .CustomEnrollmentFaq_Banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .CustomEnrollmentFaq_BodyText {
    .strong {
      font-weight: 700;
    }
    .em {
      font-style: italic;
    }
    .code {
      font-family: monospace;
      background-color: #ebebeb;
    }
    .underline {
      text-decoration: underline;
    }
    .strike-through {
      text-decoration: line-through;
    }
    a {
      font-weight: 800;
      color: #0891e9;
      text-decoration: none;
    }
  }
}

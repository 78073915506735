@import "../../../styles/ignite/common.scss";

.tracking-item.with-details {
    display: flex;
    align-items: center;
    padding: 0;
    border: 1px solid $softBorder;
    border-radius: 4px;
    margin-bottom: 5px;
    background: #fff;
    position: relative;
    max-width: 100%;

    .main-info {
        width: 130px;
        padding: 0 0px 0 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        box-sizing: border-box;
        margin-right: 25px;

        &__name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-bottom: 3px;
            border-bottom: 1px solid $softBorder;
        }

        &__rank-with-money {
            display: flex;
            padding-top: 2px;
            align-items: center;
        }

        &__money {
            font-weight: 600;
            min-width: 45px;
        }

        .dot {
            margin: 0 2px;

            @include media("<=sm") {
                margin: 0;
                padding-right: 2px;
                padding-left: 2px;
            }
        }

        &__rank {
            display: flex;
            align-items: center;
            font-weight: 600;
            display: flex;
            align-items: center;
            min-width: 54px;

            @include media("<=sm") {
                justify-content: space-between;
                min-width: 55px;
            }

            .icon-wrapper {
                min-width: 27px;
                min-height: 24px;
                display: flex;
                align-items: center;
            }

            .icon {
                color: $primaryDark;
            }
        }

        @include media("<=md") {
            flex-basis: 35%;
            margin-right: 10px;
        }

        @include media("<=sm") {
            padding: 0 0 0 10px;
            flex-basis: 38%;
            max-width: 110px;
        }
    }

    &.with-referral-status {
        @include media(">=md") {
            .main-info {
                flex-basis: 460px;
            }

            .description {
                width: 170px;
            }
        }
    }

    .months {
        display: flex;
        flex-basis: 48%;

        .month {
            margin-right: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: 600;

            .amount {
                font-weight: bold;
                border-radius: 4px;
                width: 42px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .amount-input {
                padding: 0;
                border: none;
                outline: none;
                width: 42px;
                height: 32px;
                text-align: center;
                appearance: textfield;
                -moz-appearance: textfield;
                -webkit-appearance: textfield;

                &.absent {
                    color: red;
                    font-weight: bold;
                }

                &.read-only {
                    cursor: default;
                }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .status {
        flex-basis: 22%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0;


        .button {
            font-size: 16px;
            text-align: center;
            font-weight: 800;
            background: $primaryDark;
            text-transform: uppercase;
            color: #fff;
            margin-left: 15px;
            min-width: 90px;

            &:hover {
                background: $primaryAccent;
            }

            @include media("<=sm") {
                width: 75px;
                font-size: 15px;
                min-width: 75px;
                margin-left: 0;
                margin-right: 5px;
            }
        }

        .action-message {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
        }

        @include media("<=md") {
            flex-basis: 18%;
        }

        @include media("<=sm") {
            flex-basis: 12%;
            justify-content: center;
            padding-right: 3px;
        }
    }

    .accordion {
        margin-top: 0;
        box-shadow: none;
        border-radius: 4px;
        width: 100%;

        &:before {
            border: none;
            background-color: transparent;
        }

        .content {
            justify-content: space-between;
            padding: 5px 0 0 0 !important;
            margin: 0;

            .description {
                font-size: 14px;
                font-weight: 700;
                display: flex;
                align-items: center;
                text-transform: capitalize;
            }

            &.expanded {
                .main-info {
                    flex-basis: 100%;

                    @include media("<=sm") {
                        max-width: 100%;
                    }
                }
            }
        }

        .accordion-summary {
            padding-right: 5px;

            &.expanded {
                margin: 0 auto;
            }
        }

    }

    .accordion-details {
        display: flex;
        flex-direction: column;
        padding: 8px 0 0 0;

        .months {
            border-top: 1px solid $softBorder;
            border-bottom: 1px solid $softBorder;
            padding: 5px 15px;

            .month {
                margin-right: 4px;

                @include media("<=sm") {
                    margin-right: 3px;
                }
            }

            @include media("<=sm") {
                padding: 2px;
            }
        }

        @include media("<=sm") {
            padding: 8px 5px;
        }
    }

    .content.expanded {
        margin: 12px auto 0 !important;
    }

    .arrow-icon-wrapper {
        position: absolute;
        left: 0;
        z-index: 1;

        &.expanded {
            top: 25px;
        }

        &.disabled {
            visibility: hidden;
        }

        .arrow-icon {
            width: 15px;
            height: 15px;

            &.expanded {
                transform: rotate(90deg);
                color: #0177C0;
            }
        }

        @include media("<=sm") {
            padding: 8px 5px;
        }
    }
}

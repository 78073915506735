@import "../../styles/common.scss";


.ClubEvents {
  .EventListNameItem {
    flex-basis: 35%;
  }

  .EventListDateTimeItem {
    flex-basis: 35%;
    padding-left: 1em;
    @include media("<=phone") {
      padding-left: 0;
      margin-top: .5em;
      margin-bottom: .5em;
    }
  }

  .EventListButtonItem {
    flex-basis: 30%;
  }

  .EventListNameItem .GoingIndicator {
    font-size: $textSize;
    margin-bottom: .5em;
  }
}

.ClubEvents_PreviousAndNextButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .PreviousButton, .NextButton {
    margin: 1em;
  }
}

@import "../../../../../../styles/ignite/common.scss";

.club-search{
    .club-finder-box {
        border-radius: .5em;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-top: 0;
        padding: .7em;
    
        .form-control {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .search-icon {
                font-size: 3.2em !important;
                color: $darkCallout;
        
                @include media("<=phone") {
                    display: none;
                }
            }

            .search-input {
                width: 100%;
                margin: 0 .7em;
                background: $background;
                border-radius: .5em;
                .menu-item {
                    width: 100%;
                    background-color: aqua;
                }
            }
        }
    }
}
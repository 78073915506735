@import "../../styles/common.scss";

.CCBackLink {
  font-size: $navigationTextSize;
  display: flex;
  align-items: center;
}

.CCBackLink a {
  color: $foreground;
  text-decoration: none;
  font-weight: $bold;
}

.CCBackLink svg {
  margin-right: .2em;
  color: $primaryAccent;
  font-size: $largeHeadlineTextSize;
  display: flex;
}


.CCBackLink_Special {
  display: flex;
  color: $foreground;
  text-decoration: none;
  font-weight: $bold;
  cursor: pointer;
}
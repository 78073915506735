.CustomEnrollmentStepTracker {
  $tablet: 650px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto 0 auto;
  padding: 50px 0 30px 0;
  @media (max-width: $tablet) {
    width: 100%;
  }
  .CustomEnrollmentStepTracker_Step {
    text-align: center;
    text-wrap: normal;
    width: 130px;
  }
  .CustomEnrollmentStepTracker_Line {
    height: 2px;
    width: 25%;
    margin-top: 26px;
    background-color: #BDBDBD;
    vertical-align: middle;
  }
}
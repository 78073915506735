.AcblFaq {
  .AcblFaq_Banner {
    // background-image: url("../../assets/acbl-faq.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    max-height: 400px;
    margin-bottom: 36px;
  }
  .AcblFaq_Intro {
    margin-bottom: 24px;
  }
  .AcblFaq_Question {
    margin-bottom: 24px;
  }
  .AcblFaq_Question_Question {
    font-weight: 800;
    margin-right: 3px;
    margin-bottom: 36px;
  }
  .AcblFaq_PlanSection:last-child {
    margin-bottom: 24px;
  }
  .AcblFaq_PlanSection:not(:last-child) {
    margin-bottom: 4px;
  }
  .AcblFaq_PlanSection_Title {
    font-weight: 800;
  }
  .AcblFaq_Transition1 {
    margin-bottom: 24px;
  }
  .AcblFaq_PlanSection_Plans {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 4px;
    padding-inline-start: 90px;
  }
  .AcblFaq_Transition2 {
    margin-bottom: 24px;
  }
  .AcblFaq_Transition2_Title {
    font-weight: 800;
    margin-right: 4px;
  }
  .AcblFaq_GetStarted {
    margin-bottom: 24px;
  }
  .AcblFaq_GetStarted_Title {
    font-weight: 800;
    margin-right: 4px;
  }
  .AcblFaq_GetStarted_Link {
    font-weight: 800;
    color: #0891e9;
    text-decoration: none;
  }
  .AcblFaq_Step1 {
    margin-bottom: 24px;
  }
  .AcblFaq_Step1_Title {
    font-weight: 800;
    margin-right: 4px;
  }
  .AcblFaq_Contact {
    font-weight: 800;
    margin-bottom: 24px;
    a {
      color: #0891e9;
      text-decoration: none;
    }
  }
}

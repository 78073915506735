@import "../../styles/common.scss";

.ImportantDetails {
  display: flex;
  flex-direction: column;

  .ImportantDetails_Title {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      display: flex;
      fill: $secondaryAccent;
      margin-right: .1em;
      width: 40px;
      font-size: 60px;
      @include media("<=phone") {
        font-size: 30px;
      }
    }

    .ImportantDetails_TitleText {
      display: flex;
      font-weight: $bold;
      align-self: center;
    }
  }

  .ImportantDetails_Body {
    white-space: pre-line;
  }
}

@import "../../styles/common.scss";

.e3BlueBackground {
  background-color: #0891e9;
  text-align: center;
}
.e3BlueText {
  color: #0891e9;
}

.whiteText {
  color: white;
}

.signUpButton {
  color: white;
}

.topPadding {
  padding-top: 25px;
}

.paragraph {
  font-size: 20px;
}

.customButton {
  background-color: white;
  color: black;
  font-size: 15px;
  padding: 10px 30px;
  border-radius: 0;
  //margin-top: 250px;
  //margin: 10px 0px;
  cursor: pointer;
  border-width: 0ch;
  font-weight: 600;
}

.partners {
  //display: flex;
  height: 100%;
  width: 100%;
  min-height: 10em;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.greyBackground {
  background-color: #efeeee;
  text-align: center;
}

.image-cropper {
  width: 50%;
  margin: 0 auto;
  width: 125px;
  height: 125px;
  //position: relative;
  overflow: hidden;
  border-radius: 50%;
  //align-self: center;
}
.profile-pic {
  //display: inline;
  //margin: 0 auto;
  //   margin-left: -25%; //centers the image
  width: 100%;
  //   auto: auto;
}

.activityBlock {
  text-align: center;
  position: relative;
}
.activityText {
  background-color: #efeeee;
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.instructorPicture {
  // display: flex;
  // height: 100%;
  // width: 100%;
  // justify-content: center;
  // align-items: center;
  //display: flex;
  padding-left: 150px;
  border-width: 2px;
  //text-align: center;
  //align-content: center;
  //justify-content: center;
  border-color: gray;
  text-align: "center";
  align-items: "center";
  border-style: solid;
  //padding-left: 15%;
}

.memberQuote {
  font-size: 30px;
  font-weight: 100;
}

.memberName {
  text-align: right;
  padding-bottom: 10px;
}

.quote {
  text-align: left;
}

.MemberLandingPage {
  #EventLanding_Banner {
    width: 100%;
    height: auto;
    background-image: url("../../assets/eventBanner.jpeg");
    margin: 0;
  }

  #MemberLanding_Text {
    flex: 50%;
  }

  #MemberLanding_Text p {
    margin-block-start: 0;
  }

  #MemberLanding_Image_Wrapper {
    flex: 50%;
    width: 305px;
    height: 305px;
    margin-right: 30px;
    @include media("<=phone") {
      margin: auto;
    }
  }

  #MemberLanding_Image_Wrapper img {
    width: 305px;
    height: 305px;
    border-top-left-radius: 10px;
    border-top-right-radius: 92px;
    border-bottom-right-radius: 92px;
    border-bottom-left-radius: 92px;
  }

  #CCClubLeaderLanding_Subtext {
    font-size: 30px;
  }

  #CCClubLeaderLanding_Subtext {
    text-align: right;
    margin-right: 30px;
  }

  #CCClubLeaderLanding_Learn_More_Header {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  #CCMemberLanding_Register {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 30px;
    @include media("<=phone") {
      flex-direction: column;
    }
  }

  #MemberLanding_Login {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 30px;
    margin-top: 20px;
    @include media("<=phone") {
      flex-direction: column;
    }
  }

  #CCMemberLanding_FAQ {
    width: 100%;
    @include media("<=phone") {
      width: 100%;
    }
  }

  #CCMemberLanding_FAQ_Wrapper {
    display: flex;
    flex-direction: column;
  }

  #CCMemberLanding_FAQ_Text {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    column-gap: 40px;
    font-size: 14px;
    align-content: space-evenly;
  }

  #CCMemberLanding_FAQ_Text > div {
    text-align: left;
  }

  a {
    color: #0891e9;
    text-decoration: none;
    font-weight: bold;
  }
}

@import "../../../styles/common.scss";
@import "../../../styles/ignite/common.scss";

.request-club {
    display: flex;
    flex-direction: column;

    &__description {
        font-size: 24px;
        text-align: center;

        @include media("<=phone") {
            font-size: 20px;
        }
    }

    &__logo {
        max-width: 110px;
        max-height: 110px;
        margin-right: 1.8em;
    }

    &__name {
        font-size: 24px;
        @include media("<=phone") {
            font-size: 20px;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        border: 1px solid $softBorder;
        border-radius: .3em;
        padding: .6em;
        margin-top: 30px;
    }

    .request-button {
        align-self: flex-end;
        background: $primaryDark;
        color: $background;
        margin-top: 1.25em;
        margin-right:0;
        font-size: 18px;
        font-weight: bold;
        padding: 4px 20px;
        min-width: 200px;

        &:hover {
            background: $primaryAccent;
        }
    }
}

.not-right-club {
    text-align: center;
    background: $callout;
    padding: 2em 0;
    margin-top: 3.2em;
    line-height: 1.5;

    &__finder {
        font-size: 24px;
        text-decoration: underline;
        font-weight: 800;
        color: #000;
    }
}

.ignite-page-content {
    max-width: 730px;
    margin: 0 auto 50px;

    @include media("<=phone") {
        margin-top: $headerHeight_Mobile + $topCalloutHeight + $topAccentHeight + 45px;
    }
}

@import "../../../styles/ignite/common.scss";

.club-list {
    .count-showing {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .not-seeing-club {
        @include media(">phone") {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: left;
        }
        margin-bottom: 24px;
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items:flex-start;

        .cta-message {
            font-size: 20px;
            flex: 4.5;
        }
    }
    .cta {
        @include media("<=phone") {
            margin-top: 8px;
        }
        font-weight: 700;
        flex: 1;
    }
}

@import "../../styles/common.scss";

.CCButton {
  padding-top: .7em;
  padding-bottom: .7em;
  padding-left: 1em;
  padding-right: 1.3em;
  border-radius: 5px;
  box-shadow: none;
  border: none;
  font-weight: $semiBold;
  width: auto;
  font-size: $buttonTextSize;
  cursor: pointer;
  background-color: $background;
  color: $foreground;
  position: relative;
  text-transform: capitalize;

  &:disabled {
    opacity: 0.3;
  }
}

.CCButton_ChildContainer {
  display: flex;

  // Without this, svg icons next to text in the buttons look misaligned.
  align-items: center;
}

.CCButton:focus {
  outline: none;
}

.CCButton_UnfilledWithBorder {
  border: 1px solid $primaryAccentDark;
}

.CCButton_FilledPrimary {
  background-color: $primaryAccent;
  color: $primaryAccentForeground;
}

.CCButton_FilledPrimary a {
  color: $primaryAccentForeground;
  text-decoration: none;
}

.CCButton_UnfilledWithBorder {
  border: 1px solid $primaryAccent;
}

.CCButton_UnfilledWithWhiteBorder {
  background: transparent;
  border: 1px solid white;
  color: white;

  .svg {
    color: white;
  }
}

.CCButton_FilledSecondary {
  background-color: $secondaryAccent;
  color: $secondaryAccentForeground;
}

.CCButton_FilledSecondary a {
  color: $primaryAccentForeground;
  text-decoration: none;
}

.CCButton_Link {
  background-color: transparent;
  color: inherit;
  padding-top: .2em;
  padding-bottom: .2em;
  padding-left: .2em;
  padding-right: .2em;
  border-radius: 0;
}

.CCButton_GreyScale {
  background-color: #c4c4c4;
  font-weight: 400;
}

.CCButton_Rollover:not(:disabled) {
  -webkit-box-shadow: -1px 5px 5px 0px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -1px 5px 5px 0px rgba(0, 0, 0, 0.29);
  box-shadow: -.0px 5px 5px 0px rgba(0, 0, 0, 0.29);
}

.CCButton_FilledPrimary.CCButton_Rollover {
  background-color: $primaryAccentDark;
}

.CCButton_FilledSecondary.CCButton_Rollover {
  background-color: $secondaryAccentDark;
}

.CCButton_Link.CCButton_Rollover:not(:disabled) {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}


.CCButton_Saving {
  opacity: .25;
}


.CCButton_Progress {
  color: $foreground ! important;
  position: absolute;
  font-size: $buttonTextSize;
  font-weight: $bold;
  top: 15%;
  left: 40%;
  margin: 0 ! important;
}

.CCButton:disabled {
  opacity: .4;
  cursor: not-allowed;
  background-color: grey;

  &:hover {
    background-color: grey;
  }

  &.CCButton_Rollover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}



.CCButton_FilledClubDirectoryPrimary {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #0042AD;
  color: white;
  font-size: 16px;

  &:disabled {
    opacity: 1;
    background-color: #BCBCBC;
    &:hover {
      background-color: #BCBCBC;
    }
  }
}
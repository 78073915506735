@import "../../styles/common.scss";

.AcblBanner {
  margin-left: calc(-1 * (100vw - 850px) / 2);
  background-image: url("../../assets/acbl-banner-desktop.jpg");
  background-size: cover;
  width: 100vw;
  height: 75px;
  @include media("<phone") {
    margin-left: -25px;
  }
}

@import "../../../styles/ignite/common.scss";

.club-setup-page {
    padding-top: 20px;
    padding-bottom: 50px;

    .title {
        font-size: 32px;
        font-weight: 800;
        text-align: center;
        max-width: 450px;
        margin: 0 auto;
        white-space: pre-line;

        @include media("<=phone") {
            padding: 0 30px 10px;
            font-size: 24px;
        }
    }
}

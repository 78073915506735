@import "../../styles/common.scss";
@import "../../styles/colors.scss";

.LiabilityWaiverModal {
}

.LiabilityWaiverModal_Form {
  box-sizing: border-box;
  position: absolute;
  overflow: auto;
  outline: none;
  padding: 3em 5em;
  background: $background;

  @include media(">phone") {
    width: ($desktopPageContentWidth - 100) * 1px;
    max-height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include media("<=phone") {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 3em 2em;
  }
}

.LiabilityWaiverModal_PleaseNote {
}

.LiabilityWaiverModal_IAgree {
  margin-top: 1em;
}

.LiabilityWaiverModal_WaiverContent {
  margin-top: 1em;
  border: 1px solid $softBorder;
  padding: 1em;

  @include media(">phone") {
    min-height: 200px;
    max-height: 50vh;
    overflow-y: auto;
  }
  @include media("<=phone") {
    min-height: 200px;
    max-height: 50vh;
    overflow-y: auto;
  }
}

.LiabilityWaiverModal_Buttons {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media(">phone") {
    flex-direction: row;
  }

  @include media("<=phone") {
    flex-direction: column;
  }
}

.LiabilityWaiverModal_CountMeInButton, .LiabilityWaiverModal_CancelButton {
  margin: 0.5em;
}

.LiabilityWaiverModal_CountMeInButton {
}

.LiabilityWaiverModal_CancelButton {
}

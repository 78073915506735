@import "../../styles/common.scss";

.EventList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.EventList .BorderedRowItem > div {
  display: flex;
}

.EventList_Row
{
  flex-direction: row;
  border-bottom: 1px solid $darkCallout;
  justify-content: space-evenly;
  @include media("<=phone") {
    flex-direction: column;
    border: 1px solid $darkCallout;
    padding: 1em;
  }
}

.EventList_Name {
  flex-basis: 25%;
  flex-direction: column;
  @include media("<=phone") {
    font-weight: bold;
  }
}

.EventList_IsPaid {
  font-size: $formEntryTextSize;
  @include media("<=phone") {
    font-weight: normal;
  }
}

.EventList_IsCancelled {
  font-weight: $bold;
  color: $darkErrored;
  @include media("<=phone") {
    font-weight: normal;
  }
}

.EventList_Time {
  flex-basis: 25%;
  flex-direction: column;
  @include media("<=phone") {
    margin-top: .5em;
  }
}

  .EventList_StartsAtTime {
  }

  .EventList_EndsAtTime {
  }

  .EventList_StartsAtDate {
  }

.EventList_RSVPs {
  @include media("<=phone") {
    margin-top: .5em;
    justify-content: flex-start;
  }
}

.EventList_Buttons {
  justify-content: flex-end;
  @include media("<=phone") {
    margin-top: 1em;
    justify-content: center;
  }
}

.EventList_Buttons .CCButton{
  display: flex;
  height: 2.5em;
}

.EventList_PreviousAndNextButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .PreviousButton, .NextButton {
    margin: 1em;
  }
}

.EventList_Loading {
  margin: 2em auto;
}

@import "../../../styles/common.scss";

.CCLoading {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.CCLoading_DisplayNone {
    display: none;
}

@import "./VirtualClubsCommon.scss";

.VirtualClubsPage_Header {
    text-align: center;
    padding-top: 20px;

    @media (max-width: $mobileWidth) {
        font-size: 26px;
        padding-top: 14px;
    }
}

.VirtualClubsPage_Info {
    padding-bottom: 24px;

    @media (max-width: $mobileWidth) {

        /* full-width columns for mobile */
        &.equal-columns>div {
            flex-basis: 100%
        }
    }

    p {
        font-size: 22px;
        line-height: 30px;
        margin-top: 0;

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }

        @media (max-width: $tabletWidth) {
            font-size: 18px;
            line-height: 26px;
        }

        @media (max-width: $mobileWidth) {
            font-size: 14px;
            line-height: 22px;
        }
    }

}


.VirtualClubsLandingPage {
    img {
        width: 100%;
    }

    .equal-columns {
        column-gap: $gap-vw;
        row-gap: $gap-vw;

        @media (max-width: $mobileWidth) {
            column-gap: $mobilegap-vw;
            row-gap: $mobilegap-vw;
        }
    }


    .VirtualClubs_TermsContainer {
        @media (max-width: $mobileWidth) {
            padding-left: 14px;
            width: calc(100% + 20px);
        }
    }

    .VirtualClubs_Term {
        border-radius: 8px;
    }
}
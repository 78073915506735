@import "src/styles/common";

.RegistrationWizardStep {
  h2, h4 {
    text-align: center;
  }

  .MuiFormHelperText-root {
    font-size: 0.85rem;
  }
}
@import '../../styles/common.scss';

.ClubDetailCard {
    margin-bottom: 32px;
}

.ClubDetailCard_ClickableContainer {
    display: flex;
    flex-direction: row;
    height: 227px;
    cursor: pointer;
}

.ClubDetailCard_Content {
    flex: 1 1;
}

.ClubDetailCard_Image {
    max-width: 342px;
    width: 40%;
    min-width: 250px;
}

@media (max-width: $desktopPageWidth * 1px) {
    .ClubDetailCard_ClickableContainer {
        height: 360px;
        flex-direction: column-reverse;
    }

    .ClubDetailCard_Image {
        width: 100%;
        height: 128px;
        max-width: 100%;
    }
}

.ClubDetailCard_ActivityTypes {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    color: $clubDirectory_labelText;
    margin-bottom: 2px;

}

.ClubDetailCard_ClubName {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    color: $clubDirectory_dkBlue;
    margin-bottom: 8px;
}

.ClubDetailCard_Text {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    color: $clubDirectory_paragraphText;
}

.ClubDetailCard_Text--Host {
    margin-bottom: 8px;
}

.ClubDetailCard_Text--Location {
    margin-bottom: 16px;
}

.ClubDetailCard_Text--Description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@import "../../styles/ignite/common.scss";

.direct-to-consumer-landing-page {
    margin-top: 24px;
    &-footer {
        background: #ebebeb;
        padding: 23px 20px;
        font-weight: 600;
        text-align: center;
        font-size: 18px;
    }
    &-top-wrapper {
        background: #ebebeb;
        padding: 24px 40px;
        font-weight: 400;
        text-align: center;
        font-size: 18px;
        position: relative;

        .icon {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }
    &-top {
        background-color: #EBEBEB;
        max-width: 580px;
        margin: 0 auto;

        &__title {
            text-align: center;
            margin: 0 0 25px 0;
            white-space: pre-line;

            @include media("<=phone") {
                font-size: 18px;
                padding: 0 20px;
            }
        }

        &__subtitle {
            margin-top: 20px;
        }

        &__link {
            color: #000;
            margin-left: 10px;
            font-weight: 600;
        }
    }
    .icon {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
    .inner-part {
        max-width: 892px;
        // margin: 0 auto;
        min-height: 15em;
    }

    @include media("<=phone") {
        margin: 105px auto 20px;
    }

    .title {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 6px;
        @include media("<=phone") {
            font-size: 24px;
        }
    }

    .statement {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 24px;
        color: #0177C0;
        @include media("<=phone") {
            font-size: 24px;
        }
    }

    .carousel {
        border-radius: 8px;
        .dot {
          border: 1px solid #aaa;
          opacity: 1;
          box-shadow: none;
          &.selected {
            border-color: black;
            background: black;
          }
        }
        .control-dots {
          top: 0;
          bottom: initial;
        }
        .image {
            width: 100%;
            border-radius: 8px;
          }
      }
    .section-header {
        font-weight: 700;
        font-size: 32px;
        margin-bottom: 24px;
    }

    .section-text {
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 24px;
        line-height: 29.03px;
    }

    .button{
        border-radius: 4px;
        font-weight: 800;
        font-size: 16px;
        line-height: 26px;
    }

    .section-incentive-header {
        font-family: Open Sans;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
        color: #0177C0;
        margin-bottom: 24px;
    }

    .section-incentive-text {
        font-family: Open Sans;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
    }

    .main-content {
        display: flex;
        text-align: right;

        &__text-wrapper {
            justify-content: flex-end;
            max-width: 300px;
            margin-right: 24px;

            @include media("<=phone") {
                text-align: center;
                margin: 0 auto;
            }

            .subtitle {
                font-size: 26px;
                font-weight: 400;
            }

            .description {
                font-size: 16px;
                font-weight: 600;
                margin: 20px 0 20px;
            }

            .help-text {
                font-size: 24px;
                font-weight: 800;
                margin-bottom: 10px;
                margin-top: 30px;

                @include media("<=phone") {
                    margin-top: 50px;
                }
            }

            .button {
                margin-top: 28px;
                font-weight: 800;
                margin-bottom: 15px;
                background-color: $primaryAccent;

                .icon {
                    width: 15px;
                }
            }

            .already-member {
                font-size: 16px;
                font-weight: 400;

                &__link {
                    text-decoration: none;
                    color: $primaryAccent;
                    font-weight: 600;
                }
            }
        }

        &__image-wrapper {
            .image {
                width: 260px;
                height: 260px;
                border-radius: 8px 75px 75px 75px;
            }

            @include media("<=phone") {
                display: flex;
                justify-content: center;
            }
        }

        @include media("<=phone") {
            flex-direction: column;
        }

    }

    .footer {
        margin-top: 60px;

        .title {
            font-size: 22px;
            font-weight: bold;
            @include media("<=phone") {
                font-size: 18px;
            }
        }

        .footer-list {
            padding: 0px;
            display: flex;
            justify-content: space-between;

            .footer-item {
                font-size: 14px;
                max-width: 250px;
                @include media("<=phone") {
                    padding: 0 4px;
                }
            }

            @include media("<=phone") {
                padding: 0;
            }
        }

        @include media("<=phone") {
            margin-top: 60px;
            margin-bottom: 30px;
        }
    }

    .promotion-logos {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

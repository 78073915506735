@import "../../../styles/common.scss";

$primaryDark: #0177C0;

.check-eligibility {
    max-width: 850px;
    margin: 0 -25px;
    min-height: 15em;

    &__title {
        font-size: 32px;
        font-weight: 800;
        text-align: center;
        max-width: 630px;
        margin: 40px auto 35px;
        padding: 0 15px;

        @include media("<=phone") {
            font-size: 24px;
        }
    }

    &-content {
        border: 1px solid $softBorder;
        border-radius: 8px;
        margin: 10px 0 25px;
        max-height: 600px;
        overflow-y: auto;
    }

    .payer-info {
        display: flex;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid $softBorder;

        .main-info {
            padding-left: 30px;

            &__name {
                font-size: 28px;
                font-weight: 800;

                @include media("<=phone") {
                    font-size: 20px;
                }
            }

            &__description {
                font-size: 20px;
                max-width: 450px;
            }

            @include media("<=phone") {
                padding: 0 15px;
            }
        }

        .logo-wrapper {
            padding-right: 15px;
            max-width: 200px;
            min-width: 150px;

            .logo{
                max-width: 100%;
            }
        }

        @include media("<=phone") {
            flex-direction: column;
            text-align: center;
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;

        .button {
            display: flex;
            justify-content: space-between;
            background: $primaryDark;
            color: $background;
            font-size: 16px;
            font-weight: bold;
            padding: 4px 13px;
            height: 40px;
            width: 210px;

            &.disabled {
                background-color: $softBorder;
            }

            &__icon {
                margin-left: 10px;
                width: 20px;
                height: 18px;
            }

            .rotate-icon {
                transform: rotate(60deg);
            }

            &:hover {
                background: $primaryAccent;
            }
        }

        @include media("<=phone") {
            flex-direction: column-reverse;
            align-items: center;
            gap: 10px;
            margin-bottom: 40px;
        }
    }

    .form {
        display: flex;
        justify-content: space-between;
        padding: 40px;

        @include media("<=phone") {
            &__fields{
                margin: 0 auto;
            }
            padding: 20px;
        }

        &__field {
            width: 330px;
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: 1fr min-content;
            grid-template-rows: min-content min-content;

            .label-error {
                color: #D32F2F;
                font-weight: 700;
            }
        }

        .card-wrapper {
            padding-top: 10px;
            width: 400px;
            @include media("<=phone") {
                max-width: 400px;
                width: 100%;
                margin: 0 auto;
            }

            .card-img {
                width: 100%;
            }
        }

        .date-of-birth-input{
            padding: 10.5px 14px;
        }

        @include media("<=phone") {
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
    }

    @include media("<=phone") {
        margin-top: 120px;
    }

    @include media("<=sm") {
        margin: 120px -25px 0;
    }
}

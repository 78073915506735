@import "../../styles/common.scss";

.AcblRegistrationComplete {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  .AcblRegistrationComplete__Line1 {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    text-shadow: 0 0.25px, 0.25px 0, 0.25px 0.25px;
  }
  .AcblRegistrationComplete__Line2 {
    margin-bottom: 1em;
    font-weight: 800;
    text-align: center;
    width: 400px;
  }
  .AcblRegistrationComplete__Button {
    background: #1e2452;
    font-size: 14px;
    letter-spacing: 0.75px;
    padding: 12px 24px;
    text-transform: uppercase;
    margin-bottom: 2em;
  }
  .AcblRegistrationComplete_Button_Icon {
    margin-left: 6px;
    width: 5px;
  }
}

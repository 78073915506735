@import "../../styles/common.scss";

.CustomEnrollmentLandingPage {
  $semiTablet: 855px;
  $tablet: 650px;
  $phone: 500px;
  padding-bottom: 50px;
  .CustomEnrollmentLandingPage_Banner {
    width: 100vw;
    margin-left: calc(-1 * (100vw - 850px) / 2);
    @include media("<phone") {
      margin-left: -25px;
    }
  }
  .CustomEnrollmentLandingPage_Banner_Top {
    display: flex;
    height: 96px;
    align-items: center;
    background: #001C58;
    padding-left: calc((100vw - 850px) / 2);
    padding-top: 4px;
    padding-bottom: 4px;
    @include media("<phone") {
      padding-left: 25px;
    }
  }
  .CustomEnrollmentLandingPage_Banner_Top_Logo {
    width: 80px;
    height: 60px;
  }
  .CustomEnrollmentLandingPage_TopSection {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 50px 0 70px 0;
    @media (max-width: $tablet) {
      flex-wrap: wrap-reverse;
      justify-content: center;
      text-align: center;
      margin: 0;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_Left,
  .CustomEnrollmentLandingPage_TopSection_Right {
    flex: 1 0 46%;
    @media (max-width: $tablet) {
      width: 100%;
      min-width: 330px;
    }
    .landingVideoContainer {
      position: relative;
      width: 100%;
      @media (max-width: $tablet) {
        top: 24px;
      }
    }
  }
  .CustomEnrollmentLandingPage_TopSection_Left {
    margin: 30px 0;
    @media (max-width: $tablet) {
      max-width: 415px;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_LeftText {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    @media (max-width: $tablet) {
      text-align: center;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_LeftButton {
    background: #006FD6;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    width: 240px;
    .CCButton_ChildContainer {
      display: block;
      line-height: 16px;
      height: 16px;
      padding: 5px 0;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_LeftButtonIcon {
    margin-left: 6px;
    width: 5px;
    height: 16px;
    vertical-align: text-top;
    @media (max-width: $tablet) {
      width: 7px;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_LeftLogoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 220px;
    margin-top: 30px;
    @media (max-width: $tablet) {
      flex-direction: column;
      margin: 30px auto 0 auto;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_LeftLogo {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
  }
  .CustomEnrollmentLandingPage_TopSection_LeftLogoPlus {
    margin: 0 10px;
    width: 15px;
    @media (max-width: $tablet) {
      margin: 10px 0;
      width: 15px;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_LeftLogo_ClubAssociationLogo {
    max-width: 122px;
    height: 60px;
  }
  .CustomEnrollmentLandingPage_TopSection_LeftLogo_E3HLogo {
    width: 150px;
    height: 40px;
    @media (max-width: $tablet) {
      height: 30px;
      width: auto;
      padding-left: 5px;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_Right {
    position: relative;
    overflow: hidden;
    padding: 10px;
    @media (max-width: $tablet) {
      overflow: visible;
      padding: 0;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_Right_ImageContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    @media (max-width: $tablet) {
      display: none;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_Right_Image_Desktop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .CustomEnrollmentLandingPage_TopSection_Right_BackgroundColor {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 100%;
    width: 100%;
    background-color: #001C58;
    @media (max-width: $tablet) {
      display: none;
    }
  }
  .CustomEnrollmentLandingPage_TopSection_Right_Image_Mobile {
    display: none;
    background-repeat: no-repeat;
    background-position: left;
    @media (max-width: $tablet) {
      display: block;
      position: relative;
      height: auto;
      left: -25px;
      width: calc(100% + 50px);
      bottom: 0px;
    }
  }
  .CustomEnrollmentLandingPage_MiddleSection_Header {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }
  .CustomEnrollmentLandingPage_MiddleSection_Subheader {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .CustomEnrollmentLandingPage_MiddleSection_InfoContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    p {
      max-width: 225px;
      margin: auto;
      text-align: center;
    }
  }
  .CustomEnrollmentLandingPage_MiddleSection_Info {
    flex: 1 0 200px;
    margin: 30px;
  }
  .CustomEnrollmentLandingPage_MiddleSection_InfoIconContainer {
    text-align: center;
    height: 50px;
    padding: 5px;
  }
  .CustomEnrollmentLandingPage_MiddleSection_InfoIcon {
    width: 50px;
    height: 50px;
  }
  .CustomEnrollmentLandingPage_MiddleSection_FaqLink {
    text-align: center;
    color: #0177C0;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    font-weight: 700;
    padding-left: 12px;
  }
  .CustomEnrollmentLandingPage_MiddleSection_FaqLink_Icon {
    height: 24px;
    vertical-align: text-top;
  }
  .CustomEnrollmentLandingPage_BottomSection {
    background-color: #F5F5F5;
    margin-top: 70px;
    padding: 30px;
    word-wrap: break-word;
  }
}

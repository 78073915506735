@import "../../styles/common.scss";

@include media(">phone") {
  .NavigationDesktop {
    display: flex;
  }

  .NavigationDesktop_Container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 760px;
  }

  .NavigationDesktop_Container.LoggedOut {
    width: 400px;
    align-items: center;
    .NavigationElement {
      min-width: 0;
    }
  }

  /* desktop NavigationDropdownElement menu */
  .NavigationDesktopDropdown {
    margin-top: -11px;
    margin-left: -1px;
  }

  .NavigationDesktopDropdown .MuiPaper-root {
    border-radius: 0;
  }

  .NavigationDesktopDropdown .MuiList-root {
    padding: 0;
  }

  .NavigationDesktopDropdown .NavigationElement {
    padding-left: 9px;
    padding-right: 9px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, .6);
    border-bottom: 1px #EBEBEB solid;
    justify-content: left;

    &:last-child {
      border-bottom: none;
    }
  }

  .NavigationDesktopDropdown .NavigationElement_Current {
    text-decoration: none;
    font-weight: 900;
  }

  .NavigationDesktopDropdown .NavigationElement_Over {
    background: #E3F2FF;
    color: #0177C0;
  }


}
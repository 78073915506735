.AcblPromptContinue {
  padding-top: 30px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  .AcblPromptContinue_Prompt {
    font-weight: 800;
    text-align: center;
    span {
      cursor: pointer;
      color: #0891e9;
      font-weight: bold;
    }
  }
}

.field-indicator {
  color: #bababa;

  &-wrapper {
    display: inline-flex;
    padding-top: 10px;
    justify-content: center;
    padding-left: 10px;
    padding-right: 0;
  }

  &.validated {
    color: #10BC41;
  }

  &.hidden {
    visibility: hidden;
  }

  &.error {
    color: red;
  }
}

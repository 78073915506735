@import "../../../styles/ignite/common.scss";

.add-club {
    .form-wrapper {
        padding: 40px 72px 40px 72px; 
        border: 1px solid #C4C4C4; 
        border-radius: 4;

        @include media("<=phone") {
            padding: 24px;
        }
    }
    .club-added {
        display: flex;
        justify-content: center;
        padding-top: 24px;
        .wrapper{
            width: 75%;
            @include media("<=600px") {
                width: 100%;
            }
            .title {
                font-weight: 600;
                font-size: 24px;
                color: #0177c0;
                margin-bottom: 24px;
            }
            .button-wrapper {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                @include media("<=600px") {
                    flex-direction: column;
                    row-gap: 24px;
                }
                .button {
                    font-weight: 700;
                }
            }
        }
    }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

    /* weight definitions */
$bold: 700;
$semiBold: 600;

/* font sizes that match comps */
$navigationTextSize: 18px;
$largeTextSize: 18px;
$textSize: 16px;
$smallTextSize: 14px;
$smallerTextSize: 12px;
$largeHeadlineTextSize: 36px;
$mediumHeadlineTextSize: 24px;
$smallHeadlineTextSize: 18px;
$bodyTextSize: 16px;
$buttonTextSize: 18px;
$formEntryTextSize: 14px;
$errorTextSize: 14px;
$formInstructionsTextSize: 14px;



/* ajl suggestions
$navigationTextSize: 20px;
$largeHeadlineTextSize: 40px;
$mediumHeadlineTextSize: 28px;
$smallHeadlineTextSize: 20px;
$bodyTextSize: 18px;
$buttonTextSize: 20px;
$formEntryTextSize: 16px;
$errorTextSize: 16px;
*/


html {
  font-family: "Open Sans", sans-serif;
  font-size: $bodyTextSize;
}


@import "../../../styles/common.scss";

$primaryDark: #0177C0;

.club-setup-form {
    width: 650px;
    margin: 0 auto;
    padding: 20px 0 60px;

    &__field-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 15px 0 30px;

        .club-setup-form__select {
            width: 47%;
        }

        .club-setup-form__field {
            width: 47% !important;
        }
    }

    &__field {
        width: 100%;
        margin-bottom: 5px !important;

        &.short {
            width: auto;
        }

        &_error {
            font-weight: 700 !important;
            color: #D32F2F !important;
            font-size: 14px !important;
        }
    }

    &__description {
        color: #555;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 30px;
        overflow-wrap: break-word
    }

    &__select {
        width: 100%;
        margin-bottom: 5px !important;
    }

    .subtitle {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 15px;
        margin-top: 30px;
    }

    &__terms-of-use-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 30px;

        label {
            padding: 9px;
            font-size: 16px;
        }

        .terms-of-use-error {
            color: #D32F2F;
            font-size: 14px;
            margin-top: 10px;
            font-weight: 700;
            padding-left: 10px;
        }

        @include media("<=phone") {
            text-align: center;
            margin-bottom: 40px;
        }
    }

    &__checkbox {
        padding: 0 !important;
    }

    &__checkbox-title {
        color: #000;
    }

    &__terms-of-use-link {
        text-decoration: underline;
        color: #000;
        font-weight: bold;
    }

    &__button-wrapper {
        display: flex;
        justify-content: center;
    }

    &__button {
        background-color: $primaryDark !important;
        color: #fff !important;
    }

    &__button-label {
        font-size: 16px;
        font-weight: 800;
    }

    &__button-disabled {
        background-color: $disabled !important;
    }

    &__button-icon {
        width: 15px !important;
        margin-left: 15px;
    }

    .radio-wrapper {
        display: flex;

        .radio-item {
            display: flex;
            align-items: center;
            margin-right: 35px;
        }
    }

    @include media("<=phone") {
        width: auto;

        .radio-wrapper .radio-item {
            margin-right: 15px;
        }
    }
}

@import "../../styles/common.scss";


.Header {
  border-bottom: $headerBorderSize solid $softBorder;
  @include media("<=phone") {
    height: $headerHeight_Mobile;
    background-color: $background;
    position:fixed;
    width: 100%;
    top: $topCalloutHeight + $topAccentHeight;
    z-index: 500;
  }
}

.HeaderContentContainer {
  width: 100%;
  @include media("<=phone") {
    display: none;
  }
  &.HeaderBorderBottom {
    border-bottom: $headerBorderSize solid $softBorder;
  }
}

.HeaderContent {
  height: $headerHeight;
  width: $headerContentWidth;
  position:relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.HeaderContentMobile {
  display: flex;
  justify-content: center;
  width: auto;
  margin-left: $headerContentMobileMarginLeft;
  margin-right: $headerContentMobileMarginRight;
  margin-top: $headerContentMobileMarginTop;
  @include media(">phone") {
    display: none;
  }
}

.Header-Right {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  .PayerLogo {
    height: 30px;
    align-self: center;
  }
}

.HeaderNavigation {
  width: $headerContentWidth;
  display: flex;
  justify-content: center;
  margin: 10px auto;
  @include media("<=phone") {
    display: none;
  }
}

.MobileContextualNav {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  font-weight: $bold;
  /* push down to align with logo */
  margin-top: 3px;
  /* push left to compenate for off-center illusion caused by TM symbol */
  position: relative;
  left: -5px;
}

.Header {
  .LogoLink {
    display: flex;
    align-items: center;
  }
  .HeaderLogo {
    display: flex;
    align-items: center;
    margin-top: -8px;
    @include media("<=phone") {
      margin-top: 0;
    }
  }
  .Logo {
    width: $logoWidth;
    height: 40px;
    display: flex;
    margin-right: 25px;
    flex-direction: row;
    flex: 0 0 auto;
    @include media("<=phone") {
      width: $logoWidth_Mobile;
    }
    &.aetna {
      height: 25px;
      width: 125px;
    }
    &.allina-health {
      width: 110px;
      height: 40px;
      @include media("<=phone") {
        height: 36px;
        width: 100px;
      }
    }
    &.innovation-health {
      width: 130px;
      height: 45px;
      @include media("<=phone") {
        height: 36px;
        width: 100px;
      }
    }
  }
  .AcblLogoPlus {
    margin: 0 10px;
    width: 15px;
  }
  .AcblLogo {
    max-width: $logoWidth;
    height: 45px;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    @include media("<=phone") {
      max-width: $logoWidth_Mobile;
    }
  }
  .ClubAssociationLogoPlus {
    margin: 0 10px;
    width: 15px;
  }
  .ClubAssociationLogo {
    max-width: $logoWidth;
    height: 60px;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    @include media("<=phone") {
      max-width: $logoWidth_Mobile;
      height: 45px;
    }
  }
}

.ViewSwitcher {
  margin-left: 35px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  span {
    vertical-align: middle;
  }
  .ViewSwitcherMode_Mobile {
    margin: 0 25px;
    color: #0177C0;
    @include media(">phone") {
      display: none;
    }
  }
  .ViewSwitcherMode {
    @include media("<=phone") {
      display: none;
    }
  }
  .ViewSwitcherLink {
    color: #0177C0;
    text-transform: uppercase;
    @include media("<=phone") {
      display: none;
    }
  }
  .icon {
    margin-right: 7px;
    vertical-align: middle;
  }

  @include media("<=sm") {
    margin-left: 0;
  }
}

@import "../../styles/common.scss";
.CCPlayButton_PlayIcon {
  margin-right: .5em;
  color: $secondaryAccent;
}

.CCPlayButton_TextContainer {
  display: flex;
  white-space:nowrap;
}

.CCButton_FilledPrimary .CCPlayButton_PlayIcon {
  color: $primaryAccentForeground;
}

.CCButton_FilledSecondary .CCPlayButton_PlayIcon {
  color: $secondaryAccentForeground;
}

.CCButton_UnfilledWithBorder .CCPlayButton_PlayIcon {
  color: $primaryAccent;
}

.CCPlayButton_ChildContainer {
  display: flex;
  flex-direction:  row;
  align-items: center;
}

.CCButton_UnfilledWithWhiteBorder .CCPlayButton_PlayIcon {
  color: white;
}

@import "../../styles/common.scss";

$mobile: 500px;
$tablet: 800px;

.OptumMemberLandingPage {
  .OptumMemberLandingPage_Title {
    font-size: 38px;
    line-height: 50px;
    text-align: center;
    padding-top: 32px;
  }

  .OptumMemberLandingPage_BannerImage {
    max-width: 100%;
    border-radius: 8px;
  }
  .OptumMemberLandingPage_BannerImageDesktop {
    @include media("<=#{$mobile}") {
      display: none;
    }
  }
  .OptumMemberLandingPage_BannerImageMobile {
    @include media(">#{$mobile}") {
      display: none;
    }
  }

  .OptumMemberLandingPage_Section1_Panel {
    display: flex;
    margin-top: 24px;
    @include media("<=#{$mobile}") {
      flex-direction: column;
    }
  }

  .OptumMemberLandingPage_SignUpSubsection {
    flex-basis: calc(50% - 9px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .OptumMemberLandingPage_SignUpSubsection_AboveText {
    color: #0177C0;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .OptumMemberLandingPage_SignUpSubsection_ButtonPanel {
    margin: 20px 0;
    @include media("<=#{$tablet}") {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .OptumMemberLandingPage_SignUpSubsection_SignUpButton {
    font-family: "Open Sans", sans-serif;
    &:not(:disabled) {
      background: #0288D1;
      &:hover {
        background: #015285;
      }
    }
    font-size: 16px;
    letter-spacing: 0.46px;
    font-weight: 800;
    @include media("<=#{$tablet}") {
      text-align: center;
      margin-bottom: 24px;
      .CCButton_ChildContainer {
        justify-content: center;
      }
    }
  }
  .OptumMemberLandingPage_SignUpSubsection_MoreInfoButton {
    font-family: "Open Sans", sans-serif;
    margin-left: 20px;
    color: #0177C0;
    font-size: 16px;
    letter-spacing: 0.46px;
    font-weight: 800;
    @include media("<=#{$tablet}") {
      margin-left: 0;
      .CCButton_ChildContainer {
        justify-content: center;
      }
    }
  }
  .OptumMemberLandingPage_SignUpSubsection_Below {
    @include media("<=#{$tablet}") {
      display: flex;
      flex-direction: column;
    }
    @include media("<=#{$mobile}") {
      display: block;
    }
  }
  .OptumMemberLandingPage_SignUpSubsection_Below_Link {
    margin-left: 8px;
    @include media("<=#{$tablet}") {
      margin-left: 0;
    }
    @include media("<=#{$mobile}") {
      margin-left: 8px;
    }
  }

  .OptumMemberLandingPage_Section1_DividerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 18px;
    @include media("<=#{$mobile}") {
      width: 100%;
      margin-top: 20px;
    }
  }
  .OptumMemberLandingPage_Section1_Divider {
    border: 1px solid rgba(26, 98, 199, 0.2);
    width: 0;
    height: calc(100% - 63px);
    @include media("<=#{$mobile}") {
      width: 100%;
      height: 0;
    }
  }
  .OptumMemberLandingPage_Section1_DividerIcon {
    color: rgba(26, 98, 199, 0.1);
    font-size: 60px;
    margin-top: 3px;
    @include media("<=#{$mobile}") {
      display: none;
    }
  }

  .OptumMemberLandingPage_GymSubsection {
    flex-basis: calc(50% - 9px);
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media("<=#{$mobile}") {
      margin: 40px 0;
    }
  }
  .OptumMemberLandingPage_GymSubsection_AboveText {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #0177C0;
    margin-bottom: 16px;
  }
  .OptumMemberLandingPage_GymSubsection_BelowText {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
  }
  .OptumMemberLandingPage_GymSubsection_Buttons {
    display: inline-flex;
    flex-direction: column;
    @include media("<=#{$mobile}") {
      display: flex;
      width: 100%;
    }
  }
  .OptumMemberLandingPage_GymSubsection_OnePassLink {
    margin-bottom: 24px;
    font-family: "Open Sans", sans-serif;
    background: #0288D1;
    font-weight: 800;
    .CCButton_ChildContainer {
      justify-content: center;
    }
    &:hover {
      background: #015285;
    }
  }
  .OptumMemberLandingPage_GymSubsection_RenewActiveLink {
    font-family: "Open Sans", sans-serif;
    background: #0288D1;
    font-weight: 800;
    .CCButton_ChildContainer {
      justify-content: center;
    }
    &:hover {
      background: #015285;
    }
  }

  .OptumMemberLandingPage_Section2 {
    margin-top: 60px;
    @include media("<=#{$mobile}") {
      margin-top: 30px;
    }
  }
  .OptumMemberLandingPage_Section2_Title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #0177C0;
    @include media("<=#{$mobile}") {
      text-align: center;
    }
  }
  .OptumMemberLandingPage_TestimonyPanel {
    .dot {
      border: 1px solid #aaa;
      opacity: 1;
      box-shadow: none;
      &.selected {
        border-color: black;
        background: black;
      }
    }
    .control-dots {
      top: 0;
      bottom: initial;
    }
  }
  .OptumMemberLandingPage_Testimony_Image {
    width: 100%;
    border-radius: 8px;
  }
  .OptumMemberLandingPage_Testimony_Text {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    @include media("<=#{$mobile}") {
      margin-top: 20px;
    }
  }

  .OptumMemberLandingPage_Section3 {
    margin-top: 70px;
    @include media("<=#{$tablet}") {
      margin-top: 30px;
    }
  }
  .OptumMemberLandingPage_Section3_Title {
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    text-align: center;
  }
  .OptumMemberLandingPage_Section3_Panel {
    display: flex;
    max-width: 100%;
    @include media("<=#{$tablet}") {
      flex-direction: column-reverse;
    }
  }
  .OptumMemberLandingPage_Section3_RightColumn,
  .OptumMemberLandingPage_Section3_LeftColumn {
    width: 50%;
    @include media("<=#{$tablet}") {
      width: 100%;
    }
  }
  .OptumMemberLandingPage_Section3_LeftColumn {
    padding-right: 20px;
    box-sizing: border-box;
  }
  .OptumMemberLandingPage_Section3_Subtitle {
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    line-height: 33px;
    color: #0177C0;
    @include media("<=#{$tablet}") {
      margin-top: 20px;
    }
  }
  .OptumMemberLandingPage_Section3_Content {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
    margin-top: 24px;
  }
  .OptumMemberLandingPage_Section3_Image {
    width: 100%;
    border-radius: 8px;
  }
  .OptumMemberLandingPage_Section3_ImageDesktop {
    @include media("<=#{$tablet}") {
      display: none;
    }
  }
  .OptumMemberLandingPage_Section3_ImageMobile {
    @include media(">#{$tablet}") {
      display: none;
    }
  }

  .OptumMemberLandingPage_Section4 {
    margin-top: 60px;
    @include media("<=#{$tablet}") {
      margin-top: 30px;
    }
  }
  .OptumMemberLandingPage_Section4_Title {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 20px;
  }
  .OptumMemberLandingPage_Section4_Steps {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, min-content);
    grid-column-gap: 10px;
    @include media("<=#{$tablet}") {
      display: flex;
      flex-direction: column;
    }
  }
  .OptumMemberLandingPage_Section4_Step_Label {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
    text-align: center;
    color: #0177C0;
    margin-bottom: 10px;
    @include media("<=#{$tablet}") {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  .OptumMemberLandingPage_Section4_Step_Title {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
  }
  .OptumMemberLandingPage_Section4_Step_Content {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  .OptumMemberLandingPage_Section5 {
    margin-top: 60px;
    @include media("<=#{$mobile}") {
      margin-top: 30px;
    }
  }
  .OptumMemberLandingPage_Section5_Title {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 20px;
  }
  .OptumMemberLandingPage_Section5_Subtitle {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #0177C0;
    margin-bottom: 24px;
    white-space: pre-wrap;
  }
  .OptumMemberLandingPage_Section5_Content {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #2F2F2F;
    margin-bottom: 20px;
  }
  .OptumMemberLandingPage_Section5_Clubs {
    display: flex;
    @include media("<=#{$mobile}") {
      flex-direction: column;
    }
  }
  .OptumMemberLandingPage_Club {
    width: calc(33% - 7px);
    &:not(:first-child) {
      margin-left: 20px;
    }
    @include media("<=#{$mobile}") {
      width: 100%;
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
  .OptumMemberLandingPage_Club_Image {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 8px;
    @include media("<=#{$mobile}") {
      margin-bottom: 10px;
    }
  }
  .OptumMemberLandingPage_Club_Title {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
  }
  .OptumMemberLandingPage_Club_Content {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  .OptumMemberLandingPage_Section6 {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .OptumMemberLandingPage_SignUpButton {
    margin-bottom: 30px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    &:not(:disabled) {
      background: #0288D1;
      &:hover {
        background: #015285;
      }
    }
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    padding: 18px 76px;
  }
  .OptumMemberLandingPage_FAQButton {
    display: flex;
    align-items: center;
  }
  .OptumMemberLandingPage_FAQButton_Icon {
    font-size: 20px;
    margin-left: 3.5px;
  }

  a {
    color: #0891e9;
    text-decoration: none;
    font-weight: bold;
  }
}

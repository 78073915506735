@import "../../styles/common.scss";
@import "../../styles/colors.scss";

$hamburgerWidth: 1.75em;
$hamburgerHeight: 0.8em;
$hamburgerMarginRight: 0.3em;
$hamburgerBorderSize: 0.2em;
$hamburgerBorderColor: $foreground;
$hamburgerBeforeTop: 0.3em;
$hamburgerBeforeLeft: 0;
$hamburgerBeforeWidth: 100%;

.HamburgerOuterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HamburgerContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.Hamburger {
  position: relative;
  display: inline-block;
  width: $hamburgerWidth;
  height: $hamburgerHeight;
  margin-right: $hamburgerMarginRight;
  border-top: $hamburgerBorderSize solid $hamburgerBorderColor;
  border-bottom: $hamburgerBorderSize solid $hamburgerBorderColor;
}

.Hamburger:before {
  content: "";
  position: absolute;
  top: $hamburgerBeforeTop;
  left: $hamburgerBeforeLeft;
  width: $hamburgerBeforeWidth;
  border-top: $hamburgerBorderSize solid $hamburgerBorderColor;
}

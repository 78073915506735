@import "../../styles/common.scss";

.PasswordResetConfirmForm {
  flex-basis: auto;
}

.PasswordResetConfirmForm_Result
{
  color: $errored;
  margin-bottom: 1.5em;
}

.PasswordResetConfirmPage_Instructions
{
  margin-bottom: 1em;
}


.PasswordResetConfirmForm_ButtonContainer
{
  display:flex;
  align-items: center;
  width: 16em;
  justify-content: space-between;
  flex-direction: row;
  @include media("<=phone") {
    align-items: start;
    gap: 1em;
    flex-direction: column-reverse;
  }
}

.PasswordResetConfirmForm_FieldContainer
{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include media("<=phone") {
    flex-direction: column;
  }
}

.PasswordResetConfirmForm_ButtonContainer
{
  margin-top:  1em;
}

.PasswordResetConfirmForm_Password
{
  display:flex;
  margin-right: .5em;
  width: 16em;
  .MuiFormControl-root {
    width: 100%;
  }
}

.PasswordResetConfirmForm_ConfirmPassword
{
  display:flex;
  margin-right: 1em;
  width: 16em;
  @include media("<=phone") {
    margin-top: 1em;
  }
  .MuiFormControl-root {
    width: 100%;
  }
}

.PasswordResetConfirmForm_BackToLoginButton
{
  font-weight: bolder;
  font-size: 18px;
}

.PasswordResetConfirmForm_PasswordResetConfirmButton
{
  @include media("<=phone") {
    width: 15em;
    margin-top: 1em;
  }
}

.PasswordResetConfirmForm_LoginButton
{
  margin-left: .5em;
  margin-right: .5em;
  @include media("<=phone") {
    margin-left: 0;
    width: 15em;
    margin-top: 1em;
  }
}
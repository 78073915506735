@import "../../styles/common.scss";

.Collapsible_FAQ_Wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;

  .Collapsible_FAQ_Question {
    display: inline-flex;
    flex-direction: row;
    cursor: pointer;
    width: 100%;
  }

  .Collapsible_FAQ_Question_Text {
    display: inline-flex;
    flex: 1;
    align-items: center;
  }

  .Collapsible_FAQ_Answer {
    display: none;
    padding: 20px;
    padding-top: 0;
  }

  .Collapsible_FAQ_Answer div {
    margin-top: 1em;
  }

  a {
    color: $primaryAccent;
    text-decoration: none;
    font-weight: $bold;
  }

  .Collapsible_FAQ_Answer.open {
    display: block;
  }
}

.Collapsible_FAQ_Wrapper:first-of-type {
  border-top: 1px solid black;
}


@import "../../styles/common.scss";
@import "../../styles/colors.scss";

$staticLinksWidth: 200px;
$staticLinksMarginLeft: 110px;
$staticLinksFontWeight: $bold;
$staticLinksPaddingLeft_Mobile: 10px;
$staticLinksPaddingTop_Mobile: 25px;
$staticLinksWidth_Mobile: 100%;
$staticLinksBackgroundColor_Mobile: rgba(240, 245, 248, 1);
$staticLinksHeight_Mobile: 145px;

.StaticLinks {
  display: flex;
  flex-direction: column;
  width: $staticLinksWidth;
  margin-left: $staticLinksMarginLeft;
  font-weight: $staticLinksFontWeight;
  @include media("<=phone") {
    padding-left: $pageMarginHorizontal_Mobile * 1px;
    padding-top: $staticLinksPaddingTop_Mobile;
    width: $staticLinksWidth_Mobile;
    margin-left: 0;
    background-color: $staticLinksBackgroundColor_Mobile;
    height: $staticLinksHeight_Mobile;
  }
}

.StaticLinks a {
  margin-bottom: 5px;
}
@import "../../styles/common.scss";

.PasswordResetPage {
  flex-basis: auto;
  max-width: 800px;
  @include media("<=phone") {
    max-width: 90%;
  }
}

.PasswordResetPage_Instructions {
  max-width: 600px;
  margin-bottom: 1em;
}


@import "../../../styles/ignite/common.scss";

.leaderboard {
    width: 250px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-top: 96px;

    &__title {
        display: flex;
        padding:10px 20px;
        text-transform: uppercase;
        font-weight: 800;
        align-items: center;
        border: 1px solid $softBorder;
        border-bottom: 1px solid $primaryDark;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        margin-top: 5px;
        flex-wrap:wrap;
        justify-content: center;
        .icon {
            color: $primaryDark;
            font-size: 70px;
            padding-bottom: 10px;
        }
    }
}

.leaderboard-list {
    height: 410px;
    border: 1px solid $softBorder;
    border-top: none;

    @include media("<=md") {
        border: 1px solid $softBorder;
        border-radius: 4px;
    }
}

.leaderboard-item {
    padding: 9px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $softBorder;

    .leaderboard-item__start {
        display: flex;
        max-width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .leaderboard-item__end {
        display: flex;
    }

    &__place {
        font-weight: bold;
        font-size: 16px;
        margin-right: 5px;
        min-width: 26px;
    }

    &__rank {
        width: 35px;
        font-weight: bold;
        font-size: 16px;
        margin-left: 3px;
        text-align: right;
    }

    &__medals {
        width: 43px;
        display: flex;
        align-items: center;

        .icon {
            height: 16px;
            width: 16px;
        }

        .icon:not(:last-child) {
            margin-right: 5px;
        }
    }

    &__name {
        font-size: 16px;
        flex-basis: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.leaderboard-my-activity {
    background: $primaryDark;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 44px;
    text-align: center;
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
    @include media("<=sm") {
        margin-bottom: 0;
    }
}

.leaderboard-money-info {
    display: flex;
    justify-content:stretch;
    padding: 15px;
    border: 1px solid $softBorder;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-transform: uppercase;

    .total,
    .club {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 600;
        text-align: center;
        flex: 2;
    }

    .total__amount,
    .club__amount {
        font-weight: bold;
        background: #EFEFEF;
        width: 100%;
        font-size: 18px;
        padding: 5px 0;
        margin-bottom: 5px;
        @include media("<=sm") {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .total__amount{
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .club__amount{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .icon-wrap{
        padding-right: 5px;
        min-width: 42px;
        visibility: hidden;
    }

    .icon {
        font-size: 42px;
    }

    .dot {
        font-size: 55px;
        padding: 5px 3px;
        line-height: 21px;
        color: $primaryDark;
        background: #EFEFEF;
        flex: 1;
        text-align: center;
    }

    .symbol {
        color: #555;
        font-weight: bold;
    }
    .club__text,
    .total__text {
        text-align: center;
    }
}

.leaderboard-ranking {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 15px 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top:1px solid $primaryDark;
    border-left:1px solid $softBorder;
    border-right:1px solid $softBorder;
    border-bottom: 1px solid $softBorder;

    .number {
        font-size: 26px;
        font-weight: 800;
        text-align: center;

        .symbol {
            font-size: 18px;
            color: #666;
            margin-right: 1px;
        }
    }

    .badges {
        display: flex;
        align-items: center;

        .icon{
            margin: 0 5px;
        }
    }

    .text {
        text-transform: uppercase;
        font-weight: 600;
    }

    .track-info {
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &__button {
            cursor: pointer;
            font-weight: bold;
            color: $primaryDark;
        }
    }

}

.badge-tooltip {
    padding: 10px 15px;
    background: #fff !important;
    color: #000 !important;
    border: 5px solid #0280CD;
    border-radius: 4px;
    font-size: 16px !important;
    font-weight: 700 !important;
    width: 205px;

    .arrow {
        color: #0280CD;
        font-size: 30px;
        transform: translate(-5px);
    }
}

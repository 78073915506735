@import "../../../styles/ignite/common.scss";

.invite-members {
    .list {
        display: flex;

        @include media("<=md") {
            flex-wrap: wrap;
        }
    }
}

.card-item {
    width: 152px;
    margin-right: 20px;
    &:last-child{
        margin-right: 0;
    }

    @include media("<=md") {
        margin-right: 15px;
    }

    .item-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px;
        padding-top: 25px;
        align-items: center;
        font-size: 16px;
        font-weight: 800;
        border: 2px solid #000;
        border-radius: 4px;
        height: 213px;

        .icon {
            margin-bottom: 15px;
        }

        .text {
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
        }

        .amount {
            font-size: 24px;
        }

        .help-text {
            font-size: 14px;
        }

        .button {
            background: #000;
            color: #fff;
            font-size: 16px;
            font-weight: 800;
            width: 100%;
            margin-top: 10px;
            padding: 7px;
            &.disabled{
                background: #ccc !important;
            }
        }

        &.blue {
            border: 2px solid #04598D;

            .button {
                background: #04598D;
            }
        }

        &.light-blue {
            border: 2px solid $primaryDark;

            .button {
                background: $primaryDark;
            }

            .text {
                padding-bottom: 0;
                padding-top: 0;
            }

            .help-text {
                font-weight: 400;
            }
        }

        &.gray {
            border: 2px solid #666;


            .button {
                background: #666;
            }
        }

        &.red {
            border: 2px solid #D4383A;

            .button {
                background: #D4383A;
            }

            .icon {
                width: 63px;
                height: 63px;
            }

            .path {
                fill: #EB5658;
            }

            .text {
                padding-bottom: 0;
                padding-top: 0;
            }
        }
    }

    .description {
        margin-top: 10px;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;

        &.error{
            color: #EB5658;
        }
    }
}

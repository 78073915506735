@import "../../../styles/ignite/common.scss";

.invite-members {
    .title {
        padding-left: 30px;
        font-size: 26px;
        font-weight: 800;
        margin-top: 32px;
        margin-bottom: 20px;

        @include media("<=md") {
            display: none;
        }
    }

    .subtitle {
        font-size: 24px;
        font-weight: 800;
    }

    .description {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .content {
        border: 1px solid $softBorder;
        padding: 30px 20px;
        border-radius: 4px;

        .action-text {
            margin-top: 20px;
            font-weight: 800;
            font-size: 24px;
            margin-bottom: 20px;
            text-transform: uppercase;

            &:first-child {
                margin-top: 0;
            }

            &.inviteText {
                margin-bottom: 0;
            }
        }

        .action-description {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 25px;
        }

        @include media("<=md") {
            padding: 20px;
        }

        @include media("<=sm") {
            padding: 17px;
        }
    }
}

.remind-block {
    display: flex;
    margin-top: 20px;
}

.copy-email-content {
    .content-inner {
        padding: 20px;
        border: 1px solid $softBorder;
        border-radius: 4px;
        background: #EBEBEB;
        margin-top: 30px;

        .subtitle {
            font-size: 18px;
            font-weight: 600;
            padding: 0 0 15px 0;
        }

        .main-part {
            background: #fff;
            border: 1px solid $softBorder;
            border-radius: 4px;
            padding: 20px;
            display: flex;
            align-items: start;
            font-size: 16px;
            font-weight: 400;

            @include media("<=md") {
                flex-direction: column-reverse;

                .logo-wrapper {
                    margin-bottom: 20px;
                }
            }

            .text {
                max-width: 340px;
                line-height: 1.5;

                ul {
                    display: flex;
                    flex-direction: column;
                    padding-left: 20px;

                    li {
                        width: auto;
                    }
                }
            }

            .logo-wrapper {
                background: #EFEFEF;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 7px 10px 15px;
                text-align: center;
                color: #01639f;
                margin-left: 15px;
                font-weight: bold;
                font-size: 18px;
                cursor: pointer;

                .logo {
                    margin-bottom: 15px;
                    height: 80px;
                    width: 66px;
                }
            }
        }

        .link-wrapper {
            margin-top: 15px;

            .link {
                text-transform: uppercase;
                color: $primaryDark;
                font-size: 16px;
                font-weight: 800;
            }
        }

    }
}

.invite-content {
    .button {
        background: $primaryDark;
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        margin-top: 15px;
        padding: 5px 30px;

        &:hover {
            background: #04598D;
        }

        &.disabled {
            color: #fff;
            background: #ccc !important;
        }
    }

    .content-inner {
        margin-top: 30px;

        .subtitle {
            font-size: 18px;
            font-weight: 800;
            padding: 0 0 10px 0;
        }

        .main-part {
            background: #fff;
            border: 1px solid $softBorder;
            border-radius: 4px;
            padding: 20px;
            display: flex;
            align-items: start;
            font-size: 16px;
            font-weight: 400;

            .text {
                line-height: 1.5;
                ul {
                    display: flex;
                    flex-direction: column;
                    padding-left: 30px;
                    margin-top: 0;
                    margin-bottom: 0;

                    li {
                        width: auto;
                        margin-bottom: 5px;
                    }
                }
            }

            .logo-wrapper {
                background: #ebebeb;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 7px 10px 15px;
                text-align: center;
                color: #0177C0;
                margin-left: 15px;
                cursor: pointer;

                .logo {
                    margin-bottom: 15px;
                    height: 80px;
                    width: 66px;
                }
            }
        }

        .link-wrapper {
            margin-top: 15px;

            .link {
                text-transform: uppercase;
                color:$primaryDark;
                font-size: 16px;
                font-weight: 800;
            }
        }

    }
}

.remind-content {
    .switcher {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border-top: 1px solid $softBorder;
        border-left: 1px solid $softBorder;
        border-right: 1px solid $softBorder;

        .switch {
            margin: 0 20px;
        }

        .switch-option {
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 700;

            &.active {
                color: $primaryDark
            }
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: flex-end;

        .button {
            margin-top: 15px;
            background: $primaryDark;
            text-transform: uppercase;
            color: #fff;
            font-size: 18px;
            font-weight: 800;
            padding: 4px 20px;

            &.disabled {
                background: #ccc;
            }
        }
    }

    .action-text {
        margin-bottom: 0 !important;
    }

    .action-description {
        font-size: 16px !important;
        font-weight: 600 !important;
        padding-right: 20px;
        margin-bottom: 15px !important;
    }

    .content-inner {
        padding: 20px;
        border: 1px solid $softBorder;
        border-radius: 4px;
        margin-top: 30px;

        .subtitle {
            font-size: 18px;
            font-weight: 600;
            padding: 0 0 20px 0;
        }

        .main-part {
            background: #fff;
            padding: 0;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;

            .top-part {
                white-space: pre-line;
            }

            .bottom-part {
                margin-top: 20px;
            }

            .text {
                .list {
                    display: flex;
                    flex-direction: column;
                    font-weight: 700;
                    padding-left: 30px;
                    margin-top: 0;

                    .item {
                        width: auto;
                    }
                }
            }

            .logo-wrapper {
                background: #ebebeb;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 7px 10px 15px;
                text-align: center;
                color: $primaryDark;
                margin-left: 15px;
                cursor: pointer;

                .logo {
                    margin-bottom: 15px;
                    height: 80px;
                    width: 66px;
                }
            }
        }

        .link-wrapper {
            margin-top: 15px;

            .link {
                text-transform: uppercase;
                color: $primaryDark;
                font-size: 16px;
                font-weight: 800;
            }
        }
    }
}

.content {
    .icon-button {
        color: $primaryDark;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 800;
        padding: 0;

        &:hover {
            background: transparent;
        }

        .icon {
            width: 15px;
            height: 15px;
        }
    }
}

@import "../../styles/common.scss";

.EditClubPage_FlexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.EditClubPage_FlexColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.EditClubPage_FlexData {
  display: flex;
  width: 40%;
}

form hr {
  color: $softBorder;
  background-color: $softBorder;
  margin-top: 1em;
  margin-bottom: 1em;
}

.EditClubPage_FormControl {
  display: flex ! important;
  flex-direction: row ! important;
  flex-wrap: wrap ! important;
}

.EditClubPage_Label {
  display: flex;
  min-width: 10em;
  max-width: 12em;
  margin-right: .2em;
  flex-direction: row;
  font-size: $mediumHeadlineTextSize ! important;
  @include media("<=phone") {
    margin-top: .5em;
    margin-bottom: .5em;
  }
}

.EditClubPage_Field {
  display: flex;
  flex-direction: column;
  width: 18em;
  margin-right: 1em;
}

.EditClubPage_FormHelperText {
  display: flex;
  width: 18em;
  @include media("<=phone") {
    margin-top: .5em;
    margin-bottom: .5em;
  }
}

.EditClubPage_FormHelperText a {
  text-decoration: none;
  color: $primaryAccent;
}

.EditClubPage_AddressContainer {
  display: flex;
  flex-direction: column;
}

.EditClubPage_AddressContainer .EditClubPage_Field {
  display: flex;
  margin-top: .5em ! important;
}

.EditClubPage_Address {
  .EditClubPage_FlexData:not(.EditEventPostalCode) {
    width: auto;
  }

  .EditClubPage_FlexData:not(:last-of-type) {
    margin-bottom: 1em;
  }

  .EditClubPage_LocationName {
    margin-top: 1em;
  }

  .EditEventPostalCode {
    margin-left: 1em;
    flex-grow: 1;
  }
}

.EditClubPage_ButtonContainer {
  margin-top: 2em;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items:center;
}

.EditClubPage_SavingMessage {
  color: $errored;
  @include media("<=phone") {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.EditClubPage_Loading {
  margin-top: 5em;
  margin-bottom: 5em;
}

.EditClubPage_LoadingMessage {
  color: $errored;
}

.EditClubPage_Cancelled {
  color: $errored;
  font-size: $mediumHeadlineTextSize;
  text-align: center;
  margin: 2em;
  font-weight: bold;
}

.EditClubPage_Inactive {
  color: $inactiveClub;
  font-weight: bold;
}

.EditClubPage_Active {
   color: $activeClub;
   font-weight: bold;
 }

.EditClubPage_SaveIndicator {
  margin-top: .5em;
  display: flex;
  align-items: center;
  justify-content: center;
}


.EditClubPage_ButtonContainerFormControl
{
  width: 100%;
}

.EditClubPage_AlmostDone
{
  font-weight: $bold;
  font-size: 22px;
  margin-bottom: 80px;
  @include media("<=phone") {
    margin-bottom: 30px;
  }
}

.EditClubPage_AlmostDone div
{
  margin-bottom: 20px;
}
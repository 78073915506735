@import "../../styles/common.scss";

.CCBackLink {
  margin-bottom: .5em;
  margin-top: .5em;
}

.ClubEvents {
  margin-top: .5em;
}

.ClubPage {
  margin-top: 1em;

  @include media("<=sm") {
    padding-top: .1em;
  }
}

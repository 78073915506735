@import "../../../styles/ignite/common.scss";

.confirmation-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .confirmation-modal-wrapper {
        width: 500px;
        min-height: 300px;
        background: #fff;
        padding: 32px;
        box-sizing: border-box;
        position: relative;
        max-height: 100%;
        overflow: auto;

        .content {
            min-height: 250px;
            display: flex;
            flex-direction: column;

            .title {
                color: #0177C0;
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .subtitle {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 20px;
                max-width: 385px;
            }

            .main-text {
                flex-grow: 1;
            }

            .button-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .button {
                height: 40px;
                font-size: 18px;
                font-weight: 800;

                &.confirm {
                    background: #0280CD;

                    text-transform: uppercase;
                    color: #fff;
                    margin: 20px 0;
                }

                &.cancel {
                    border: 1px solid #0280CD;
                    color: #0280CD;
                }

                &.disabled {
                    background: #ccc;
                }
            }
        }

        .warning-block {
            border-radius: 4px;
            border: 2px solid #D4383A;
            background: rgba(212, 56, 58, 0.07);
            padding: 16px 24px;
            margin-bottom: 48px;
            margin-top: 48px;

            .title {
                color: #000;
                font-size: 14px;
                font-weight: 700;
            }

            .description {
                font-size: 14px;
                font-weight: 600;
            }
        }

        .close-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }

        @include media("<=sm") {
            padding: 20px;
            margin: 10px;
        }

        .change-role {
            &__select {
                width: 300px;
            }
        }
    }
}

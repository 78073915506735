@import "../../../../styles/ignite/common.scss";
.BYOCWelcomeModal {
    text-align: center;
    display: flex;
    margin: 16px 0;
    @include media("<=phone") {
        padding: 8px;
        flex-direction: column-reverse;
    }
    .welcome{
        color: $primaryDark;
        font-weight: 700;
        font-size: 24px;
    }

    .message {
        font-size: 18px;
        font-weight: 700;
        padding: 0px 5em;
        @include media("<=phone") {
            padding: 0 1em;
        }
    }
    .cta {
        margin-top: 8px;
        font-weight: 700;
        background-color: $primaryDark;
    }
    .close {
        margin: 0 12px 0 0;
        @include media("<=phone"){
            margin: 0;
            text-align: right;
        }
    }
}
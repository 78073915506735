@import "../../styles/common.scss";

$tabletWidth: 768px;
$mobileWidth: 600px;

$e3blue: #0177C0;

/* default gap for flexbox spacing */
$gap: 3.5;
$mobilegap: 2;
$gap-vw: calc(#{$gap} * 1vw);
$mobilegap-vw: calc(#{$mobilegap} * 1vw);

.flex-columns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.column-end {
    align-self: flex-end;
    margin-left: auto;
}
.flex-rows {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.equal-columns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @media (max-width: $mobileWidth) {
        justify-content: center;
    }
}

.equal-columns>div {
    flex: 1 1 0px;
}

.equal-columns.is-square>div {
    // square aspect ratio = 100 / (100 total, not including the gap)
    aspect-ratio: 100 / calc(100 - #{$gap});
}

.VirtualClubsLandingPage,
.VirtualClubsCategoryPage {
    position: relative;
}



.VirtualClub_MUIButton_outlined {

    // align to bottom
    margin-top: auto;
    align-self: flex-end;

    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: $e3blue;
    border-color: rgba(25, 118, 210, 0.5);

    &:hover {
        background: rgba(25, 118, 210, 0.05);

    }


}

.VirtualClubs_TermsContainer {

    position: relative;
    left: -24px;
    padding-left: 24px;
    width: calc(100%);

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    padding-bottom: 10px;
}

.VirtualClubs_Terms {
    min-width: 610px;
}

.VirtualClubs_Term {

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: rgba(25, 118, 210, 0.08);
    text-align: center;
    color: $e3blue;
    cursor: pointer;

    &:hover {
        background-color: #ffffff;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    }

    .term_image {
        padding-top: 10%;
        max-height: 80px;
        width: 64%;

        @media (max-width: $tabletWidth) {
            padding-top: 9%;
            width: 58%;
        }

        @media (max-width: $mobileWidth) {
            padding-top: 14%;
        }
    }

    h5 {
        margin: 0;
        padding: 1% 4% 0;
        font-size: 13.5px;
        line-height: 16px;

        @media (max-width: $tabletWidth) {
            font-size: 11px;
            line-height: 14px;
        }

        @media (max-width: $mobileWidth) {
            font-size: 12px;
            line-height: 16px;
            padding-bottom: 8%;
        }
    }
}





/* carousel customizations */
.recCarousel,
.rec-item-wrapper,
.rec-slider-container {
    height: 100% !important;

}

.rec.rec-pagination {
    display: none;
}

.rec.rec-slider-container {
    margin: 0;
}

.rec.rec-arrow:disabled {
    visibility: hidden;
}

.rec.rec-arrow {
    height: 100%;
    width: 32px;
    border-radius: 0;
    background: url("../../assets/nextprev-button.png") no-repeat center center;
    background-size: 10px 16px;
    border: none;
    color: transparent;
    box-shadow: none;

    &:hover,
    &:hover:enabled,
    &:focus,
    &:focus:enabled {
        height: 100%;
        border-radius: 0;
        background: url("../../assets/nextprev-button.png") no-repeat center center;
        background-size: 10px 16px;
        border: none;
        box-shadow: none;
        color: transparent;
    }

    &.rec-arrow-left {
        transform: rotate(180deg);
    }
}
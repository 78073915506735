@import "../../styles/common.scss";


.DirectoryWorkInProgress {
  border-radius: 1em;
  border: 1px solid #CF504F;
  flex-direction: column;
  position: relative;

  @include media("<=phone") {
    margin-top: 120px;
  }
}

.DirectoryWorkInProgress_Content {
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;

  @include media("<=phone") {
    flex-direction: column;
    padding: 24px;
  }
}

.CrossBoldIcon {
  position: absolute;
  right: 24px;
  top: 16px;
  cursor: pointer;

  @include media("<=phone") {
    right: 16px;
    top: 10px;
  }
}


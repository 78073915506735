@import "../../../styles/common.scss";

$primaryDark: #0177C0;

.verification-status {
    max-width: 850px;
    margin: 0 auto;
    min-height: 15em;

    &__title {
        font-size: 32px;
        font-weight: 800;
        text-align: center;
        margin: 40px auto 40px;
        padding: 0 15px;
        white-space: pre-line;

        &.not-eligible {
            color: #ED5459;
        }

        @include media("<=phone") {
            font-size: 24px;
        }
    }

    &-content {
        border: 1px solid $softBorder;
        border-radius: 8px;
        padding: 15px 0;
        margin: 40px auto 20px auto;
        max-height: 600px;
        overflow-y: auto;

    }

    &-row {
        display: flex;
        align-items: center;
        padding: 20px;

        &:not(:last-child) {
            border-bottom: 1px solid $softBorder;

            @include media("<=phone") {
                flex-direction: column;
            }
        }
        &.second{
            flex-direction: column;
        }

        .main-info {
            padding-left: 30px;

            &__name {
                font-size: 24px;
                font-weight: bold;
            }

            &__description {
                font-size: 20px;
            }

            @include media("<=phone") {
                padding: 30px 0;
            }
        }

        .logo-wrapper {
            padding-right: 15px;
            min-width: 155px;
            min-height: 45px;
            img{ 
                max-width: 200px;
            }
            @include media("<=phone") {
                padding-right: 0;
            }

        }

        @include media("<=phone") {
            justify-content: center;
        }
    }

    &-row.other {
        justify-content: center;

        .logo-wrapper {
            display: none;
        }

        .main-info {
            padding-left: 0;
        }
    }



    @include media("<=phone") {
        flex-direction: column;
        text-align: center;
    }

    .help-text {
        display: flex;
        align-items: center;
        font-weight: 800;
        font-size: 24px;

        &__icon {
            color: $primaryDark;
            font-size: 28px;
            margin-right: 16px;
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: flex-end;

        &.not-eligible {
            flex-direction: column;
            align-items: flex-end;
            
            @include media("<=phone") {
                align-items: center;
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .button {
            background: $primaryDark;
            color: $background;
            font-size: 18px;
            font-weight: 800;
            padding: 4px 13px;
            height: 40px;
            width: 275px;
            margin-bottom: 20px;

            &__icon {
                margin-left: 10px;
                width: 20px;
                height: 18px;
            }

            &.white {
                background: $background;
                color: $primaryDark;

                &:hover {
                    background: $primaryDark;
                    color: $background;
                }
            }

            &:hover {
                background: $primaryAccent;
            }
        }

        @include media("<=phone") {
            justify-content: center;
        }
    }

    @include media("<=phone") {
        margin: 140px auto 60px;
    }
}

@import "../../styles/common.scss";

.PassionMultiSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  .PassionMultiSelect_CarouselWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .PassionMultiSelect_Carousel {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .PassionMultiSelect_ScrollWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 8px;
    &.PassionMultiSelect_Disabled {
      visibility: hidden;
      pointer-events: none;
      touch-action: none;
    }
  }
  .PassionMultiSelect_Scroll {
    color: rgba(0, 0, 0, 0.54);
  }
  .PassionMultiSelect_PassionChip {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 16px;
    padding: 4px;
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
  .PassionMultiSelect_PassionChip_Name {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 2px;
    padding: 3px 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 60px;
    text-align: center;
  }
  .PassionMultiSelect_PassionChip_Remove {
    color: rgba(0, 0, 0, 0.26);
    width: 20px;
    cursor: pointer;
  }
  .PassionMultiSelect_AddPassionsButton {
    font-family: 'Roboto', sans-serif;
    color: #0177C0;
    font-weight: 700;
    font-size: 14px;
    line-height: 157%;
    margin-top: 2px;
    white-space: nowrap;
  }
  @include media("<=phone") {
    .PassionMultiSelect_Carousel {
      flex-wrap: wrap;
    }
    .PassionMultiSelect_PassionChip {
      margin-bottom: 4px;
    }
    .PassionMultiSelect_PassionChip_Name {
      width: auto;
    }
  }
}

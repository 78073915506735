@import "../../styles/common.scss";

$needHelpRight: 0;
$needHelpRight_Mobile: 0;

.TopCallout {
  background-color: $callout;
  height: $topCalloutHeight;
  @include media("<=phone") {
    position:fixed;
    width: 100%;
    left: 0px;
    top: $topAccentHeight;
    z-index: 500;
  }
}

.LanguageSelection {
  width: $desktopPageContentWidth * 1px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.LanguageSelection .LanguageSelectionContent {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.7em;
  height: 30px;
  padding: 3px 0;
}

.LanguageSelection .TopCallout_LanguageSelectionField {
  position: absolute;
  top: 0;
  left: 30px;
  height: 30px;
}

.LanguageSelection .TopCallout_LanguageSelectionField .MuiSelect-root {
  padding: 0 24px 0 4px;
  height: 30px;
  line-height: 30px;
}

.NeedHelp {
  width: $desktopPageContentWidth * 1px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @include media("<=phone") {
    width: 100%;
    right: 10px;
  }
}

.NeedHelpContent, .NeedHelpContentMobile {
  position: absolute;
  right: $needHelpRight;
  line-height: 1.7em;
  @include media("<=phone") {
    display: none;
  }
}

.NeedHelp a {
  text-decoration: none;
  color: $foreground;
  font-weight: $bold;
}

.NeedHelpContentMobile   {
  right: $needHelpRight_Mobile;
  @include media("<=phone") {
    display: block;
  }
  @include media(">phone") {
    display: none;
  }
}

.NeedHelpContentMobile .PhoneIcon
{
  margin-left: 5px;
  vertical-align:middle;
  margin-top: -5px;
}

.NeedHelpContentMobile a {
  font-weight: normal;
}
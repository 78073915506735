@import "../../styles/common.scss";

.ClubMemberLandingPage {
  #MemberLanding_Banner {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-direction: row;
    @include media("<=phone") {
      flex-direction: column;
    }
  }

  #MemberLanding_Text {
    flex: 50%;
  }

  #MemberLanding_Text p {
    margin-block-start: 0;
  }

  #MemberLanding_Image_Wrapper {
    flex: 50%;
    width: 305px;
    height: 305px;
    margin-right: 30px;
    @include media("<=phone") {
      margin: auto;
    }
  }

  #MemberLanding_Image_Wrapper img {
    width: 305px;
    height: 305px;
    border-top-left-radius: 10px;
    border-top-right-radius: 92px;
    border-bottom-right-radius: 92px;
    border-bottom-left-radius: 92px;
  }

  #CCClubLeaderLanding_Subtext {
    font-size: 30px;
  }

  #CCClubLeaderLanding_Subtext {
    text-align: right;
    margin-right: 30px;
  }

  #CCClubLeaderLanding_Learn_More_Header {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  #CCMemberLanding_Register {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 30px;
    @include media("<=phone") {
      flex-direction: column;
    }
  }

  #MemberLanding_Login {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 30px;
    margin-top: 20px;
    @include media("<=phone") {
      flex-direction: column;
    }
  }

  #CCMemberLanding_FAQ {
    width: 100%;
    @include media("<=phone") {
      width: 100%;
    }
  }

  #CCMemberLanding_FAQ_Wrapper {
    display: flex;
    flex-direction: column;
  }

  #CCMemberLanding_FAQ_Text {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    column-gap: 40px;
    font-size: 14px;
    align-content: space-evenly;
  }

  #CCMemberLanding_FAQ_Text > div {
    text-align: left;
  }

  a {
    color: #0891e9;
    text-decoration: none;
    font-weight: bold;
  }
}

.OptumSelectPlan {
  .OptumSelectPlan_FormErrors {
    margin-top: 1em;
  }

  .OptumSelectPlan_FormError {
    color: red;
  }

  .OptumSelectPlan_Fields {
    display: flex;
    flex-direction: column;
  }

  .OptumSelectPlan_InsuranceIdField {
    margin-top: 1em;
  }
}
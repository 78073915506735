@import "../../../styles/ignite/common.scss";

.club-finder-box {
    background: $callout;
    border-radius: .5em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 0;
    padding: .7em;

    .form-control {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .search-input {
        width: 72%;
        margin: 0 .7em;
        background: $background;
        border-radius: .5em;
    }

    .search-button {
        width: 19%;
        text-align: center;
        font-weight: bold;
        height: 2.2em;
        font-family: inherit;
        padding: 0;
        background: $primaryDark;

        @include media("<=phone") {
            padding: 0.5em 0.2em;
            width: 25%;
        }
    }

    .CCButton_ChildContainer {
        display: block;
    }

    @include media("<=phone") {
        padding: 0.5em;
    }

    .search-icon {
        font-size: 3.2em !important;
        color: $darkCallout;

        @include media("<=phone") {
            display: none;
        }
    }
}

.autocomplete-item {
    border-top: 1px solid $softBorder;
    width: 100%;
    padding: .4em 0 0;
}


.alphabetical-list {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 800;
    color: $darkCallout;
    margin: 0 0 1.2em;
    padding-top: .6em;
    font-size: 1.25em;

    @include media("<=phone") {
        font-size: 1em;
        padding-top: 1.6em;
        margin-bottom: 1.6em;
        flex-wrap: wrap;
        flex-wrap: wrap;
    }

    &__letter {
        margin-right: .45em;
        cursor: pointer;

        &.active {
            color: $primaryAccent;
        }

        &.disabled {
            cursor: not-allowed;
        }

        @include media("<=phone") {
            margin-right: .2em;
        }
    }
}

.chip-list {
    font-weight: bold;
    margin: .5em .5em 1em;

    &__item {
        margin-right: .7em;
        text-transform: capitalize;
    }
}

@import "../../../styles/common.scss";

.MemberRegistrationWizard {
  @include media(">phone") {
    width: 50%;
    margin: 0 auto;

    > h1 {
      text-align: center;
    }
  }

  p.WizardStepHeading {
    text-align: center;
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 25px;
  }
}

@import "../../styles/common.scss";

.EventView {
  position: relative;
}

.EventView {

  .EventView_CancelledWarning {
    background-color: $darkErrored;
    color: $darkErroredForeground;
    padding: 1.5em;
    @include media("<=phone") {
      width: 125%;
      margin-left: -25px;
    }
  }

  .EventView_CancelledWarningTitle {
    font-size: $smallHeadlineTextSize;
    font-weight: $bold;
    @include media("<=phone") {
      width: 80%;
    }
  }

  .EventView_CancelledWarningMessage {
    font-weight: $semiBold;
    @include media("<=phone") {
      margin-top: .5em;
      width: 80%;
    }
  }

  .EventView_Desktop {
    display: flex;
    @include media("<=phone") {
      display: none;
    }
  }

  .EventView_Mobile {
    display: flex;
    flex-direction: column;
    @include media(">phone") {
      display: none;
    }
  }

  .EventView_Image {
    margin-top: 31px;
    margin-bottom: 23px;

    @include media("<=phone") {
      margin-bottom: 40px;
    }
  }

  .EventView_Desktop_Column:first-child {
    margin-right: 20px;
    flex-grow: 1;
  }

  .EventView_Desktop_Column.Column2 {
    width: 290px;
  }

  .EventView_EventTitle_PassionName {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
  }

  .EventView_EventTitle_EventName {
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: #0177C0;
  }

  .EventView_EventTitle_DateLine {
    margin-top: 16px;
    margin-bottom: 24px;
    @include media("<=phone") {
      margin-bottom: 40px;
    }
    .ListSelfGuidedIndicator {
      margin-top: 5px;
    }
  }

  .EventView_EventTitle_DateLine_Today {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 17px;
    background: #0177C0;
    height: auto;
    .MuiChip-label {
      padding: 0 5px;
    }
  }

  .EventView_Chip {
    background: #0177C0;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 150%;
    .MuiChip-label {
      padding: 3px 10px;
    }
  }

  .EventView_EventTitle_DateLine_Date {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .EventView_EventTitle_Time {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-top: 10px;
  }

  .EventView_EventLocationAndTime {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    .CCLocationView_LocationName {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }
    @include media("<=phone") {
      margin-top: 40px;
    }
  }

  .EventView_Registered {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0091e9;
    padding: 8px 8px;
  }

  .EventView_Registered_Icon {
    margin-right: 4px;
  }

  .EventView_RegisterButton {
    width: 100%;
    height: 62px;
    margin-bottom: 32px;
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    //padding: 18px 71.5px;
    //box-sizing: border-box;
    .CCButton_ChildContainer {
      justify-content: center;
    }
    @include media("<=phone") {
      margin-bottom: 0;
    }
  }

  .EventView_HaveQuestionsSection {
    margin-bottom: 32px;
    @include media("<=phone") {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .EventView_TextBody {
    margin-top: 40px;
  }

  .EventView_TextBody_Title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 12px;
    @include media("<=phone") {
      margin-bottom: 40px;
    }
  }

  .EventView_TextBody_Description {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    white-space: pre-wrap;
  }

  .WhatYoullNeedSection.Hidden {
    display: none;
  }

  .WhoSection_ClubLink {
    color: #0091e9;
  }
}

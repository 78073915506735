@import "../../../styles/ignite/common.scss";

.ignite-page-content {
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  .title {
    font-size: 32px;
    font-weight: 800;
    line-height: 150%;
    text-align: center;

    @include media("<=phone") {
      font-size: 24px;
    }
  }

  .separator {
    width: 30%;
    opacity: .5;
    margin: 20px auto 30px;
  }

  .intro-text {
    font-size: 24px;
    text-align: center;
    margin-bottom: 38px;
    line-height: 28.8px;

    @include media("<=phone") {
      font-size: 20px;
    }
  }

  .contact-info {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    align-items: center;

    &:last-child .contact-info__label {
      align-self: start;
    }

    &__main {
      width: 78%;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      align-items: center;

      &.preferred-method {
        flex-direction: column;
        align-items: start;

        .button-wrapper {
          .button {
            background: #EBEBEB;
            font-size: 18px;
            font-weight: 700;
            color: #555;
            width: 113px;
            height: 40px;
            margin-right: 2px;

            &.active {
              background: #0280CD;
              color: #fff;
            }

            @include media("<=sm") {
              width: 72px;
              font-size: 16px;
            }
          }
        }

        &.is-editing {
          pointer-events: none;

          .contact-info__label,
          .contact-info__main,
          .contact-info__help-text {
            color: #898989;
          }

          .button {
            color: #898989 !important;

            &.active {
              color: #fff !important;
              background: #C4C4C4 !important;
            }
          }
        }
      }

      @include media("<=phone") {
        font-size: 16px;
        margin-top: 4px;
      }
    }

    &__text {
      padding: 4.5px 0 5.5px;
    }

    &__help-text {
      font-size: 16px;
      font-weight: 600;
      margin-top: 20px;
      max-width: 480px;

      @include media("<=sm") {
        font-size: 15px;
        max-width: 200px;
      }
    }

    &__input {
      font-size: 24px !important;
      font-weight: bold !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include media("<=phone") {
        font-size: 16px !important;
      }
    }

    &__base {
      &::before {
        display: none;
      }

    }

    &__label {
      font-size: 18px;
      text-transform: uppercase;
      width: 23%;
      font-weight: 600;
      align-self: center;

      @include media("<=sm") {
        font-size: 16px;
        padding-left: 20px;
        width: 31%;
      }
    }

    &__checkbox {
      margin-right: 28px;
      padding-top: 0;
    }

    .switcher {
      font-size: 16px;

      &.positive {
        .switcher__positive-text {
          color: $primaryAccent;
        }
      }

      &.negative {
        .switcher__negative-text {
          color: $primaryAccent;
        }
      }
    }
  }

  .club-button {
    border: 1px solid $primaryAccentDark;
    color: $primaryAccentDark;
    background: $primaryDark;
    color: #fff;
    font-weight: 800;
    align-self: flex-end;
    margin-top: 24px;
    min-height: 40px;
    min-width: 135px;
    padding-right: 0;

    &__icon {
      padding-left: 20px;
    }

    &:hover {
      background: $primaryDark;
    }
  }

  .disabled-button {
    color: #fff;
    background: $softBorder;
  }

  .contact-methods-content {
    margin-top: 8px;
    padding: 30px 40px 40px 40px;
    border: 1px solid $softBorder;
    border-radius: 4px;

    &.editing {
      border: 1px solid $primaryDark;
    }

    @include media("<=sm") {
      padding: 30px 0;
    }
  }

  .contact-methods-top {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    border-bottom: 1px solid $softBorder;
    margin-bottom: 50px;

    &__title {
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase;

      @include media("<=sm") {
        font-size: 20px;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;

      .edit-button {
        border: 1px solid $primaryAccentDark;
        color: $primaryAccentDark;
        background: $primaryDark;
        color: #fff;
        font-weight: bold;
        align-self: flex-end;
        border-radius: 5px;
        height: 40px;
        width: 40px;
        margin-left: 10px;

        &:hover {
          background: $primaryDark;
        }
      }
    }

    @include media("<=sm") {
      padding: 0 20px 20px;
    }
  }

  .checkbox-column {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-bottom: 16px;

    &__text {
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 18px;
    }
  }

  @include media("<=phone") {
    padding: 0;
  }
}

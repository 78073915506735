@import "../../styles/common.scss";

.ManualRsvp {
  display: flex;
  flex-direction: column;
}

.ManualRsvp_Instructions {
  display: flex;
  flex-direction: column;
}

.ManualRsvp_InstructionsTitle {
  margin-top: 1em;
  display: flex;
  font-weight: $bold;
  font-size: $mediumHeadlineTextSize;
}

.ManualRsvp_InstructionsText {
  flex-wrap: nowrap;
  display: flex;
  @include media("<=phone") {
    margin-top: .5em;
  }
}

.ManualRsvp_Form {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include media("<=phone") {
    flex-direction: column;
    margin-top: .5em;
    align-items: flex-start;
  }
}

.ManualRsvp_AutocompleteFormControl {
  width: 25em;
  @include media("<=phone") {
    width: 100%;
  }
}

.ManualRsvp_Autocomplete {
  display: flex;
  @include media("<=phone") {
    width: 100%;
  }
}

.ManualRsvp_Button {
  margin-left: 1em;
  display: flex;
  @include media("<=phone") {
    margin-top: .5em;
    margin-left: 0;
  }
}

.ManualRsvp_SavingSuccess {
  margin-left: .5em;
  @include media("<=phone") {
    margin-top: .5em;
    margin-left: 0;
  }
}

@import "../../styles/common.scss";

.AmericasWalkingClubWaiver {
  div:not(.AmericasWalkingClubWaiver_Body) {
    display: flex;
  }
  li {
    padding-left: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    @include media("<=phone") {
      padding-left: .2em;
      margin-top: .2em;
      margin-bottom: .5em;
    }
  }
  .AmericasWalkingClubWaiver_Header {
    font-weight: $bold;
    margin-bottom: 2em;
    @include media("<=phone") {
      flex-direction: column;
      align-items: center;
      margin-bottom: 1em;
    }
    img {
      width: 125px;
      @include media("<=phone") {
        margin-bottom: 1em;
      }
    }
  }
  .AmericasWalkingClubWaiver_HeaderText {
    justify-content: center;
    align-self: center;
    text-align: center;
    margin-left: 1em;
    flex-basis: 100%;
  }
}
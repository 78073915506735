@import "../../styles/common.scss";

.CCClubView {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
}

.CCClubView div {
  display: flex;
}

.CCClubView_InactiveWarning {
  background-color: $callout;
  color: $calloutForeground;
  @include media("<=phone") {
    flex-direction: column;
  }
  @include media(">phone") {
    flex: 0 0 100%;
    flex-wrap: wrap;
  }
}

.CCClubView_InactiveWarningTitle {
  padding: 1em;
  flex: 0 0 100%;
  font-size: $mediumHeadlineTextSize;
}

.CCClubView_InactiveWarningText {
  padding: 1em;
  width: 80%;
  @include media("<=phone") {
    flex-direction: column;
    padding: 1em;
  }
}
.CCClubView_Chip {
  color: #0177C0 !important;
  background-color: #FFFFFF !important;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 150%;
  .MuiChip-label {
    padding: 3px 10px;
  }
}

.CCClubView_InactiveWarningButton {
  margin-left: 1em;
  @include media("<=phone") {
    margin-left: 0em;
    margin-top: 1em;
  }
}

.CCClubView_Image {
  width: 350px;
  height: 350px;
  margin-right: .5em;
  background-size:     cover;                    
  background-repeat:   no-repeat;
  background-position: center center;
  @include media("<=phone") {
    margin-right:-25px;
    margin-left:-25px;
    width: 125%;
    min-height: 350px;
    height: auto;
    padding: 0;
  }
}


.CCClubView_Details {
  background-color: $primaryAccent;
  color: $primaryAccentForeground;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  flex: 1 1 auto;
  max-width: 430px;
  @include media("<=phone") {
    padding: 0 25px;
    margin-right:-25px;
    margin-left:-25px;
    width: 100%;
    max-width: none;
  }
  @include media(">phone") {
    min-height: 260px;
  }
}

.CCClubView_ClubNameAndDescription {
  padding-top: 30px;
  flex-direction: column;
  @include media("<=phone") {
    padding: .5em;
    padding-top: 1em;
  }
}


.CCClubView_ClubNameAndVirtual{
  cursor: pointer;
  flex-direction: column;
}

.CCClubView_ClubName {
  font-size: $largeHeadlineTextSize;
  font-weight: $bold;
  @include media(">phone") {
    line-height: 1.2em;
  }
  @include media("<=phone") {
    font-size: $mediumHeadlineTextSize;
  }
}

.CCClubView_ClubDescription {
  font-weight: $semiBold;
  margin-top: .5em;
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25em;
  line-height: 1.2em;
}
.CCClubView_VirtualClub {
  padding-top: .50em;
  margin-top: .5em;
  max-height: 5em;
  overflow: hidden;
  max-width: 25em;
  line-height: 1.2em;
}


.CCClubView_PassionAndLocation {
  display: flex;
  margin-top: .5em;
  justify-content: space-between;
  flex-direction: column;
  @include media("<=phone") {
    margin-top: 0;
  }
}

.CCClubView_Passion {
  margin-top: .5em;
  font-weight: $bold;
  @include media("<=phone") {
    margin-top: .5em;
    padding-left: .5em;
    padding-right: .5em;
  }
}

.CCClubView_Location {
  font-weight: $bold;
  flex-direction: column;
  margin-right: 2em;
  @include media("<=phone") {
    padding-left: .5em;
    padding-right: .5em;
  }
}

.CCClubView_TimeZone {
  font-weight: $bold;
  @include media("<=phone") {
    padding-right: .5em;
    margin-top: 0;
  }
}

.CCClubView_ClubLeader {
  font-weight: $bold;
  margin-top: 1em;
  @include media("<=phone") {
    padding-left: .5em;
    padding-right: .5em;
  }
}


.CCClubView_ClubLeaderEmail {
  font-weight: $bold;
  margin-bottom: .5em;
  @include media("<=phone") {
    padding-left: .5em;
    padding-right: .5em;
    padding-bottom: .5em;
  }
}

.CCClubView_ClubLeaderEmail a {
  text-decoration: none;
  color: $primaryAccentForeground;
  margin-left: .5em;
}

.CCClubView_Links {
  @include media(">phone") {
    flex-direction: column;
    justify-content: flex-end;
    justify-self: flex-end;
    margin-bottom: .5em;
    align-self: flex-end;
    margin-right: -15px;
    flex: 1;
  }
  @include media("<=phone") {
    margin-top: .5em;
    padding: .5em;
    align-items: center;
    align-self: center;
  }
}

.CCClubView_Links_Row {
  @include media(">phone") {
    flex-direction: row;
  }
}

.CCClubView_Links a {
  @include media(">phone") {
    display: flex;
  }
  color: $primaryAccentForeground;
  text-decoration: none;
  font-weight: $bold;
}

@import "../../../styles/ignite/common.scss";

.found-club-item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .4em;
    border: 1px solid $softBorder;
    padding-right: .6em;
    padding-bottom: 0;
    border-radius: 0.3em;
    font-weight: 800;

    @include media("<=phone") {
        flex-wrap: wrap;
        border:none;
        padding-bottom: 1.25em;
        border-bottom: 1px solid $softBorder;
        border-radius: 0;
    }

    &__content {
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: 100%;
    }

    &__logo {
        width: 4em;
        height: 4em;
        margin-right: .7em;
    }

    .request-button {
        border: 1px solid $primaryDark;
        color: $primaryDark;
        padding: .3em 1em .3em 1.3em;
        font-size: 1em;
        margin: 0;
        min-width: 160px;
       
        span {
            font-weight: bold;
            white-space: nowrap;
        }

        @include media("<=phone") {
           margin-top: .6em;
        }

    }
    
}

.SelectButtonGroup {
  .SelectButtonGroup_Button {
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    color: #0491E8;
    border-color: rgba(25, 118, 210, 0.5);
    padding: 6px 8px;
    &.selected {
      background: rgba(25, 118, 210, 0.08);
    }
  }
}

@import "../../../styles/ignite/common.scss";

.record-activity {
    .title {
        padding-top: 24px;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
        color: #0280CD;
        @include media("<=phone") {
            padding-top: 8px;
            margin-bottom: 8px;
            font-size: 20px;
        }
    }
    .portable-title {
        padding-top: 24px;
        margin-bottom: 24px;
        color: #0280CD;
        @include media("<=phone") {
            padding-top: 8px;
            margin-bottom: 8px;
        }
    }
    .abc {
        border: 1px solid #C4C4C4;
        border-radius: 0px 0px 4px 4px;
        padding: 16px 72px 40px 72px;
        @include media("<=phone") {
            padding: 4px 18px 10px 18px;
        }
        .type-header {
            text-align:center; 
            font-size: 14px;
            font-weight: 700;
            color: #0280CD;
        }
    }
}

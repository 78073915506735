@import "../../pages/virtualClubs/VirtualClubsCommon.scss";
$e3blue: #0177C0;

.VirtualClub_Event_LiveNow {
    padding-bottom: 24px;

    .event_live_now {
        line-height: 50px;
        display: flex;
        font-size: 20px;
        font-weight: 700;
    }

    .event_name {
        padding-left: 10px;
    }

    .event_link {
        padding-left: 10px;
        color: #9747FF;
        text-decoration: underline;
        cursor: pointer;
    }
}
@import "../../styles/common.scss";

.EventSearchResults {
  .SortButton {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

  .SortText {
  }

  .SortIcon {
  }

  $tabletWidth: 768px;
  $mobileWidth: 600px;

  // Reverse everything from BorderedRow.scss
  .BorderedRow {
    @media (min-width: $mobileWidth) {
      flex-direction: row;
      border: none;
      border-bottom: 1px solid $darkCallout;
      padding: 1em 0.2em;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  .BorderedRow:first-child {
    @media (min-width: $mobileWidth) {
      margin-top: 0;
    }
  }
  .BorderedRow .BorderedRowItem {
    @media (min-width: $mobileWidth) {
      margin-top: 0;
      width: initial;
    }
  }
  .BorderedRow .BorderedRowLastItem {
    @media (min-width: $mobileWidth) {
      margin-top: 0;
      width: initial;
      justify-content: initial;
    }
  }

  .EventSearchResults_NoResultsWrapper {
    text-align: center;
  }

  .EventSearchResults_NoResults {
    display: inline-flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    margin: 5px auto;
    align-items: center;
  }

  .EventSearchResults_Link {
    font-weight: bold;
    a {
      color: #0091e9;
      text-decoration: none;
      white-space: nowrap;
    }
  }

  .EventSearchResults_OtherOptionsText {
    font-weight: bold;
  }
  .EventSearchResults_OtherOptions {
    text-align: left;
    margin-top: 15px;
  }
  .EventSearchResults_OtherOptionsTable {
    width: 100%;
    td {
      padding-top: 5px;
      padding-bottom: 5px;
      &:first-child {
        padding-right: 20px;
      }
    }
    .CCButton {
      padding-left: 1.2em;
      padding-right: 1.2em;
    }
  }

  .EventSearchResults_NoResults_VirtualOnly,
  .EventSearchResults_NoResults_ResetButton {
    color: #0177c0;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 4px;
    margin-left: 5px;
  }

  .ResultsTable {
    @media (max-width: $mobileWidth) {
      display: none;
    }
    .Result {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.ResultHeader {
        font-weight: bold;
        @media (max-width: $mobileWidth) {
          display: none;
        }
      }

      .EventName {
        flex-basis: 30%;
        flex-direction: column;

        .EventName_IsSelfGuided {
          font-size: $formEntryTextSize;
        }

        .EventName_IsPaid {
          font-size: $formEntryTextSize;
        }

        .TypeAndEventName_EventName {
          color: $primaryAccent;
          font-size: $largeTextSize;
          font-weight: $semiBold;
        }
      }

      .Date {
        padding-right: 10px;
        flex-basis: 11%;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: $tabletWidth) {
          flex-basis: 13%;
        }
      }

      .Date_DayOfWeek {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #3e3e3e;
      }

      .Date_Date {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #3e3e3e;
      }

      .Date_Recurrence {
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        padding-top: 10px;
        color: #3e3e3e;
      }

      .PassionImageColumn {
        padding-right: 21px;
        flex-basis: 13.2%;
        box-sizing: border-box;
        overflow: hidden;
      }

      .PassionImageColumn_Image {
        width: 100%;
        height: 63px;
        background-size: cover;
        background-position-y: center;
        background-position-x: center;
        border-radius: 8px;
      }

      .TypeAndEventName {
        padding-right: 5px;
        flex-basis: 23.8%;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .TypeAndEventName_Type {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          text-transform: uppercase;
          color: #2f2f2f;
        }

        .TypeAndEventName_EventName {
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          color: #0177c0;
        }

        .ListSelfGuidedIndicator .SelfGuidedEvent {
          white-space: initial;
        }
      }

      .Time {
        padding-right: 21px;
        flex-basis: 11.6%;
        box-sizing: border-box;
        overflow: hidden;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        flex-wrap: wrap;

        @media (max-width: $tabletWidth) {
          flex-basis: 16.6%;
        }
      }

      .Location {
        padding-right: 7px;
        flex-basis: 18%;
        box-sizing: border-box;
        overflow: hidden;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;

        .Virtual_Short {
          display: none;
        }

        @media (max-width: $tabletWidth) {
          .Virtual_Full {
            display: none;
          }

          .Virtual_Short {
            display: inline-flex;
          }
        }
      }

      .Level {
        padding-right: 16px;
        flex-basis: 10%;
        box-sizing: border-box;
        overflow: hidden;
      }

      .Action {
        flex-basis: 14.7%;
        flex-grow: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        @media (max-width: $tabletWidth) {
          flex-basis: 5%;
        }
      }

      .Action_Button {
        padding: 8px 22px;
        line-height: 26px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        color: #0177c0;
        font-weight: 800;
        font-size: 16px;

        .Action_Button_Short {
          display: none;
        }

        @media (max-width: $tabletWidth) {
          padding: 8px;
          font-size: 11px;

          .Action_Button_Full {
            display: none;
          }

          .Action_Button_Short {
            display: inline;
          }
        }
      }
      // button with disabled attribute
    }

    .Result_Forbidden {
      background-color: #f5f5f5;
      .Action_Button {
        color: #9e9e9e;
        border: 1px solid #9e9e9e;
      }
    }
  }

  .ResultsList {
    display: none;
    flex-direction: column;
    @media (max-width: $mobileWidth) {
      display: flex;
    }

    .ResultsList_SelectLabel {
      display: none;
    }

    .ResultsList_Select span {
      color: rgba(0, 0, 0, 0.54);
      visibility: visible;
      transform: translateY(-20%);
    }

    .ResultsList_Divider {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .Result:not(:last-child) {
      margin-bottom: 32px;
    }

    .Result_LineHorizontal {
      display: flex;
      justify-content: space-between;
    }
    .Result_LineVertical {
      display: flex;
      flex-direction: column;
    }
    .Result_LineDateTime {
      margin-top: 16px;
    }
    .Result_Date {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #3e3e3e;
    }
    .Result_Time {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
    .Result_LineEventTypeName {
      margin-top: 18px;
    }
    .Result_Type {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
    }
    .Result_EventName {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #0177c0;
    }
    .Result_LineLocation {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .Result_LineLocation .ListSelfGuidedIndicator {
      margin-top: 8px;
    }
    .Result_Location {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
    .Action_Button .CCButton_ChildContainer {
      font-weight: 800;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      color: #0177c0;
      justify-content: center;
    }

    .Result_Forbidden {
      background-color: #f5f5f5;
      .Action_Button {
        border: 1px solid #9e9e9e;
        .CCButton_ChildContainer {
          color: #f5f5f5;
        }
      }
    }
  }

  .ResultsCalendar {
    @media (max-width: $mobileWidth) {
      display: none;
    }
    margin-top: 20px;
    .fc .fc-view,
    .fc-scroller {
      position: static;
    }
    .fc-view-harness {
      height: auto !important;
    }
    .fc-daygrid .fc-scrollgrid {
      border-left: none;
      border-right: none;
      border-top: none;
      .fc-col-header-cell.fc-day {
        border-left: none;
        border-right: none;
        text-transform: uppercase;
      }
      & > thead > tr,
      & > thead > tr > th {
        border-right: none;
      }
      & > tbody > tr > td {
        border-left: 1px solid #ddd;
        border-left: 1px solid var(--fc-border-color, #ddd);
        border-right: 1px solid #ddd;
        border-right: 1px solid var(--fc-border-color, #ddd);
      }
      .fc-daygrid-event {
        white-space: break-spaces;
      }
      .fc-event-main {
        padding: 4px 8px;
        cursor: pointer;
      }
      .fc-event-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
      }
      .fc-daygrid-day-number {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        text-transform: uppercase;
        color: #000000;
      }
      .fc-daygrid-block-event {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 8px;
      }
      .fc-day-other {
        background: #f5f5f5;
        .fc-daygrid-day-top {
          opacity: 1;
        }
      }
    }
    &.ResultsCalendarLoading {
      display: none;
    }
    &.ViewingCurrentMonth .fc-toToday-button,
    &.ViewingCurrentMonth .fc-toPrevious-button,
    &.ViewingFinalMonth .fc-toNext-button {
      pointer-events: none;
      touch-action: none;
      opacity: 0.5;
    }
    .fc-toolbar-chunk .fc-button {
      &,
      &:active {
        border: 1px solid #0091e9;
        background-color: white;
        color: #0177c0;
        text-transform: uppercase;
        font-size: 16px;
      }
      &.fc-button-primary:not(:disabled):active:focus {
        box-shadow: none;
      }
      &:hover:not(:disabled) {
        background-color: rgba(0, 0, 0, 0.04);
      }
      &:focus {
        box-shadow: none;
      }
    }

    .fc-daygrid .ResultsCalendar_SeeMore {
      background-color: white;
      border: none;
      .fc-event-title {
        color: black;
        text-decoration: underline;
      }
    }
    .SelfGuidedIndicator {
      display: inline;
      font-size: 8px;
      height: 1.5em;
      fill: white;
      margin-right: 0.3em;
      font-weight: $bold;
    }
    .VirtualIndicator {
      display: inline;
      font-size: 11px;
      fill: white;
      margin-right: 0.3em;
      font-weight: 700;
    }
  }

  .ResultsMonthList {
    display: none;
    @media (max-width: $mobileWidth) {
      display: block;
    }
    .ResultsMonthList_MonthSelect {
      .MuiInputBase-root {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
        color: #000000;
      }
      .MuiInput-underline:before {
        border-bottom: none;
      }
    }
    .ResultsMonthList_Days {
      margin-top: 20px;
    }
    .ResultsMonthList_Day {
      display: flex;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
    .ResultsMonthList_Day_Date {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 28px;
      width: 73px;
      box-sizing: border-box;
    }
    .ResultsMonthList_Day_Date_DayOfTheWeek {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 4px;
    }
    .ResultsMonthList_Day_Date_Number {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
      background: #f5f5f5;
      padding: 12px;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      box-sizing: border-box;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &.Today {
        background: #0177c0;
        color: #ffffff;
      }
    }
    .ResultsMonthList_Day_Events {
      display: flex;
      flex-direction: column;
      width: calc(100% - 73px);
    }
    .ResultsMonthList_Day_Event {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
      background: #ba68c8;
      border-radius: 8px;
      padding: 12px 8px;
      display: flex;
      flex-direction: column;
      width: 100%;
      cursor: pointer;
      box-sizing: border-box;
      &.Virtual {
        background: #0177c0;
      }
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
    .ResultsMonthList_Day_Event_Name .SelfGuidedIndicator {
      display: inline;
      font-size: 8px;
      height: 1.5em;
      fill: white;
      margin-right: 0.3em;
      font-weight: $bold;
    }
    .VirtualIndicator {
      display: inline;
      font-size: 12px;
      fill: white;
      margin-right: 0.3em;
      font-weight: 700;
    }
    .ResultsMonthList_Day_Event_TimeFrame {
    }
    .ResultsMonthList_Day_Event_Time {
    }
  }

  &.LIST {
    .ResultsCalendar,
    .ResultsMonthList {
      display: none;
    }
  }

  &.MONTH {
    .ResultsTable,
    .ResultsList {
      display: none;
    }
  }

  .EventSearchResults_Chip {
    background: #0177c0;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 150%;

    .MuiChip-label {
      padding: 3px 10px;
    }
  }

  .VirtualClubChip {
    width: 110px;
    font-size: 11px;
    font-weight: 900;
  }
}

.AboutPage {
  .AboutPage_Title {
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: #0177C0;
    padding-top: 28px;
    margin-bottom: 32px;
  }
  .AboutPage_ImagePanel {
    display: flex;
    img {
      border-radius: 8px;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
    &.AboutPage_ImagePanel2 {
      img {
        width: calc(50% - 10px);
        @media screen and (max-width: 490px) {
          width: 100%;
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
    &.AboutPage_ImagePanel3 {
      img {
        width: calc(33.3% - 20px / 3);
        @media screen and (max-width: 690px) {
          width: calc(50% - 10px);
          &:nth-child(3) {
            display: none;
          }
        }
        @media screen and (max-width: 490px) {
          width: 100%;
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
  .AboutPage_Section1 {
    font-weight: 800;
  }
  .AboutPage_Section1Items {
    color: #0177C0;
    margin-bottom: 20px;
    li {
      line-height: 25px;
      white-space: pre-wrap;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  .AboutPage_GeneralSection {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
  .AboutPage_GeneralTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #0177C0;
  }
  .AboutPage_Questions {
    & > li {
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
  .AboutPage_AnswerContent {
    margin-top: 16px;
  }
  .AboutPage_AnswerItems {
    padding-left: 3ch;
  }
  .AboutPage_AnswerContent,
  .AboutPage_AnswerItems {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    a {
      text-decoration: none;
      font-weight: 800;
      color: black;
    }
  }
}
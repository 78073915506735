@import "../../styles/common.scss";

.ClubAgreementPage {
  table {
    width: 800px;
  }
  .SignatureBlockInstructions {
    display: none;
  }
  .SignatureBlockWrapper {
    display: none;
    @include media("<=phone") {
      flex-direction: column;
    }
  }
  .SignatureBlock {
    display: flex;
    @include media("<=phone") {
      margin-top: 1em;
      flex-direction: column;
    }
  }
  .SignatureBlock table {
    width: 400px;
    margin-right: 1em;
    @include media("<=phone") {
      width: 300px;
    }
  }
  .underline {
    margin-left: 1em;
    border-bottom: 1px solid black;
    width: 300px;
  }
}
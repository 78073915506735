@import "../../styles/common.scss";

.CustomEnrollmentRegistrationComplete {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  .CustomEnrollmentRegistrationComplete_Line1 {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    text-shadow: 0 0.25px, 0.25px 0, 0.25px 0.25px;
  }
  .CustomEnrollmentRegistrationComplete_Line2 {
    margin-bottom: 2em;
  }
  .CustomEnrollmentRegistrationComplete_Button {
    background: #1e2452;
    font-size: 14px;
    letter-spacing: 0.75px;
    padding: 12px 24px;
    margin-bottom: 2em;
  }
  .CustomEnrollmentRegistrationComplete_Button_Icon {
    margin-left: 6px;
    width: 5px;
  }
}

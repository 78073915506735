@import "../../styles/common.scss";

.FindAnEventPage {
  padding-top: 24px;

  .HaveQuestionsWrapper {
    margin-top: 80px;
    padding-bottom: 40px;
  }

  .FeaturedEvents {
    margin-bottom: 60px;
  }

  .SearchingErrorMessage {
    margin-top: 1.5em;
    color: $errored;
  }

  .EventSearchFields {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .EventSearchPage_FormControl {
    width: 100%;
  }

  .EventSearchPage_FormControl_Row1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .SectionHeader {
      margin-bottom: 0;
    }
    .EventSearchPage_FormControl_Row1_Right {
      display: flex;
      .EventSearchPage_FormControl_Location {
        display: flex;
        align-items: center;
        .EventSearchPage_FormControl_LocationIcon {
          color: #0177C0;
          margin-right: 6px;
        }
        .MuiInputBase-input {
          display: flex;
          align-items: center;
          padding-top: 9px;
          padding-bottom: 9px;
          min-height: 24px;
          font-weight: 600;
        }
        .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
          // To account for the change in padding
          transform: translate(14px, 14px) scale(1);
        }
        .CCButton {
          padding: 8px 22px;
        }
      }
    }
  }
  .EventSearchPage_FormControl_Row2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .EventSearchPage_FormControl_Row2_Left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .EventSearchPage_PostalCodeInput {
    flex-grow: 1;
  }

  .EventSearchPage_FormControl_VirtualOnly {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .EventSearchPage_ZipCodeWrapper {
    width: 8em;
  }

  .FindAnEventForm_DistanceWrapper {
    margin: 0 6px;
    span {
      color: rgba(0, 0, 0, 0.54);
      visibility: visible;
      transform: translateY(-20%);
    }
  }

  .TodayButton {
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    color: #0491E8;
    border-color: rgba(25, 118, 210, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 6px 8px;
    &.CCButton_Rollover {
      box-shadow: none;
    }
  }

  .FindAnEventForm_SubmitButton {
    color: #0177C0;
    text-transform: uppercase;
    font-size: 16px;
  }

  .CCLoading {
    margin: 30px auto;
  }

  .SearchButton {
    margin-left: 1em;
  }

  .TotalResultCount {
    margin-top: 2em;
    margin-bottom: 1em;
    font-weight: $semiBold;
    font-style: italic;
    color: $primaryAccent;
  }

  $tabletWidth: 768px;
  $mobileWidth: 600px;

  .PreviousAndNextButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      margin: 1em;
      min-width: 190px;
      padding-right: 1em;
      padding-left: 1em;
      .CCButton_ChildContainer {
        justify-content: center;
      }
    }
  }

  @media (max-width: $mobileWidth) {
    .EventSearchPage_FormControl_Row1 {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 16px;
      .SectionHeader {
        margin-bottom: 20px;
      }
      .SectionHeader_Title {
        color: #000000;
      }
    }
    .EventSearchPage_FormControl_Row2 {
      flex-direction: column;
      align-items: flex-start;
    }
    .EventSearchPage_FormControl_Row2_Left {
      margin-bottom: 16px;
    }
  }
}

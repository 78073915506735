@import "../../styles/common.scss";
@import "../../styles/colors.scss";

$footerBorderTopSize: 1px;
$footerHeight: 208px;
$footerContentMarginTop: 40px;
$footerContentWidth: $desktopPageContentWidth * 1px;
$footerContentMobileMarginTop: 40px;
$footerContentMobileWidth: $desktopPageContentWidth;
$footerContentMobileLogoPaddingLeft: 10px;
$logoAndCopyrightWidth: 260px;
$logoWidth: 150px;
$logoWidth_Mobile: 150px;
$translateLogoHeightWithPadding: 30px;

.Footer {
  height: $footerHeight;
  border-top: $footerBorderTopSize solid $softBorder;
  margin-top: 30px;
  @include media("<=phone") {
    height: 410px;
  }
}

.Footer .TranslateLogo {
  position: absolute;
  top: -$footerContentMarginTop - $translateLogoHeightWithPadding;
  right: 0;
  padding: 7px 0;
}

.Footer .FooterContent {
  position: relative;
  margin-top: $footerContentMarginTop;
  width: $footerContentWidth;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  @include media("<=phone") {
    display: none;
  }
}

.Footer .FooterContentMobile {
  margin-top: $footerContentMobileMarginTop;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  @include media(">phone") {
    display: none;
  }
}

.Footer .LogoAndCopyright {
  width: $logoAndCopyrightWidth;
  display: flex;
  flex-direction: column;
  /// the other footer blocks have line heights, so the text starts a tad under
  /// their position
  /// push down to line up the top of the logo with the other footer blocks on desktop
  margin-top: 5px;
  @include media("<=phone") {
    padding-left: $pageMarginHorizontal_Mobile * 1px;
    margin-top: 0px;
  }
}

.Footer .FooterLink {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  font-weight: $semiBold;
  color: $foreground;
}

.Footer .Logo {
  width: $logoWidth;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  @include media("<=phone") {
    width: $logoWidth_Mobile;
  }
}
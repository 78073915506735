@import "../../styles/common.scss";

.LoadingMessage
{
  height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoadingMessage_Message {
  font-size: $mediumHeadlineTextSize;
  display: flex;
  color: $errored;
  @include media("<=phone") {
    text-align: center;
  }
}

.LoadingMessage_LoginButton {
  margin-top: 3em;
  display: flex;
}
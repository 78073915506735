@import "../../styles/common.scss";

.FindAClubPage {

  .FindAClubForm {
    margin-top: 1.5em;
  }

  .SearchingErrorMessage {
    margin-top: 1.5em;
    color: $errored;
  }


  .ClubSearchFields {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include media("<=phone") {
      width: 100%;
      flex-direction: column;
    }
  }

  .ClubSearchPage_PassionSelectWrapper {
    @include media("<=phone") {
      width: 100%;
    }
  }

  .ClubSearchPage_PassionSelect {
    width: 15em;
    @include media("<=phone") {
      width: 100%;
    }
  }

  .ClubSearchPage_FormControl {
    @include media("<=phone") {
      width: 100%;
    }
  }

  .ClubSearchPage_PostalCodeInput {
    flex-grow: 1;
  }

  .ClubSearchPage_ZipCodeWrapper {
    width: 8em;
    @include media("<=phone") {
      margin-top: 1em;
      width: 100%;
    }
  }

  .ClubSearchPage_ZipCodeWrapper .MuiFormControl-root {
    @include media("<=phone") {
      width: 100%;
    }
  }

  .ClubsNearText {
    margin-left: 1em;
    margin-right: 1em;
    white-space: nowrap;
    @include media("<=phone") {
      margin-top: 1em;
      text-align: center;
      width: 100%;
    }
  }

  .SearchButton {
    margin-left: 1em;
    @include media("<=phone") {
      margin-left: 0;
      margin-top: 1em;
      width: 100%;
    }
  }

  .TotalResultCount {
    margin-top: 2em;
    margin-bottom: 1em;
    font-weight: $semiBold;
    font-style: italic;
    color: $primaryAccent;
  }

  .Results {

    hr {
      margin: 1em 0;
    }

    .Result {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ClubName {
        color: $primaryAccent;
        font-size: $largeTextSize;
        font-weight: $semiBold;
        flex-basis: 25%;
      }

      .Passion {
        margin-left: 1em;
        flex-basis: 25%;
        @include media("<=phone") {
          margin-left: 0;
        }
      }

      .Location {
        margin-left: 1em;
        flex-basis: 25%;
        @include media("<=phone") {
          margin-left: 0;
        }
      }

      .Events {
        margin-left: 1em;
        flex-basis: 25%;
        text-align:right;
        @include media("<=phone") {
          margin-left: 0;
          text-align:inherit;
        }
      }

      .Events .CCButton {
        justify-self: flex-end;
      }
    }
  }

  .PreviousAndNextButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .PreviousButton, .NextButton {
      margin: 1em;
    }
  }
}

.SectionHeader {
  margin-bottom: 20px;
  .SectionHeader_Title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */
    color: #0177C0;
  }
  .SectionHeader_Subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #3A3A3A;
    margin-top: 4px;
  }
}

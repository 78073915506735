@import "../../../styles/ignite/common.scss";

.members-tab-content {
    .button-wrapper {
        .description {
            font-size: 16px;
            text-align: center;
        }

        .action-button {
            background: #666;
            color: #fff;
            font-size: 16px;
            font-weight: 800;
            padding: 8px 15px;
            height: 40px;
            white-space: nowrap;

            &.red {
                background: #D4383A;
            }

            &.disabled {
                background: #898989;
            }

            .icon {
                width: 15px;
                height: 15px;
                margin-right: 8px;
            }
        }

        @include media("<=sm") {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            .action-button {
                margin-left: 10px;
            }
        }
    }

    .members-tabs {
        margin-bottom: 10px;

        .dashboard-tab {
            font-size: 15px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .tab-subtitle {
        font-size: 18px;
        font-weight: 800;
        text-transform: capitalize;
    }

    .members-list {
        padding: 5px;
        border: none;
        border-radius: 0;
        overflow-x: hidden;
        max-height: 620px;

        @include media("<=md") {
            padding: 0;
        }
    }

    .sorting__order {
        @include media("<=md") {
            padding: 15px 5px;
        }
    }

    .tab-navigation {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 800;
        text-transform: uppercase;

        .arrow-icon {
            width: 40px;
            height: 50px;

            &:first-child {
                margin-right: 10px;
            }

            &:last-child {
                margin-left: 20px;
            }

            @include media("<=sm") {
                width: 30px;
                height: 40px;

                &:first-child {
                    margin-right: 0px;
                }

                &:last-child {
                    margin-left: 5px;
                }
            }
        }

        @include media("<=sm") {
            font-size: 18px;
        }
    }

    .search-input {
        width: 350px;
        height: 40px;
        max-width: 100%;

        .search-icon {
            fill: rgba(58, 58, 58, 0.70);
        }
    }
}

@import "../../../styles/ignite/common.scss";

.clubs {
    padding: 40px 50px !important;

    .top-content {
        margin-bottom: 0 !important;
    }

    .noClubs {
        text-align: center;
    }

    .bottom-content {
        margin-top: 16px;
        border: none;
    }

    .club-list {
        padding: 0 0 5px;
        width: 100%;

        .club-item {
            border: 1px solid $softBorder;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            padding: 30px 10px;

            .left-content {
                display: flex;
                gap: 15px;
                align-items: center;
                cursor: pointer;
            }

            .Events_Link {
                font-size: 14px;
                font-weight: 700;
                line-height: 19px;
                text-decoration: underline;
                cursor: pointer;
                display: flex;
              }

            .ClubView_Chip {
                background: #0177C0;
                color: #FFFFFF;
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.15px;
                line-height: 150%;

                .MuiChip-label {
                    padding: 3px 10px;
                }
            }

            &__description {}

            .right-content {
                text-align: right;
            }

            &__role {
                font-size: 14px;
                font-weight: 800;
                text-transform: uppercase;
                margin-bottom: 30px;
            }

            &__action {
                color: #0177C0;
                font-size: 16px;
                font-weight: 700;
                cursor: pointer;
            }

            &__name {
                font-size: 16px;
                font-weight: 800;
            }

            &__location,
            &__leader {
                font-size: 16px;
                font-weight: 600;
            }

            &__image {
                max-width: 60px;
                max-height: 60px;
                clip-path: circle();
            }

            @include media("<=sm") {
                gap: 15px;
            }
        }

        @include media("<=phone") {
            border: none;
        }
    }

    .not-right-club {
        text-align: center;
        background: $callout;
        padding: 2em 0;
        margin-top: 3.2em;
        position: absolute;
        left: 0;
        width: 100vw;
        line-height: 1.5;

        &__finder {
            font-size: 24px;
            text-decoration: underline;
            font-weight: 800;
            color: #000;
        }
    }

    @include media("<=sm") {
        padding: 10px !important;
    }
}

.leave-club-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .leave-club-modal-wrapper {
        width: 500px;
        background: #fff;
        padding: 32px;
        box-sizing: border-box;
        position: relative;
        max-height: 100%;
        overflow: auto;

        .content {
            display: flex;
            flex-direction: column;

            .title {
                color: #0177C0;
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .description {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 40px;
                color: #000;
            }

            .main-text {
                flex-grow: 1;
            }

            .button-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .warning-block {
                margin: 20px 0;

                .subtitle {
                    font-weight: 600;
                    margin-bottom: 10px;
                }
            }

            .button {
                height: 40px;
                font-size: 16px;
                font-weight: 800;
                padding: 7px 22px;

                &.confirm {
                    background: #0280CD;
                    text-transform: uppercase;
                    color: #fff;
                    margin: 20px 0;
                }

                &.cancel {
                    border: 1px solid #0280CD;
                    color: #0280CD;
                }

                &.disabled {
                    background: #ccc;
                }
            }
        }

        .warning-block {
            border-radius: 4px;
            border: 2px solid #D4383A;
            background: rgba(212, 56, 58, 0.07);
            padding: 16px 24px;
            margin-bottom: 48px;
            margin-top: 48px;

            .title {
                color: #000;
                font-size: 14px;
                font-weight: 700;
            }

            .description {
                font-size: 14px;
                font-weight: 600;
            }
        }

        .close-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }

        @include media("<=sm") {
            padding: 20px;
            margin: 10px;
        }

        .change-role {
            &__select {
                width: 300px;
            }
        }
    }
}
@import "../../styles/colors.scss";

.LocationPicker_error {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: $clubDirectory_red;
}

.LocationPickerFilter .LocationPicker_FormWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.LocationPickerFilter_SubmitButton {
    width: 70px;
    height: 40px;
}

// < 1168 (magic number in order to break at "Within" at design's request)
@media (min-width: 768px) and (max-width: 1168px) {
    .LocationPickerFilter .LocationPicker_FormWrapper {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 7px;
    }

    .LocationPicker_InlineText:first-child {
        flex-basis: 100%;
        display: block;
    }
}

@media (max-width: 767px) {
    .LocationPicker_Container {
        max-width: 400px;
    }
}

@media (max-width: 455px) {
    .LocationPickerFilter .LocationPicker_FormWrapper {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 7px;
    }

    .LocationPicker_InlineText:first-child {
        flex-basis: 100%;
        display: block;
    }
}

.LocationPicker_InlineText {
    font-size: 14px;
    font-weight: 700;
    color: $clubDirectory_mediumText;
}

.CD_Textfield.MuiFormControl-root {
    width: 127px;
    min-width: 127px;
    background: #ffffff;
    border-radius: 80px;

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(188, 188, 188, 1);
        border-radius: 80px;
    }

    .MuiOutlinedInput-input {
        padding: 11.5px 16px 11.5px 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: rgba(0, 0, 0, .6);
    }

    // label
    .MuiInputLabel-outlined {
        transform: translate(19px, 13px) scale(1);
        opacity: 1;
    }

    // focused label
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(19px, 13px) scale(1);
        opacity: 0;
    }

    .MuiOutlinedInput-notchedOutline legend {
        display: none;
    }

    fieldset.MuiOutlinedInput-notchedOutline {
        top: 0;
    }

}

.CD_Select.MuiOutlinedInput-root {

    background: #ffffff;
    border-radius: 80px;
    width: 94px;

    .MuiSvgIcon-root {
        position: absolute;
        right: 9px;
    }

    .MuiOutlinedInput-input {
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;
        padding: 11.5px 14px;
        background: white;
        border-radius: 80px;
    }

    .MuiOutlinedInput-notchedOutline {
        top: 0;

        legend {
            display: none;
        }
    }
}
@import "../../styles/common.scss";

.MyEventsPage_WelcomeBack {
  font-size: $mediumHeadlineTextSize;
  line-height: 33px;
  font-weight: $bold;
  margin-top: .5em;
}

.MyEventsPage_MyEventsSeparator {
  margin-top: 2em;
  @include media("<=phone") {
    margin-top: 1em;
  }
}

.MyEventsPage_MoreEventsSeparator {
  margin-top: 2em;
  @include media("<=phone") {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.MyEventPage_FindMoreEvents {
  display: flex;
  background-color: $primaryAccent;
  color: $primaryAccentForeground;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  @include media("<=phone") {
    flex-direction: column;
  }
}

.MyEventPage_FindMoreEventsTitle {
  font-size: $largeTextSize;
  font-weight: $semiBold;
  margin-left: 100px;
  @include media("<=phone") {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.MyEventPage_FindMoreEvents div {
  display: flex;
}

.MyEventPage_FindMoreEventsForm .FindAClubForm_FormControl,
.MyEventPage_FindMoreEventsForm .FindAnEventForm_FormControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 100px;
  @include media("<=phone") {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.MyEventPage_FindMoreEventsForm .FindAClubForm_FormControl .MuiSelect-selectMenu,
.MyEventPage_FindMoreEventsForm .FindAnEventForm_FormControl .MuiSelect-selectMenu {
  background-color: $background ! important;
  width: 100%;
}

.MyEventPage_FindMoreEventsForm .FindAClubForm_FormControl .ClubSearchPage_PassionSelect,
.MyEventPage_FindMoreEventsForm .FindAnEventForm_FormControl .EventSearchPage_PassionSelect {
  width: 100%;
}

.MyEventPage_FindMoreEventsForm .FindAClubForm_FormControl .MuiOutlinedInput-input,
.MyEventPage_FindMoreEventsForm .FindAnEventForm_FormControl .MuiOutlinedInput-input {
  background-color: $background ! important;
}

.MyEventPage_FindMoreEventsForm .FindAClubForm_ZipCodeWrapper,
.MyEventPage_FindMoreEventsForm .FindAnEventForm_ZipCodeWrapper {
  margin-top: 10px;
}

.MyEventPage_FindMoreEventsForm .FindAClubForm_SearchFields,
.MyEventPage_FindMoreEventsForm .FindAnEventForm_SearchFields {
  flex-direction: column;
}

.MyEventPage_FindMoreEventsForm .FindAClubForm_SubmitButtonWrapper,
.MyEventPage_FindMoreEventsForm .FindAnEventForm_SubmitButtonWrapper {
  margin-left: 50px;
  @include media("<=phone") {
    margin-left: 0;
    margin-top: 20px;
  }
}

.MyEventsPage_HeaderLine {
  display: flex;
  justify-content: space-between;
  @include media("<=phone") {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.MyEventsPage_UpcomingEventsHeader {
  margin-top: 40px;
}

.MyEventsPage {
  .MyEventsPage_FeaturedEventsHeader {
    margin-top: 40px;
    @include media("<=phone") {
      margin-bottom: 0;
    }
  }

  .MyEventsPage_HaveQuestions {
    margin-top: 62px;
    margin-bottom: 62px;
    @include media("<=phone") {
      margin-top: 40px;
      margin-bottom: 45px;
    }
  }

  .MyEventsPage_FindVirtualEvents {
    background: #F5F5F5;
    border-radius: 16px;
    width: calc(100% - 54px);
    min-height: 96px;
    margin: 42px auto;
    padding: 37px 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media("<=phone") {
      padding: 20px;
      flex-direction: column;
    }
  }

  .MyEventsPage_FindVirtualEvents_Top {
    font-size: 16px;
    line-height: 22px;
    color: #3A3A3A;
  }

  .MyEventsPage_FindVirtualEvents_Button {
    margin-left: 50px;
    background: #0177C0;
    @include media("<=phone") {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .MyEvents {
    margin-bottom: 42px;
    & + .MyEventsPage_HaveQuestions {
      margin-top: 0;
    }
  }

  & > :last-child {
    margin-bottom: 60px;
  }

  .EventSearchResults_NoResultsWrapper {
    display: none;
  }
}

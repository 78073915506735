@import "../../../styles/ignite/common.scss";

.club-dues-plan-options {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @include media("<=phone") {
        flex-wrap: wrap;
    }

    .group-label {
        margin-right: 16px;
    }
}

@import "../../styles/common.scss";
@import "../../styles/colors.scss";

$contactUsWidth: 200px;
$contactUsMarginLeft: 80px;
$contactUsPaddingLeft_Mobile: 10px;
$contactUsPaddingTop_Mobile: 25px;
$contactUsWidth_Mobile: 100%;
$contactUsMarginBottom_Mobile: 20px;

.ContactUsCallout {
  font-weight: bold;
  margin-bottom: 8px;
}

.ContactUs a {
  margin-bottom: 8px;
}

.ContactUs {
  display: flex;
  flex-direction: column;
  width: $contactUsWidth;
  margin-left: $contactUsMarginLeft;
  @include media("<=phone") {
    padding-left: $pageMarginHorizontal_Mobile * 1px;
    padding-top: $contactUsPaddingTop_Mobile;
    width: 100%;
    margin-left: 0;
    margin-bottom: $contactUsMarginBottom_Mobile;
  }
}

.ContactUs .EmailIcon {
  margin-right: 3px;
}

.ContactUs .PhoneIcon {
  margin-right: 3px;
}



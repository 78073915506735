@import "../../styles/common.scss";

.EventViewWithDetails .EventDescription {
  margin-top: 1.5em;
  font-size: $largeTextSize;
  white-space: pre-line;
  @include media("<=phone") {
    margin-top: 1em;
  }
  @include media(">phone") {
    width: 67%;
  }
}

.EventViewWithDetails {
  .ImportantDetails {
    font-size: $largeTextSize;
    margin-top: .5em;
    @include media(">phone") {
      width: 85%;
    }
  }
}

.EventViewWithDetails {
  .SelfGuidedMessage {
    font-size: $largeTextSize;
    margin-top: 2em;
    margin-bottom: 1em;
    @include media(">phone") {
      width: 85%;
      margin-top: 1.5em;
    }
  }
  .WhatYoullNeed {
    font-size: $largeTextSize;
    margin-top: 2em;
    margin-bottom: 1em;
    @include media(">phone") {
      width: 85%;
      margin-top: 1.5em;
    }
  }
}

.EventViewWithDetails a {
  color: $foreground;
  text-decoration: none;
}


.EventViewWithDetails a svg {
  color: $secondaryAccent;
  margin-right: .2em;
  position: relative;
  top: .2em;
}

.EventViewWithDetails_DetailsBox {
  margin-top: 1.5em;
  @include media("<=phone") {
  }
}

.EventView_CountMeInButton {
  display: flex;
  margin-top: -40px;
  align-self:center;
  margin-left: auto;
  margin-right: auto;
}


.EventLandingPage_LandingBottom_CountMeInButton {
  margin-top: 1em;
}


.EventViewWithDetails_RsvpConfirmedBottom_ButtonContainer {
  display: flex;
  margin-top: 4em;
  justify-content: center;
  @include media("<=phone") {
    margin-top: 2em;
    flex-direction: column;
  }
}

.EventViewWithDetails_RsvpConfirmedBottom_GoToMyEventsButton {
  margin-right: 1em;
  @include media("<=phone") {
    margin-right: 0;
  }
}

.EventViewWithDetails_RsvpConfirmedBottom_FindMoreEventsButton {
  @include media("<=phone") {
    margin-top: 1em;
  }
}

.EventViewWithDetails_RsvpConfirmedBottom_ChangedMindContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.EventViewWithDetails_RsvpConfirmedBottom_ChangedMind {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.EventViewWithDetails_RsvpConfirmedBottom_ChangedMindButton {
  margin-top: 1em;
  display: flex;
}


.EventViewWithDetails_RsvpCancelled {
  display: flex;
  flex-direction: column;

  a {
    color: $primaryAccent;
    text-decoration: none;
    font-weight: $bold;
  }
}

.EventViewWithDetails_RsvpCancelled_Title {
  display: flex;
  color: $primaryAccent;
  font-size: $largeHeadlineTextSize;
  margin-top: 1em;
  font-weight: $semiBold;
}

.EventViewWithDetails_RsvpCancelled_Body {
  display: flex;
  margin-top: 2em;
  margin-bottom: 2em;
  @include media("<=phone") {
    margin-top: 1em;
    flex-direction: column;
  }
}

.EventViewWithDetails_RsvpCancelled_Messsage {
  margin-right: 3em;
}

.EventViewWithDetails_RsvpCancelled_Link {
  display: inline;
}

.EventViewWithDetails_RsvpCancelled_LinksContainer {
  display: flex;
  flex-direction: column;
  @include media("<=phone") {
    margin-top: -.5em;
  }
}

.EventViewWithDetails_RsvpCancelled_MoreClubEvents {
  display: flex;
}

.EventViewWithDetails_RsvpCancelled_MoreClubEvents a {
  display: flex;
  font-size: $largeTextSize;
  color: $foreground;
  font-weight: $semiBold;
}

.EventViewWithDetails_RsvpCancelled_MoreClubEvents svg {
  display: flex;
  margin-right: .25em;
  color: $primaryAccent;
}

.EventViewWithDetails_RsvpCancelled_MorePassionEvents {
  color: $foreground;
  display: flex;
  margin-top: 1em;
}

.EventViewWithDetails_RsvpCancelled_MorePassionEvents a {
  color: $foreground;
  font-size: $largeTextSize;
  display: flex;
  font-weight: $semiBold;
}

.EventViewWithDetails_RsvpCancelled_MorePassionEvents svg{
  display: flex;
  margin-right: .25em;
  color: $primaryAccent;
}

.EventViewWithDetails_RsvpCancelled_GoToMyEventsButton {
  display: flex;
  align-self: center;
  @include media("<=phone") {
    margin-top: 1em;
    align-self: flex-start;
  }
}

.EventViewWithDetails_ClubName {
  padding-right: 0.5em;
}

.EventViewWithDetails_Chip {
  background-color: #0177C0 !important;
  color: #FFFFFF !important;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 150%;
  .MuiChip-label {
    padding: 3px 10px;
  }
}


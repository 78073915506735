@import "../../styles/common.scss";

.SelectPassionsModal {
  width: 579px;
  height: 507px;
  max-width: 90vw;
  max-height: 90vh;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 16px;
  margin: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  .SelectPassionsModal_Close {
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    cursor: pointer;
    top: 29px;
    right: 29px;
  }
  .SelectPassionsModal_Passions {
    height: 318px;
    margin-bottom: 20px;
    overflow-y: auto;
    flex-basis: 100%;
    display: flex;
    .SelectPassionsModal_Passions_Column {
      display: flex;
      flex-direction: column;
    }
  }
  .SelectPassionsModal_Passion {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-left: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .SelectPassionsModal_Panel {
    display: flex;
    justify-content: space-between;
  }
  .SelectPassionsModal_CheckboxContainer {
    flex-basis: 50%;
    display: flex;
    align-items: center;
  }
  .SelectPassionsModal_Panel_Right {
    flex-basis: 50%;
    display: flex;
    flex-direction: row-reverse;
  }
  .SelectPassionsModal_Submit {
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    padding: 7px 22px;
  }
  .SelectPassionsModal_Cancel {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #0177C0;
    margin-right: 8px;
    text-transform: uppercase;
  }
  @include media("<=phone") {
    width: 390px;
    height: 844px;
    .SelectPassionsModal_Passions {
      flex-direction: column;
      margin-bottom: 0;
    }
    .SelectPassionsModal_Panel {
      border-top: 1px solid #BDBDBD;
      flex-direction: column;
      align-items: center;
      width: calc(100% + 48px);
      margin-left: -24px;
      padding-left: 24px;
      padding-right: 24px;
      box-sizing: border-box;
    }
    .SelectPassionsModal_Panel_Right {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .SelectPassionsModal_CheckboxContainer {
      margin-top: 20px;
    }
    .SelectPassionsModal_Submit {
      margin-top: 12px;
      width: 100%;
      text-align: center;
      .CCButton_ChildContainer {
        display: inline-flex;
      }
    }
    .SelectPassionsModal_Cancel {
      margin-top: 14px;
      margin-right: 0;
    }
  }
}

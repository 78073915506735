@import "../../../styles/common.scss";

.MemberSelectPayerPage {
  @include media(">phone") {
    width: 50%;
    margin: 0 auto;

    > h1 {
      text-align: center;
    }
  }

  .MemberSelectPayerPage_Form {
  }

  .MemberSelectPayerPage_FormErrors {
    margin-top: 1em;
  }

  .MemberSelectPayerPage_FormError {
    color: red;
  }

  .MemberSelectPayerPage_Fields {
    display: flex;
    flex-direction: column;
  }

  .MemberSelectPayerPage_PayerSelect {
  }

  .MemberSelectPayerPage_InsuranceIdField {
    margin-top: 1em;
  }

  .MemberSelectPayerPage_SubmitButton {
    margin: 1em auto 0;
  }
}

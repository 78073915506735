
@import "../../../styles/common.scss";

.ClubJoinedModal {
    .title {
        font-weight: 700;
    }

    .actions {
        padding: 16px;
        @include media("<=sm") {
            padding: 8px;
        }
    }

    .content {
        padding-top: 0;
        padding-bottom: 0;
    }
    
}
@import "../../../styles/common.scss";

.leader-onboarding {
    max-width: 720px;
    margin: 0 auto;
    min-height: 15em;
    padding: 0;
    background: #fff;
    margin-top: 110px;

    @include media("<=phone") {
        margin: 0 auto 20px;
    }

    &-top-wrapper {
        background: #ebebeb;
        padding: 24px 20px;
        font-weight: 400;
        text-align: center;
        font-size: 18px;
        position: relative;

        .icon {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }

    &-top {
        max-width: 580px;
        margin: 0 auto;

        &__title {
            text-align: center;
            margin: 0 0 25px 0;
            white-space: pre-line;

            @include media("<=phone") {
                font-size: 18px;
                padding: 0 20px;
            }
        }

        &__subtitle {
            margin-top: 20px;
        }

        &__link {
            color: #000;
            margin-left: 10px;
            font-weight: 600;
        }
    }

    .registration-form {
        padding: 20px 80px;
        border: none;

        &__button-wrapper {
            display: flex;
            align-items: center;
        }

        @include media("<=phone") {
            padding: 20px;
        }
    }
}

.leader-registration-modal {
    overflow-y: auto;
}

.leader-onboarding-footer {
    background: #ebebeb;
    padding: 23px 20px;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
}

.PassionImage {
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position-y: center;
  border-radius: 8px;
  &.Wide {
    width: 100%;
    padding-bottom: 40.57%;
    background-size: cover;
    background-position-y: center;
    border-radius: 8px;
  }
}
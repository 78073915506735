@import "../../../../../styles/ignite/common.scss";
.GroupWorks {
    .button-wrapper {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        @include media("<=600px") {
            flex-direction: column;
            row-gap: 24px;
        }
        .button {
            font-weight: 700;
        }
    }
}
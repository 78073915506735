@import "../../styles/common.scss";

.CCEventViewFullDesktop {
  position: relative;

  .CCEventViewFullDesktop_Presents {
    font-style: italic;
    max-width: 25em;
  }

  .CCEventViewFullDesktop_EventName {
    font-weight: $bold;
    font-size: $mediumHeadlineTextSize;
    margin-top: .4em;
    max-width: 20em;
  }

  .CCEventViewFullDesktop_EventDescription {
    margin-top: .4em;
    white-space: pre-wrap;
  }

  .CCEventViewFullDesktop_Extra {
    font-weight: $bold;
    margin-top: .15em;
    width: 80%;
  }

  .CCEventViewFullDesktop_CountMeInButton {
    position: absolute;
    bottom: 50px;
  }

  .CCEventViewFullDesktop_SelfGuided {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}


@import "../../../styles/ignite/common.scss";

.invite-members-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    .invite-members-modal-wrapper {
        max-width: 785px;
        min-height: 450px;
        background: #fff;
        padding: 30px 50px;
        box-sizing: border-box;
        position: relative;
        max-height: 100vh;
        overflow-y: auto;

        .title {
            font-size: 24px;
            font-weight: 600;
            color: #0177C0;
            padding-left: 20px;
        }

        .content {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .selected-members {
                border: 1px solid $softBorder;
                width: 100%;
                min-height: 100px;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                padding: 10px;
                box-sizing: border-box;

                .selected-item {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    padding: 8px 32px 8px 16px;
                    border: 1px solid #0177C0;
                    border-radius: 20px;
                    box-sizing: border-box;
                    position: relative;

                    .name {
                        font-size: 14px;
                        color: #0177C0;
                        font-weight: 600;
                    }

                    .remove {
                        position: absolute;
                        color: #0177C0;
                        top: 7px;
                        right: 12px;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }

            .button-wrapper {
                display: flex;
                justify-content: flex-end;

                .button {
                    margin-top: 15px;
                    background: $primaryDark;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 800;
                    padding: 4px 20px;

                    &.disabled {
                        background: #ccc;
                    }
                }
            }

            .content-inner {
                padding: 20px;
                border: 1px solid $softBorder;
                border-radius: 4px;
                margin-top: 20px;

                .subtitle {
                    font-size: 18px;
                    font-weight: 600;
                    padding: 0 0 20px 0;
                }

                .main-part {
                    background: #fff;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.5;

                    .top-part {
                        white-space: pre-line;
                    }

                    .bottom-part {
                        margin-top: 20px;
                    }

                    .text {
                        .list {
                            display: flex;
                            flex-direction: column;
                            font-weight: 700;
                            padding-left: 30px;
                            margin-top: 0;

                            .item {
                                width: auto;
                            }
                        }
                    }
                }

                .link-wrapper {
                    margin-top: 15px;

                    .link {
                        text-transform: uppercase;
                        color: $primaryDark;
                        font-size: 16px;
                        font-weight: 800;
                    }
                }
            }

            .description {
                font-size: 14px;
                align-self: flex-start;
                margin-top: 20px;
            }

            .button {
                width: 265px;
                height: 40px;
                font-size: 18px;
                font-weight: 800;

                &.confirm {
                    background: #0280CD;

                    text-transform: uppercase;
                    color: #fff;
                    margin: 20px 0;
                }

                &.cancel {
                    border: 1px solid #0280CD;
                    color: #0280CD;
                }

                .icon {
                    margin-left: 15px;
                }
            }
        }

        .close-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }

        @include media("<=sm") {
            padding: 30px 10px;
        }
    }
}

@import "../../styles/common.scss";

.EventListDateTimeItem {
  flex-direction: column;
  @include media("<=phone") {
  }
}

.EventListDateTimeItem_StartsAtTime .CCDateView {
  display: flex;
  flex-shrink: 0;
  max-width: 100%;
}

.EventListDateTimeItem_StartsAtDate .CCDateView {
  display: flex;
  flex-shrink: 0;
  max-width: 100%;
}


@import "../../../styles/ignite/common.scss";

.pagination {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #ebebeb;

    &-item {
        color: #0067A6;
        font-size: 16px;
        font-weight: bold;
        margin-right: 12px;
        cursor: pointer;

        &.selected {
            color: #000;
        }
    }

    &-icon {
        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            color: gray;
        }
    }
}

@import "../../../styles/ignite/common.scss";

.leader-dashboard-page {
    max-width: 100vw;
    overflow: hidden;

    .dashboard-tabs-indicator {
        background-color: #000;
        margin-bottom: 5px;
        height: 5px;
    }

    .header-tabs {
        @include media("<=md") {
            display: none;
        }
    }

    .dashboard-tab {
        color: #000;
        text-transform: none;
        min-width: auto;
        font-size: 18px;
        font-weight: 600;
        opacity: 1;

        &.selected {
            color: #000;
            font-weight: 800;
        }
    }

    .leader-dashboard-content {
        // this matches the tablet max width/medium breakpoint
        max-width: 855px;
        margin: 20px auto 40px;
        display: flex;
        flex-direction: column;

        .top-content {
            border: none;
        }

        .bottom-content {
            display: flex;
            border: none;

            .members {
                width: 600px;
                margin-right: 5px;

                .title {
                    font-size: 32px;
                    font-weight: 800;
                    padding-left: 30px;
                    display: flex;

                    @include media("<=md") {
                        font-size: 24px;
                        margin: 0;
                        padding-left: 0;
                    }
                }

                .list-title {
                    font-size: 32px;
                    font-weight: bold;
                    margin-top: 40px;
                }

                .sorting {
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid $softBorder;
                    border-radius: 4px;
                    padding: 20px 15px 10px 20px;

                    &__text {
                        font-weight: bold;
                        font-size: 18px;
                        text-transform: uppercase;
                    }

                    &__help-text {
                        margin-right: 5px;
                    }

                    &__order {
                        display: flex;
                    }

                    .select::before {
                        display: none;
                    }

                    .search-icon-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: 14px;
                        cursor: pointer;
                        text-transform: uppercase;
                        border-left: 1px solid $softBorder;
                        padding-left: 10px;

                        .icon {
                            width: 24px;
                            height: 24px;
                            color: #898989;
                        }
                    }
                }

                @include media("<=md") {
                    width: 100%;
                }

                .list-title {
                    font-size: 32px;
                    font-weight: bold;
                    margin-top: 40px;
                }

                .sorting {
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid $softBorder;
                    border-radius: 4px;
                    padding: 20px 15px 10px 20px;

                    &__text {
                        font-weight: bold;
                        font-size: 18px;
                        text-transform: uppercase;
                    }

                    &__help-text {
                        margin-right: 5px;
                        margin-top: 5px;
                    }

                    &__order {
                        display: flex;
                    }

                    .select::before {
                        display: none;
                    }

                    .search-icon-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: 14px;
                        cursor: pointer;
                        text-transform: uppercase;
                        border-left: 1px solid $softBorder;
                        padding-left: 10px;

                        .icon {
                            width: 24px;
                            height: 24px;
                            color: #898989;
                        }
                    }
                }

                @include media("<=md") {
                    width: 100%;
                    margin-right: 0 !important;
                }
            }

            .search-box-row {
                background: $callout;
                border-radius: .5em;
                padding: .7em;

                .form-control {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

                .search-input {
                    width: 72%;
                    margin: 0 .7em;
                    background: $background;

                    @include media("<=sm") {
                        margin-left: 0;
                    }
                }

                .search-button {
                    width: 19%;
                    text-align: center;
                    font-weight: bold;
                    font-family: inherit;
                    padding: 0;
                    background: $primaryDark;
                    color: #fff;
                    min-height: 40px;
                    text-transform: capitalize;

                    &:hover {
                        background: $primaryAccent;
                    }

                    @include media("<=sm") {
                        padding: 0.5em 0.2em;
                        width: 25%;
                    }
                }

                .search-input {
                    height: 40px;
                }

                .CCButton_ChildContainer {
                    display: block;
                }


                .search-icon {
                    font-size: 3.2em !important;
                    color: $darkCallout;

                    @include media("<=sm") {
                        display: none;
                    }
                }

                .close-icon {
                    width: 20px;
                    height: 20px;
                }

                .big-close-icon {
                    width: 35px;
                    height: 35px;
                    margin-left: 5px;
                    cursor: pointer;
                }

                .search-button {
                    color: #fff;
                }

                @include media("<=sm") {
                    padding: 0.1em 0.5em;
                    margin: 10px 0;
                }
            }

            .tracking-subtitle {
                font-weight: bold;
                text-transform: uppercase;
                font-size: 16px;
                padding: 10px 20px;

                &.your {
                    border-right: 1px solid $softBorder;
                    border-left: 1px solid $softBorder;

                    @include media("<=md") {
                        border: none;
                    }
                }

                @include media("<=md") {
                    padding-left: 0;
                }
            }
        }

        @include media("<=md") {
            padding-bottom: 50px;
        }

        @include media("<=sm") {
            padding: 0 10px 50px;
            margin-top: 0px;
        }
    }

    .dot {
        font-size: 40px;
        padding: 6px 5px;
    }

    .option-item {
        display: flex;
    }

    .info-panel-wrapper {
        display: flex;
        justify-content: flex-end;

        .info-panel {
            max-width: 250px;

            .main-content {

                @include media("<=sm") {
                    flex-grow: 1;
                }
            }

            .leaderboard-my-activity {
                @include media("<=sm") {
                    max-width: 121px;
                    padding: 30px 11px;
                    flex-direction: column;
                    margin-left: 10px;
                }
            }

            @include media("<=md") {
                width: 290px;
                max-width: 100%;
            }

            @include media("<=sm") {
                display: flex;
                flex-grow: 1;
                max-width: 100%;
                flex-direction: row-reverse;
            }
        }

        @include media("<=md") {
            justify-content: space-between;
            flex-direction: row-reverse;
            align-items: flex-end;
            margin-top: 0;
            margin-bottom: 0;
        }

        @include media("<=sm") {
            justify-content: space-between;
            flex-direction: row;
            margin: 0 auto;
            align-items: flex-end;
        }
    }

    .actions-button-panel {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        min-height: 80px;

        .action-button {
            display: flex;
            flex-direction: column;
            background: $primaryDark;
            font-size: 14px;
            font-weight: 800;
            color: #fff;
            justify-content: space-between;
            padding: 8px;
            text-transform: uppercase;
            align-items: center;
            text-align: center;
            max-width: 80px;
            max-height: 80px;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;

            .icon {
                fill: #fff;
                max-height: 30px;
            }

            &.dark-blue {
                background: #04598D;
            }

            &.gray {
                background: #666;
            }

            @include media("<=md") {
                margin-bottom: 0;
                margin-top: 0;
                margin-right: 5px;
            }

            @include media("<=sm") {
                margin-bottom: 0;
                margin-left: 5px;
                margin-top: 5px;
            }
        }

        @include media("<=md") {
            margin-bottom: 0;
        }

        @include media("<=sm") {
            flex-direction: column;
            margin-bottom: 0;
        }
    }

    .header {
        border-bottom: 1px solid $softBorder;
        max-width: 100%;

        .header-inner {
            // this matches the tablet max width/medium breakpoint
            width: 855px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            padding: 20px 0;

            .logo {
                width: 140px;
                height: 38px;
            }

            .navigation {
                min-height: 40px;
                flex-grow: 1;
                display: flex;
                justify-content: center;

                .nav-link {
                    position: relative;
                    cursor: pointer;

                    text-decoration: none;
                    color: #222;
                    font-size: 16px;
                    text-transform: uppercase;
                    border: 2px solid transparent;
                    padding: 5px 10px;
                    margin-right: 10px;

                    &.active {
                        border-bottom: 3px solid #0177C0;
                        font-weight: 700;
                    }
                }

                .nav-link:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    right: -5px;
                    height: 30px;
                    top: 2px;
                    width: 1px;
                    background: #ccc;
                }
            }

            .profile {
                display: flex;
                flex-direction: column;
                text-align: right;

                .actions {
                    display: flex;
                    color: #0177C0;
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: capitalize;
                    cursor: pointer;

                    .action {
                        position: relative;
                        padding: 5px 0;

                        &:first-child {
                            margin-right: 10px;

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                height: 20px;
                                top: 6px;
                                right: -6px;
                                width: 1px;
                                background: #222;
                            }
                        }
                    }
                }
            }
        }

        @include media("<=md") {
            margin-top: 35px;

            .header-inner {
                justify-content: space-between;
                width: auto;
                padding: 20px 15px;
                position: relative;

                .hamburger-icon {
                    font-size: 36px;
                }

                .navigation {
                    position: absolute;
                    top: 85px;
                    left: 0;
                    right: 0;
                    flex-direction: column;
                    z-index: 10;

                    .nav-link {
                        display: block;
                        background: #0091e9;
                        color: #fff;
                        margin-right: 0;
                        font-size: 24px;
                        font-weight: bold;
                        text-align: center;
                        padding-top: 1em;
                        padding-bottom: 1em;
                        border-bottom: 1px solid #fff;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .select-order {
        .menu__button {
            padding: 0;

            .menu__label {
                font-weight: bold;
                display: flex;
                min-height: 32px;
                flex-direction: row;
                align-items: center;
                align-content: center;
                font-size: 14px;

                .option-item {
                    display: flex;
                    min-height: 32px;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                }
            }
        }
    }
}

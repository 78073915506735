@import "../../styles/common.scss";

$darkRed: #990000;
$lightBlue: #0091EA;
$lightGray: #cccccc;
$darkGray: #777777;

.EventUploadPage {
  .h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.2em;
  }

  a {
    color: #0091EA;
    text-decoration: none;
    font-weight: bold;
  }

  .EventUploadForm {
    @include media("<=phone") {
      display: none;
    }
  }

  .EventUploadFormMobile {
    @include media("<=phone") {
      padding-top: 20px;
      font-size: $largeTextSize;
      display: block;
    }
    @include media(">phone") {
      display: none;
    }
  }

  /** colors **/
  .GrayForeground {
    color: #898989;
  }

  .GrayBackground {
    background-color: #898989;
  }

  .BlueForeground {
    color: #0091EA;
  }

  .BlueBackground {
    background-color: #0091EA;
    border-color: #0091EA;
  }

  .BlackForeground {
    color: #000000;
  }

  .BlackBackground {
    background-color: #000000;
  }

  .WhiteForeground {
    color: #FFFFFF;
  }

  .WhiteBackground {
    background-color: #FFFFFF;
  }

  .DarkRedForeground {
    color: #990000;
  }

  .DarkGreenForeground {
    color: #009933;
  }


  .PageContainer {
    display: block;
    width: 850px;
    margin-left: auto;
    margin-right: auto;
    @include media("<=phone") {
      margin-top: 4em;
      width: auto;
    }
  }

  .Button {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px;
    text-transform: uppercase;
    border-style: solid;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .SmallButton {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 5px;
    text-transform: uppercase;
    border-style: solid;
    font-size: .9em;
    font-weight: 600;
    cursor: pointer;
  }

  .ThinDivider {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 20px;
    clear: both;
    color: #eeeeee;
  }


  .ClearModal {
    font-size: 2em;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
  }

  .DarkModal {
    background: rgba(0, 0, 0, 0.5);
    font-size: 2em;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
  }

  /* util */
  .Truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .DisplayNone {
    display: none;
  }

  .DisplayBlock {
    display: block;
  }

  .Hidden {
    visibility: hidden;
  }

  .MarginAuto {
    margin: auto;
  }

  .PositionRelative {
    position: relative;
  }

  .Bold {
    font-weight: bold;
  }

  .Centered {
    text-align: center;
  }

  .Disabled {
    color: #cccccc;
    cursor: default;
  }

  .ValidationErrored {
    color: $darkRed;
  }


  .FailureEmptyOrBadFormat, .FailureRequiredEmptyOrBadFormat, .FailureTooLong {
    color: $darkRed;
  }

  .eventUploadPreview {
    position: relative;
  }

  .eventUploadPreview .eventLeaderFirstName,
  .eventUploadPreview .eventLeaderLastName,
  .eventUploadPreview .eventStartTime,
  .eventUploadPreview .eventStartDate,
  .eventUploadPreview .eventEndTime,
  .eventUploadPreview .eventEndDate,
  .eventUploadPreview .eventState,
  .eventUploadPreview .eventZIP,
  .eventUploadPreview .eventCity,
  .eventUploadPreview .timeZone,
  .eventUploadPreview .eventType1,
  .eventUploadPreview .eventType2 {
    display: inline-block;
  }

  table td {
    vertical-align: top;
  }


  .UploadProgressContainer {
    width: 850px;
    padding-top: 8px;
    padding-bottom: 5px;
    padding-left: 2px;
    padding-right: 2px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 40%;
    background: #cccccc;
    border-radius: 5px;
  }

  .UploadProgressBarBackground {
    background: #cccccc;
    width: 800px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    border: 1px solid #666666;
    padding: 3px;
  }

  .UploadProgressBar {
    background: #0091EA;
    width: 200px;
    height: 40px;
    border-radius: 5px;
  }

  .UploadProgressLabel {
    background: rgba(0, 0, 0, 0);
    color: black;
    width: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .StillNeedHelp {
    position: absolute;
    right: .1em;
    top: .1em;
    border-radius: 1em;
    background: #000000;
    padding: 15px;
    width: 200px;
    color: white;
    font-size: 1.3em;
    text-transform: uppercase;
  }

  .PreviewEventsWrapper {
    min-height: 50em;
  }


  .BorderBox {
    padding: 18px;
    border: 1px solid black;
    margin-left: 1em;
  }

  .LeftColumn {
    display: block;
    width: 420px;
    float: left;
  }

  .LeftColumn .Button {
    margin-bottom: 10px;
  }

  .MiddleGutterColumn {
    width: 10px;
    float: left;
  }

  .RightColumn {
    display: block;
    width: 420px;
    float: left;
  }

  .RightColumn .Button {
    margin-top: 10px;
    margin-right: 10px;
  }

  .UploadFileLabel {
    width: 150px;
    margin-bottom: 1em;
  }

  .LeftColumn div:not(:first-of-type), .PreviewInstructions div:not(:first-of-type) {
    margin-top: 10px;
  }

  .NextButton {
    margin-left: 10px;
    margin-right: 10px;
  }

  .PreviousButton {
    margin-right: 10px;
  }

  .EventImportWizard_Spacer {
    min-height: 800px;
  }
}
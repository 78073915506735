@import "../../styles/ignite/common.scss";

.eligibility-status-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .eligibility-status-modal {
    min-height: 500px;
    width: 900px;
    padding: 24px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .eligibility-status-modal-header {
      display: flex;
      justify-content: center;
      align-items: center;

      > p {
        font-size: 38px;
        font-weight: 700;
        margin: 0;
      }

      &__loading {
        > div {
          width: auto;
          margin: 0px 24px 0 0;
        }
      }

      &__eligible {
        .MuiSvgIcon-root {
          fill: #10BC41;
          height: 40px;
          width: 40px;
          margin-right: 14px;
        }
      }

      &__pending {
        > div {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          margin-right: 14px;
          background-color: #FCD405;

          .MuiSvgIcon-root {
            fill: #fff;
            height: 40px;
            width: 40px;
          }
        }
      }
    }

    .eligibility-status-modal-text {
      font-size: 24px;
      color: #0177C0;
      margin: 20px 60px 0 60px;
      text-align: center;
      white-space: pre-line;

      @include media("<=phone") {
        margin: 20px 20px 0 20px;
      }
    }

    .eligibility-status-modal-button {
      background: #0177C0;
      color: #fff;
      font-size: 16px;
      font-weight: 800;
      width: 270px;
      height: 62px;
      margin-top: 40px;
    }
  }
}

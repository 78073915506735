@import "../../styles/common.scss";

.ListSelfGuidedIndicator {
  display: flex;
  .SelfGuidedIndicator {
    display: flex;
    width: 1em;
    height: 1.5em;
    fill: $secondaryAccent;
    margin-right: .3em;
    font-weight: $bold;
  }
  .SelfGuidedEvent {
    display: flex;
    font-weight: bold;
    white-space: nowrap;
    text-transform: uppercase;
  }
}
@import "../../styles/common.scss";

@include media("<=phone") {
  .NavigationElement {
    display: flex;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: 1px solid $primaryAccentForeground;
    border-bottom: 1px solid $primaryAccentForeground;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }

  .NavigationElement {
    display: flex;
    color: $primaryAccentForeground;
    text-decoration: none;
    font-size: $mediumHeadlineTextSize;
    font-weight: bold;
  }

  .NavigationElement_Current {
    font-size: $mediumHeadlineTextSize;
  }
}


@include media(">phone") {
  .NavigationElement {
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 5em;
    font-size: $smallHeadlineTextSize;
    text-transform: uppercase;
    cursor: pointer;
    height: 44px;
  }

  .NavigationElement {
    color: $foreground;
    text-decoration: none;
    display: flex;
  }

  .NavigationElement_Current {
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: #0177C0;
    text-decoration-thickness: 4px;
    text-underline-offset: 5px;
  }

  .NavigationElement_Over {
    color: $secondaryAccentDark;
  }
}

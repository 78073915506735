@import "../../styles/common.scss";

.MasqueradePage {

  padding-top: 1em;
  padding-bottom: 1em;
  min-height: 600px;

  .Result {
    color: $darkErrored;
  }

  .Instructions {
  }

  .Instructions a {
    color: $primaryAccent;
    font-weight: $bold;
    text-decoration: none;
  }

  .Instructions div {
    font-size: $largeTextSize;
    margin-top: 1em;
  }
}

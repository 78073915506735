@import "../../styles/common.scss";

.EventView {
  margin-top: .8em;
}

.SignMeUpButton {
  margin-top: 1.8em;
}

.RsvpAttendanceList {
  margin-top: 2em;
}

.ClubLeaderViewEvent_ManualRsvp {
  margin-top: 1.5em;
}

.Loading {
  padding-top: 10em;
  padding-bottom: 10em;
}
@import "../../styles/common.scss";

.ListSeparator {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ListSeparator_Title {
  display: flex;
  margin-bottom: .25em;
}

.ListSeparator_LineContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ListSeparator_AccentBox {
  background-color: $secondaryAccent;
  height: 10px;
  width: 250px;
}

.ListSeparator_Underline {
  border-bottom: 1px solid $foreground;
  flex: 1;
}


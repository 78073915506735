@import "../../../styles/ignite/common.scss";

.new-leader-dashboard-page {
    max-width: 100vw;

    .display-none {
        display: none;
    }

    .toolbar {
        padding: 0;
    }

    .double-arrow {
        color: rgba($color: #000000, $alpha: .54);
    }

    .arrow {
        width: 30px;
        height: 30px;
    }

    .disabled {
        .double-arrow {
            opacity: .3;
        }
    }

    .name,
    .status {
        text-transform: uppercase;
    }

    .name {
        .cell-content {
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            max-width: 200px;
            white-space: nowrap;
        }
    }

    .cell-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;

        .value {
            width: 24px;
            text-align: right;
        }
    }

    .cell-icon {
        color: #0177C0;

        &.edit, &.view {
            cursor: pointer;

            &.disabled {
                color: #888;
                pointer-events: none;
            }
        }
    }

    .checkbox-cell {
        border-bottom: none;
        padding-left: 0;

        .checkbox {
            padding-left: 0;
        }
    }

    .pagination-list {
        display: flex;
        align-items: center;

        .pagination-item {
            &.active {
                color: #000;
                pointer-events: none;
            }
        }
    }

    .scroller {
        overflow-x: auto !important;

    }

    .scroller::-webkit-scrollbar {
        display: none;
    }

    .header-cell {
        padding: 10px 5px;
        font-size: 14px;
        text-transform: uppercase;
        color: #0177C0;
        font-weight: 600;
        border: none;
        text-wrap: nowrap;

        &.checkbox-cell {
            border: none;
            padding-left: 0;

            .checkbox {
                color: #0177C0;
            }
        }

        .active {
            color: #0177C0;
        }

        .sort-icon {
            width: 15px;
            height: 15px;
            color: #0177C0 !important;
            transform: scale(1.5);
            margin: 0;

            &.is-desc {
                transform: scale(1.5) rotate(180deg);
            }
        }

        @include media("<=md") {
            padding: 10px 5px;

            .sort-icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    .table-cell {
        text-wrap: nowrap;
        padding: 16px 5px;

        &.last-activity,
        &.online,
        &.in-person {
            text-align: center;
        }
    }

    .members-table-mobile {
        .member-item {
            padding: 10px 0;
            border-top: 1px solid $softBorder;

            .top-content {
                font-size: 18px;
                font-weight: 600;
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .right-content {
                    width: 70px;
                    display: flex;
                    justify-content: space-between;
                }

                .left-content {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 290px;
                    white-space: nowrap;

                    &.with-margin {
                        margin-left: 40px;
                    }
                }

                .checkbox {
                    padding-left: 0;
                }
            }

            .middle-content {
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                padding-left: 30px;
                font-size: 14px;
                font-weight: 600;
            }

            .bottom-content {
                padding-left: 30px;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
            }

            .row {
                display: flex;
            }

            .label-value-block {
                display: flex;
                margin-right: 15px;
                margin-bottom: 5px;
                margin-top: 5px;
                margin-left: 10px;
                gap: 3px;
                align-items: center;

                .label {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #0177C0;
                    font-weight: 600;
                }

                .value {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #3A3A3A;
                    font-weight: 600;
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    align-items: center;
                }
            }
        }
    }

    .actions {
        display: flex;
        justify-content: space-between;
        margin-left: 0;
        width: 100%;
        background: #eee;
        border-right: 4px;
    }

    .MuiTableSortLabel-root.MuiTableSortLabel-active {
        span {
            display: none;
        }
    }

    .dashboard-tabs-indicator {
        background-color: #0177C0;
        margin-bottom: 5px;
        height: 5px;
        min-width: 120px;
    }

    .dashboard-tab {
        color: #000;
        text-transform: none;
        min-width: auto;
        font-size: 18px;
        font-weight: 600;
        opacity: 1;

        &.selected {
            color: #000;
            font-weight: 800;
        }
    }

    .leader-dashboard-content {
        // this matches the tablet max width/medium breakpoint
        max-width: 855px;
        margin: 20px auto 40px;
        display: flex;
        flex-direction: column;

        .top-content {
            display: block;
            border: none;

            .club-info {
                .name {
                    font-size: 16px;
                    text-transform: capitalize;
                }
            }

            .title {
                font-size: 24px;
                font-weight: 800;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include media("<=md") {
                    font-size: 24px;
                    margin: 0 0 20px;
                    padding-left: 0;
                }

                @include media("<=sm") {
                    width: 100%;
                    margin-bottom: 0;
                    margin-top: 0;
                    flex-direction: column-reverse;
                    align-items: flex-start;
                }
            }

            .subtitle {
                font-size: 20px;
                font-weight: 800;
                text-transform: uppercase;
            }
        }

        .bottom-content {
            border: none;
        }

        @include media("<=md") {
            padding-bottom: 50px;
        }

        @include media("<=sm") {
            padding: 0 10px 50px;
            margin-top: 20px;
        }
    }

    .selection-panel {
        display: table-row-group;
        position: relative;
        height: 120px;

        .content {
            position: absolute;
            left: 0;
            right: 0;

            .top-content {
                display: flex;
                justify-content: center;
                font-size: 17px;
                font-weight: 700;
                background: rgba($color: #0091E9, $alpha: .1);
                padding: 5px 0;
                margin: 16px 0;
                display: flex;

                .text {
                    margin-right: 10px;
                    font-weight: 400;
                }

                .action {
                    cursor: pointer;
                    color: #0177C0;
                }

                @include media("<=sm") {
                    flex-direction: column;
                    padding: 10px;
                }
            }
        }

        &__select {
            width: 480px;

            @include media("<=sm") {
                width: 300px;
                margin-left: 10px;
            }
        }

        &.without-dropdown {
            height: 70px;
        }

        @include media("<=sm") {
            height: 160px;

            &.without-dropdown {
                height: 80px;
            }
        }
    }

    .view-switcher {
        margin-left: 20px;
        font-size: 16px;
        font-weight: 600;
        color: #0177C0;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
            margin-right: 7px;
        }

        @include media("<=sm") {
            margin-left: 0;
        }
    }

    .middle-content {
        display: flex;
        padding: 20px 0;

        .action-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 25px;

            .text {
                margin-left: 10px;
                font-size: 15px;
                font-weight: 700;
                color: #0177C0;
            }

            .add-icon {
                color: #0177C0;
            }
        }

        @include media("<=sm") {
            flex-direction: column;
            padding: 20px 10px;

            .action-button {
                margin-bottom: 10px;
            }
        }
    }

    .notification-error {
        background: #D4383A
    }

    .notification-success {
        background: #10BC41;
    }

    .members-tab-content {
        @include media(">sm") {
            .container {
                justify-content: space-between;
            }
        }
    }
}

.member-actions-menu-item:hover {
    span {
        background: rgba($color: #0091E9, $alpha: .1);
    }
}

@import "../../../styles/common.scss";

$primarytDark: #0177C0;

.successful-signup-content {
    max-width: 730px;
    margin: 60px auto 0 auto;
    min-height: 15em;

    @include media("<=phone") {
        margin: 145px auto 20px;
    }

    .successful-signup-club {
        display: flex;
        flex-direction: column;

        &__description {
            margin-top: 15px;
            font-size: 24px;
            text-align: center;
        }

        &__status {
            font-size: 24px;

            @include media("<=phone") {
                margin: 0 auto;
            }
        }

        &__title {
            text-align: center;
            font-size: 32px;
            font-weight: 600;
            b{
                font-weight: 800;
            }
        }

        &__separator{
            width: 42%;
            margin-top: 25px;
            opacity: .5;
        }

        @include media("<=phone") {
            padding: 0 20px;
        }
    }

    .club-info {
        display: flex;
        align-items: center;
        border: 1px solid $softBorder;
        border-radius: 0.3em;
        padding: 0.6em;
        margin-top: 1.3em;

        &__image-wrapper {
            display: flex;
        }

        &__logo {
            max-width: 125px;
            max-height: 105px;
            margin-right: 1.8em;
        }
        &__name{
            font-size: 24px;
        }
    }

    .club-button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;

        .club-button {
            border: 1px solid $primaryAccentDark;
            color: $primaryAccentDark;
            background: $primarytDark;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
        }
    }
}

.not-right-club {
    text-align: center;
    background: $callout;
    padding: 2em 0;
    margin-top: 3.2em;
    line-height: 1.5;

    &__finder {
        font-size: 24px;
        text-decoration: underline;
        font-weight: 800;
        color: #000;
    }
}

@import "./VirtualClubsCommon.scss";

$e3blue: #0177C0;

/* general rules */

img {
    max-width: 100%;
}

// slightly larger intercap blue
h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    color: $e3blue;
}

// all caps blue
h4 {
    margin: 0 0 16px 0;
    padding: 0;
    color: $e3blue;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
}

/* utilities */
.roundedOutlined {
    border: 1px solid $e3blue;
    border-radius: 8px;
    margin: 32px 0;
    padding: 24px;
}

.invisible {
    visibility: hidden;
    height: 0;
}

.fz-24 {
    font-size: 24px;
}

.fz-20 {
    font-size: 20px;
}

.weight-300 {
    font-weight: 300;
}

.weight-400 {
    font-weight: 400px;
}

.weight-600 {
    font-weight: 600px;
}

.weight-700 {
    font-weight: 700;
}
.pb-4 {
    padding-bottom: 4px;
}

.pb-6 {
    padding-bottom: 6px;
}

.pb-8 {
    padding-bottom: 8px;
}

.pb-16 {
    padding-bottom: 16px;
}

.pb-24 {
    padding-bottom: 24px;
}

.color-e3blue {
    color: $e3blue;
}

.floatRight {
    padding-top: 22px;
    margin-left: auto;
}

.strong {
    font-weight: bold;
}

.title {
    font-size: 16px;
    font-weight: 700;
}


/* modals */
.MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.ClubDetail_Container {
    padding: 32px 0;
}


.clubInfo_heroImage {
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center center;
    border-radius: 8px;
    margin-bottom: 24px;
}


.fileDownload {
    margin-top: 16px;
}

.eventInfo_title {
    font-size: 20px;
    line-height: 50px;
    margin: 0 0 8px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.eventInfo_dateRange {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.eventInfo_subtitle {
    font-size: 18px;
    margin: 0 0 24px 0;
    color: $e3blue;
}

.clubInstructor_container {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.clubInstructor {
    width: 160px;
    text-align: center;
}

.clubInstructor_biopic {
    margin: 0 auto 18px;
}

.MuiAvatar-root.MuiAvatar_100px {
    width: 100px;
    height: 100px;
}

.MuiAvatar-root.MuiAvatar_centered {
    margin: 0 auto;
}

.join-leave-club-menu {
    width: 200px;
}

.VirtualClubsClubDetailPage {
    .MuiTypography-colorPrimary {
        color: $e3blue;
    }

    .floatRight .MuiButton-root {
        margin-left: 24px;
    }

    h1 {
        font-size: 32px;
    }

    p {
        margin: 0 0 24px 0;
        padding: 0;
        font-size: 20px;
        line-height: 30px;

    }

    // used for Join / Joined button
    .VirtualClub_MUIButton_outlined {
        margin-top: auto;
        align-self: flex-end;

        font-weight: 700;
        padding: 12px 24px;
        color: #0177C0;
        white-space: nowrap;
        border: 1px solid #0177C0;
        border-radius: 4px;
        line-height: 1;
        margin: 0 0 0 40px;
        font-size: 18px;
        font-weight: 800;
    }

    // used for event sign up / cancel
    .VirtualClub_MUIButton.medium {

        font-size: 14px;
        font-weight: 800;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: #fff;

        margin: 0 0 0 8px;
        padding: 6px 13px;
        background-color: $e3blue;
        border-color: $e3blue;

        &:hover {
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.29);
        }
    }

    .VirtualClub_MUIButton_outlined.medium {
        padding: 6px 13px;
        margin: 0 0 0 8px;
        white-space: nowrap;
        border: 1px solid #0177C0;
        border-radius: 4px;

        font-size: 14px;
        font-weight: 800;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: #0177C0;
    }



    a {
        color: $e3blue;

        &.goBack {
            padding-left: calc(15px + 8px);
            position: relative;

            &::before {
                width: 15px;
                height: 18px;
                content: "";
                position: absolute;

                top: 2px;
                left: 0;
                z-index: -1;
                background: url("../../assets/nextprev-button.png") 0 0 no-repeat;
                transform: rotate(180deg);
            }

        }

        &.fileDownload {
            padding-left: calc(24px + 8px);
            position: relative;

            &::before {
                width: 24px;
                height: 16px;
                content: "";
                position: absolute;

                top: 2px;
                left: 0;
                z-index: -1;
                background: url("../../assets/icon_eye.svg") 0 0 no-repeat;

            }

        }
    }


    .recCarousel {
        margin-bottom: 24px;
    }
    .rec.rec-arrow {
        width: 28px;
        min-width: 28px;
    }
    .galleryImage {
        background-size: cover;
        background-position: center center;

        aspect-ratio: 1;
        height: 150px;
        width: auto;

        border-radius: 8px;
    }

    .has-5 div {
        flex: 20%;
        max-width: 20%;
        border-radius: 8px;
    }
    .galleryBlock {
        margin: 0 15px;
    }
    .testimonialBlock {
        margin: 0 15px;
    }
    .testimonialBody {
        text-align: center;

        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        /* 166.667% */
    }

    .galleryBody {
        text-align: center;

        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        /* 166.667% */
    }

    .testimonialImg {
        height: 192px;
        background-size: cover;
        background-position: center center;
        border-radius: 8px;
        margin-bottom: 24px;
    }
}